import {
  Box,
  Button,
  ButtonBase,
  Divider,
  styled,
  TextField,
  Tooltip,
  Typography,
  Zoom,
} from '@mui/material';

const FlexBox = styled(Box)(() => ({ display: 'flex', alignItems: 'center' }));

const FilterName = styled(Typography)(({ theme }) => ({
  fontSize: '0.875rem',
  fontWeight: 400,
  lineHeight: '1.19rem',
  marginRight: '.5rem',
  color: theme.palette.text.lightUnitGrey,
}));

const PopoverBox = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  height: '31.06rem',
  width: '17.62rem',
  padding: '1rem',
  border: `1px solid ${theme.customColors.popoverBorder}`,
  borderRadius: '0.25rem',
  background: theme.palette.background.layoutBackgroundColor,
  boxShadow: `0px 0.25rem 0.5rem ${theme.palette.border.popoverGrey}`,
}));

const SectionHeading = styled(Typography, {
  shouldForwardProp: (p) => p !== 'isSmall',
})(({ isSmall }) => ({
  fontWeight: 700,
  fontSize: isSmall ? '.875rem' : '1rem',
  lineHeight: '1.25rem',
  marginBottom: '.6rem',
}));

const SortingButton = styled(ButtonBase, {
  shouldForwardProp: (p) => p !== 'isAsc' && p !== 'isActive',
})(({ theme, isAsc, isActive }) => ({
  border: `1px solid ${theme.palette.border.primary}`,
  width: '7.5rem',
  height: '1.5rem',
  ...(isAsc && { borderRightWidth: 0, borderRadius: '.25rem 0 0 .25rem' }),
  ...(!isAsc && { borderRadius: '0 .25rem .25rem 0' }),
  ...(isActive && {
    backgroundColor: theme.palette.border.primary,
    color: theme.palette.border.secondary,
  }),
}));

const SortingButtonLabels = styled(Typography)(() => ({
  fontWeight: 600,
  fontSize: '.75rem',
  lineHeight: '1rem',
  marginLeft: '.5rem',
  width: '50%',
}));

const DividerLine = styled(Divider, {
  shouldForwardProp: (p) => p !== 'marginTopAuto',
})(({ theme, marginTopAuto }) => ({
  borderColor: theme.customColors.darkGrey,
  margin: marginTopAuto ? 'auto 0 1rem' : '1rem 0',
}));

const SearchField = styled(TextField, {
  shouldForwardProp: (p) => p !== 'isError',
})(({ theme, isError }) => ({
  '.MuiInputBase-input': {
    padding: '0.25rem',
  },
  '.MuiInputBase-root': {
    borderRadius: '.25rem',
    padding: 0,
    height: '2rem',
    '&.MuiOutlinedInput-root': {
      fontSize: '.875rem',
      '&:hover fieldset': {
        ...(isError && { borderColor: theme.palette.border.lavaRed }),
      },
      '&.Mui-focused fieldset': {
        borderColor: isError
          ? theme.palette.border.lavaRed
          : theme.customColors.saveGreen,
        borderWidth: '1px',
      },
    },
  },
}));

const OptionBox = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  cursor: 'pointer',
  whiteSpace: 'nowrap',
  '&:hover': {
    backgroundColor: theme.palette.background.gunmetalGrey,
  },
}));

const OptionLabel = styled(Typography)(() => ({
  fontWeight: 400,
  fontSize: '.875rem',
  lineHeight: '1.19rem',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
}));

const OptionTooltip = styled(({ className, isDisabled = true, ...props }) => (
  <Tooltip
    {...props}
    classes={{ popper: className }}
    TransitionComponent={Zoom}
    disableHoverListener={isDisabled}
  />
))(({ theme }) => ({
  zIndex: theme.zIndex.tooltip + 1,
  '& .MuiTooltip-tooltip': {
    border: `1px solid ${theme.customColors.wrapperGrey}`,
    backgroundColor: theme.customColors.dullDark,
    padding: 6,
  },
}));

const FilterButton = styled(Button, {
  shouldForwardProp: (p) => p !== 'isApply',
})(({ theme, isApply }) => ({
  width: '7.25rem',
  height: '2.25rem',
  fontWeight: '600',
  border: `1px solid ${theme.customColors[isApply ? 'saveGreen' : 'white']}`,
  borderRadius: '1.25rem',
  color: theme.customColors[isApply ? 'black' : 'white'],
  backgroundColor: isApply ? theme.customColors.saveGreen : 'transparent',
  textTransform: 'none',
  '&.MuiButton-root:hover': {
    color: theme.customColors[isApply ? 'white' : 'black'],
    backgroundColor: isApply ? 'transparent' : theme.customColors.white,
    border: `1px solid ${theme.customColors[isApply ? 'saveGreen' : 'white']}`,
  },
}));

const FilterBadge = styled('span')(({ theme }) => ({
  backgroundColor: theme.customColors.highlightBlue,
  borderRadius: '1.25rem',
  fontWeight: 700,
  fontSize: '.75rem',
  lineHeight: '1rem',
  color: theme.customColors.black,
  padding: '.1rem .3125rem',
  textAlign: 'center',
  marginTop: '-1rem',
  marginLeft: '-.5rem',
}));

export {
  FlexBox,
  FilterName,
  PopoverBox,
  SectionHeading,
  SortingButton,
  SortingButtonLabels,
  DividerLine,
  SearchField,
  OptionBox,
  OptionLabel,
  OptionTooltip,
  FilterButton,
  FilterBadge,
};

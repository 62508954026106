import { useState, useEffect } from 'react';
import {
  Box,
  ButtonBase,
  Checkbox,
  InputAdornment,
  Popover,
} from '@mui/material';
import PropTypes from 'prop-types';
import FilterIcon from '../../assets/img/FilterIcon.svg';
import FilterDisabledIcon from '../../assets/img/FilterDisabledIcon.svg';
import AscendingIcon from '../../assets/img/AscendingIcon.svg';
import AscendingActiveIcon from '../../assets/img/AscendingActiveIcon.svg';
import DescendingIcon from '../../assets/img/DescendingIcon.svg';
import DescendingActiveIcon from '../../assets/img/DescendingActiveIcon.svg';
import SearchInActiveIcon from '../../assets/img/SearchInActiveIcon.svg';
import SearchIcon from '../../assets/img/SearchIcon.svg';
import CloseIcon from '../../assets/img/CloseIcon.svg';
import UncheckedIcon from '../../assets/img/UncheckedIcon.svg';
import CheckedIcon from '../../assets/img/CheckedIcon.svg';
import {
  DividerLine,
  FilterBadge,
  FilterButton,
  FilterName,
  FlexBox,
  OptionBox,
  OptionLabel,
  PopoverBox,
  SearchField,
  SectionHeading,
  SortingButton,
  SortingButtonLabels,
} from './styledPeers';
import { useRecipeContext } from '../../context/recipe/reducer';
import { recipeActions } from '../../context/recipe/actions';
import FilterLabel from './label';
import { cloneDeep } from 'lodash';
import { FILTER_MANIPULATIONS } from '../../constants';

const Filter = ({ filterName, displayName, anchorEl, setAnchorEl }) => {
  const { recipeState, recipeDispatch } = useRecipeContext();

  const refId = anchorEl ? `${filterName}-ref` : undefined;

  const isAscActive =
    recipeState.sorting.filterName === filterName &&
    recipeState.sorting.type === 'asc';
  const isDesActive =
    recipeState.sorting.filterName === filterName &&
    recipeState.sorting.type === 'des';

  const [searchText, setSearchText] = useState('');

  const [searchedOptions, setSearchedOptions] = useState(
    recipeState[filterName].optionsForFilter
  );

  const [checkSnapshot, setCheckSnapshot] = useState(null);

  useEffect(() => {
    if (Boolean(refId)) {
      setCheckSnapshot({
        optionsForFilter: cloneDeep(recipeState[filterName].optionsForFilter),
        selections: [...recipeState[filterName].selections],
        isAllChecked: recipeState[filterName].isAllChecked,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [refId]);

  useEffect(() => {
    if (searchText.length >= 2) {
      setSearchedOptions(
        recipeState[filterName].optionsForFilter.filter((o) =>
          o.value.toLowerCase().includes(searchText.toLowerCase())
        )
      );
    } else {
      setSearchedOptions(recipeState[filterName].optionsForFilter);
    }
  }, [
    filterName,
    recipeState,
    recipeState.identifierFilter,
    recipeState.nameFilter,
    searchText,
  ]);

  const clearSearch = () => {
    setSearchText('');
    setSearchedOptions(recipeState[filterName].optionsForFilter);
  };

  const closeHandler = (restoreSnap = false) => {
    if (restoreSnap) {
      recipeDispatch(recipeActions.checkRestore(filterName, checkSnapshot));
    }
    setAnchorEl(null);
    clearSearch();
  };

  const sortingToggle = (type) => {
    if (type === 'asc' && !isAscActive) {
      recipeDispatch(recipeActions.toggleSorting(filterName, type));
    }
    if (type === 'des' && !isDesActive) {
      recipeDispatch(recipeActions.toggleSorting(filterName, type));
    }
    closeHandler();
  };

  const checkToggleHandler = (value, checked) => {
    recipeDispatch(recipeActions.toggleCheck(filterName, value, checked));
  };

  const filterHandler = (isReset = false) => {
    let isAnyChecked = false;
    if (!isReset) {
      recipeState[filterName].optionsForFilter.forEach((option) => {
        isAnyChecked = isAnyChecked || option.isChecked;
      });
    }
    const getFilterAction = () => {
      if (isAnyChecked) return FILTER_MANIPULATIONS.APPLY;
      if ((isAscActive || isDesActive) && !isReset)
        return FILTER_MANIPULATIONS.CHECK_RESET;
      return FILTER_MANIPULATIONS.FULL_RESET;
    };
    recipeDispatch(recipeActions.setFilter(filterName, getFilterAction()));
    closeHandler();
  };

  return (
    <FlexBox>
      <FilterName>{displayName}</FilterName>
      {recipeState.recipesFromAPI.length > 0 && (
        <ButtonBase
          id={refId}
          onClick={(e) => setAnchorEl(e.currentTarget)}
          disableRipple
          data-testid={`${filterName}-button`}
          disabled={recipeState.isRecipesDirty}
        >
          {recipeState.isRecipesDirty ? <FilterDisabledIcon /> : <FilterIcon />}
          {recipeState[filterName].badgeCount > 0 && (
            <FilterBadge data-testid={`${filterName}-badgeCount`}>
              {recipeState[filterName].badgeCount}
            </FilterBadge>
          )}
        </ButtonBase>
      )}
      <Popover
        id={refId}
        open={Boolean(refId)}
        anchorEl={anchorEl}
        onClose={() => closeHandler(true)}
        sx={{ zIndex: '1500' }}
      >
        <PopoverBox data-testid={`${filterName}-box`}>
          <SectionHeading>Sort</SectionHeading>
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              width: '100%',
            }}
          >
            <SortingButton
              isAsc
              onClick={() => sortingToggle('asc')}
              isActive={isAscActive}
              data-testid={`${filterName}-asc-button`}
            >
              <FlexBox>
                {isAscActive ? <AscendingActiveIcon /> : <AscendingIcon />}
                <SortingButtonLabels>Ascending</SortingButtonLabels>
              </FlexBox>
            </SortingButton>
            <SortingButton
              onClick={() => sortingToggle('des')}
              isActive={isDesActive}
              data-testid={`${filterName}-des-button`}
            >
              <FlexBox>
                {isDesActive ? <DescendingActiveIcon /> : <DescendingIcon />}
                <SortingButtonLabels>Descending</SortingButtonLabels>
              </FlexBox>
            </SortingButton>
          </Box>
          <DividerLine />
          <SectionHeading>Filter</SectionHeading>
          <SearchField
            placeholder="Search"
            value={searchText}
            onChange={(e) => setSearchText(e.target.value)}
            InputProps={{
              startAdornment: (
                <InputAdornment position="end">
                  {!!searchText ? <SearchIcon /> : <SearchInActiveIcon />}
                </InputAdornment>
              ),
              ...(!!searchText && {
                endAdornment: (
                  <InputAdornment
                    position="start"
                    sx={{ cursor: 'pointer' }}
                    onClick={() => clearSearch()}
                  >
                    <CloseIcon />
                  </InputAdornment>
                ),
              }),
            }}
            data-testid={`${filterName}-searchfield`}
          />
          <DividerLine />
          <SectionHeading isSmall>{displayName}</SectionHeading>
          <Box
            sx={{ overflowX: 'auto' }}
            data-testid={`${filterName}-options-list-box`}
          >
            {searchedOptions.length > 0 && (
              <OptionBox>
                <FilterCheckbox
                  checked={recipeState[filterName].isAllChecked}
                  onChange={(e) => checkToggleHandler('all', e.target.checked)}
                  dataTestId={`${filterName}-all-checkbox`}
                />
                <OptionLabel>All</OptionLabel>
              </OptionBox>
            )}
            {searchedOptions.map((option, idx) => (
              <OptionBox key={idx}>
                <FilterCheckbox
                  checked={option.isChecked}
                  onChange={(e) =>
                    checkToggleHandler(option.value, e.target.checked)
                  }
                  dataTestId={`${filterName}-${option.value}-checkbox`}
                />
                <FilterLabel label={option.value} searchText={searchText} />
              </OptionBox>
            ))}
          </Box>
          <DividerLine marginTopAuto />
          <FlexBox sx={{ justifyContent: 'space-around' }}>
            <FilterButton
              onClick={() => filterHandler(true)}
              data-testid={`${filterName}-reset-button`}
            >
              Reset
            </FilterButton>
            <FilterButton
              isApply
              onClick={() => filterHandler()}
              data-testid={`${filterName}-apply-button`}
            >
              Apply
            </FilterButton>
          </FlexBox>
        </PopoverBox>
      </Popover>
    </FlexBox>
  );
};

const FilterCheckbox = ({ checked, onChange, dataTestId }) => {
  return (
    <Checkbox
      disableRipple
      checkedIcon={<CheckedIcon />}
      icon={<UncheckedIcon />}
      checked={checked ?? false}
      onChange={onChange}
      data-testid={dataTestId}
    />
  );
};

FilterCheckbox.propTypes = {
  checked: PropTypes.bool,
  onChange: PropTypes.func,
  dataTestId: PropTypes.string,
};

Filter.propTypes = {
  filterName: PropTypes.string,
  displayName: PropTypes.string,
  anchorEl: PropTypes.object,
  setAnchorEl: PropTypes.func,
};

export default Filter;

import {
  Box,
  ButtonBase,
  LinearProgress,
  styled,
  Typography,
} from '@mui/material';
import InfoIcon from '../../assets/img/greyInfo.svg';

const CustomButtonBase = styled(ButtonBase)(({ theme }) => ({
  padding: '0.5rem',
  '&.Mui-disabled': {
    color: theme.palette.text.darkGrey,
    opacity: 0.5,
    filter:
      'invert(34%) sepia(3%) saturate(801%) hue-rotate(175deg) brightness(92%) contrast(90%)',
  },
}));

const ButtonBaseLabel = styled(Typography)(() => ({
  marginLeft: '.5rem',
  fontSize: '1rem',
  fontWeight: 400,
}));

const InfoTooltipIcon = () => <InfoIcon style={{ cursor: 'pointer' }} />;

const CustomizedDropZone = styled(Box)(({ theme }) => ({
  border: `.1rem dashed ${theme.customColors.saveGreen}`,
  borderRadius: '0.5rem',
  color: theme.palette.text.primary,
}));

const FileContainer = styled(Box)(({ theme }) => ({
  background: theme.palette.background.blackGrey,
  boxShadow: '0 0.25rem 0.25rem rgba(0, 0, 0, 0.25)',
  borderRadius: '.25rem',
  display: 'flex',
  alignItems: 'center',
  padding: '1.5rem 1.25rem 1.5rem 1.5rem',
  width: 500,
}));

const CustomLinearProgress = styled((props) => (
  <LinearProgress variant="determinate" color="primary" {...props} />
))(({ theme }) => ({
  height: '0.438rem',
  backgroundColor: theme.customColors.progressBarEmpty,
  borderRadius: '.25rem',
  marginTop: '1rem',
  width: '90%',
  '& .MuiLinearProgress-barColorPrimary': {
    backgroundColor: theme.customColors.progressBarBlue,
    borderRadius: '1rem',
  },
}));

const LightGreyTypo = styled((props) => (
  <Typography variant="subtitle2" {...props} />
))(({ theme }) => ({
  color: theme.palette.text.lightWhiteGrey,
}));

export {
  CustomButtonBase,
  ButtonBaseLabel,
  InfoTooltipIcon,
  CustomizedDropZone,
  FileContainer,
  CustomLinearProgress,
  LightGreyTypo,
};

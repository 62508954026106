import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import Accordion from '../../../components/Accordion';
import { alpha, Grid, styled } from '@mui/material';
import { SOC_MESSAGES } from '../../../constants/en-us';
import { ACCORDION_STATUS, SOC_TYPES } from '../../../constants';
import {
  getStringValue,
  validateRefreshRate,
} from '../../../utils/helperFunctions';
import RefreshRateBody from './body';
import { isEmpty } from 'lodash';
import Button from '../../../components/Button';
import Text from '../../../components/Text';
import { SOC_STATUS_ACTIONS } from '../../../context/statusReducer';

const FooterGrid = styled(Grid)(({ theme }) => ({
  background: `${alpha(theme.palette.background.darkishBlackBlue, 0.5)}`,
  border: `0.0625em solid ${alpha(theme.palette.border.darkGrey, 0.3)}`,
  margin: 'auto',
  padding: '0.34375em',
}));

const RefreshRate = ({
  expanded,
  expandUpdate,
  refreshRate,
  putRefreshRate,
  refreshRateChange,
  setRefreshRateChange,
  status = '',
  socStatusDispatch = () => null,
  actualsUOM,
}) => {
  const [refreshRateData, setRefreshRateData] = useState(null);
  const [showError, setShowError] = useState(false);

  const checkIfChangesExist = (data) => {
    if (
      data.uoMId !== refreshRate.uoMId ||
      data.value !== getStringValue(refreshRate.value)
    ) {
      setRefreshRateChange((prev) => new Set(prev.add(data.value)));
    } else {
      const changes = refreshRateChange;
      setRefreshRateChange(changes);
    }
  };

  const inputChangeHandler = (e) => {
    socStatusDispatch({
      type: SOC_STATUS_ACTIONS.UPDATE_REFRESH_RATE_STATUS_FLAG,
      payload: { updatedStatus: ACCORDION_STATUS.IN_PROGRESS },
    });
    const data = { ...refreshRateData };
    data.value = e.target.value;
    const { val, msg } = validateRefreshRate(e.target.value, data.uoMId);
    data.error = !val;
    if (!val) {
      data.errorText = msg;
    }
    setRefreshRateData(data);
    checkIfChangesExist(data);
  };

  const uomChangeHandler = (e) => {
    socStatusDispatch({
      type: SOC_STATUS_ACTIONS.UPDATE_REFRESH_RATE_STATUS_FLAG,
      payload: { updatedStatus: ACCORDION_STATUS.IN_PROGRESS },
    });
    const data = { ...refreshRateData };
    data.uoMId = e.target.value;
    const { val, msg } = validateRefreshRate(data.value, e.target.value);
    data.error = !val;
    data.errorText = msg;
    setRefreshRateData(data);
    checkIfChangesExist(data);
  };

  const saveHandler = () => {
    const { value, uoMId } = refreshRateData;
    if (value === null || uoMId === null) {
      setShowError(true);
      return;
    }
    setShowError(false);
    putRefreshRate({ value, uoMId });
  };

  useEffect(() => {
    setRefreshRateData({
      ...refreshRate,
      value: getStringValue(refreshRate.value),
      errorText: '',
      error: false,
    });
  }, [refreshRate]);

  return (
    <Accordion
      expanded={expanded}
      onChange={() => expandUpdate(SOC_TYPES.REFRESH_RATE)}
      id="soc-refreshRate"
      data-testid="soc-refreshRate"
    >
      <Accordion.AccordionSummary
        aria-controls="soc-refreshRate-content"
        id="soc-refreshRate-header"
      >
        <Text isHeading text={SOC_MESSAGES.ACTUALS} />
        <Accordion.AccordionStatus status={status} />
      </Accordion.AccordionSummary>
      <Accordion.AccordionDetails data-testid="smf_refreshrate">
        {refreshRateData && (
          <Grid container direction="column">
            <RefreshRateBody
              refreshRateData={refreshRateData}
              inputChangeHandler={inputChangeHandler}
              uomChangeHandler={uomChangeHandler}
              showError={showError}
              actualsUOM={actualsUOM}
            />
            <FooterGrid container item justifyContent="flex-end">
              <Button
                text={SOC_MESSAGES.SAVE}
                onClick={saveHandler}
                disabled={refreshRateData.error || isEmpty(refreshRateChange)}
                data-testid="smf_savebtn"
              >
                <Text isBody text={SOC_MESSAGES.SAVE} />
              </Button>
            </FooterGrid>
          </Grid>
        )}
      </Accordion.AccordionDetails>
    </Accordion>
  );
};

RefreshRate.propTypes = {
  expanded: PropTypes.bool,
  expandUpdate: PropTypes.func,
  status: PropTypes.string,
  refreshRate: PropTypes.shape({
    value: PropTypes.number,
    uoMId: PropTypes.number,
  }),
  refreshRateChange: PropTypes.instanceOf(Set),
  putRefreshRate: PropTypes.func,
  setRefreshRateChange: PropTypes.func,
  socStatusDispatch: PropTypes.func,
  actualsUOM: PropTypes.array,
};

export default RefreshRate;

import { ACCORDION_STATUS } from '../constants';

export const SOC_STATUS_ACTIONS = {
  SET_ALL_STATUS: 'SET_ALL_STATUS',
  UPDATE_REFRESH_RATE_STATUS_FLAG: 'UPDATE_REFRESH_RATE_STATUS_FLAG',
  UPDATE_CRITICALITY_RTINGS_STATUS_FLAG:
    'UPDATE_CRITICALITY_RTINGS_STATUS_FLAG',
  UPDATE_CELL_CRITICALITY_STATUS_FLAG: 'UPDATE_CELL_CRITICALITY_STATUS_FLAG',
  UPDATE_ASSET_CRITICALITY_STATUS_FLAG: 'UPDATE_ASSET_CRITICALITY_STATUS_FLAG',
  UPDATE_SOC_RECIPE_STATUS_FLAG: 'UPDATE_SOC_RECIPE_STATUS_FLAG',
  UPDATE_RISK_CATEGORY_STATUS_FLAG: 'UPDATE_RISK_CATEGORY_STATUS_FLAG',
};

export const initialSocStatus = {
  refreshRatePhase: ACCORDION_STATUS.NOT_STARTED,
  criteriaCriticalityPhase: ACCORDION_STATUS.NOT_STARTED,
  riskCategoryPhase: ACCORDION_STATUS.NOT_STARTED,
  cellCriticalityPhase: ACCORDION_STATUS.NOT_STARTED,
  assetCriticalityPhase: ACCORDION_STATUS.NOT_STARTED,
  standardOperatingConditionPhase: ACCORDION_STATUS.NOT_STARTED,
  recipeManagementPhase: ACCORDION_STATUS.NOT_STARTED,
};

export const SocStatusReducer = (state, action) => {
  const { type, payload } = action;
  switch (type) {
    case SOC_STATUS_ACTIONS.SET_ALL_STATUS: {
      const { status } = payload;
      return {
        ...state,
        refreshRatePhase: status.refreshRateStatus,
        criteriaCriticalityPhase: status.criteriaCriticalityStatus,
        riskCategoryPhase: status.riskCategoryStatus,
        assetCriticalityPhase: status.assetCriticalityStatus,
        cellCriticalityPhase: status.cellCriticalityStatus,
        standardOperatingConditionPhase: status.standardOperationRecipeStatus, // TODO: Remove standardOperatingConditionPhase
        recipeManagementPhase: status.standardOperationRecipeStatus,
      };
    }

    case SOC_STATUS_ACTIONS.UPDATE_REFRESH_RATE_STATUS_FLAG: {
      return {
        ...state,
        refreshRatePhase: payload.updatedStatus,
      };
    }

    case SOC_STATUS_ACTIONS.UPDATE_CRITICALITY_RTINGS_STATUS_FLAG: {
      return {
        ...state,
        criteriaCriticalityPhase: payload.updatedStatus,
      };
    }

    case SOC_STATUS_ACTIONS.UPDATE_CELL_CRITICALITY_STATUS_FLAG: {
      return {
        ...state,
        cellCriticalityPhase: payload.updatedStatus,
      };
    }

    case SOC_STATUS_ACTIONS.UPDATE_ASSET_CRITICALITY_STATUS_FLAG: {
      return {
        ...state,
        assetCriticalityPhase: payload.updatedStatus,
      };
    }

    case SOC_STATUS_ACTIONS.UPDATE_SOC_RECIPE_STATUS_FLAG: {
      return {
        ...state,
        recipeManagementPhase: payload.updatedStatus,
      };
    }

    case SOC_STATUS_ACTIONS.UPDATE_RISK_CATEGORY_STATUS_FLAG: {
      return {
        ...state,
        riskCategoryPhase: payload.updatedStatus,
      };
    }

    default: {
      return {
        ...state,
      };
    }
  }
};

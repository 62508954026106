import ReactSlider from 'react-slider';
import './styles.css';
import Label from '../CustomSliderLabel';
import { Grid, Box, Tooltip, styled } from '@mui/material';
import PropTypes from 'prop-types';

const LightTooltip = styled(Tooltip)(() => ({
  fontSize: '14px',
  marginBottom: '.25em',
}));

export default function CustomSlider({
  colors,
  onChange,
  sliderValues,
  setSliderValues,
}) {
  function changeHandler(values) {
    setSliderValues(values);
    onChange();
  }
  return (
    <>
      <Grid container direcion="row" alignItems="center">
        <Box
          display="flex"
          flexDirection="column"
          width="100%"
          top="10px"
          paddingTop="30px"
          paddingBottom="15px"
          alignItems="center"
          alignContent="center"
        >
          <ReactSlider
            defaultValue={sliderValues}
            className="horizontal-slider"
            thumbClassName="slider-thumb"
            trackClassName="slider-track"
            onChange={changeHandler}
            min={0}
            max={99}
            minDistance={1}
            step={1}
            value={sliderValues}
            renderThumb={(props, state) => (
              <LightTooltip
                placement="top"
                title={state.valueNow + '%'}
                {...props}
              >
                <div></div>
              </LightTooltip>
            )}
          />
          <Box
            display="flex"
            flexDirection="row"
            width="70%"
            paddingRight={2}
            paddingBottom={2}
          >
            <Label
              color={colors.insignificant}
              lowValue={0}
              highValue={sliderValues[0]}
              label="Insignificant"
            />
            <Label
              color={colors.minor}
              lowValue={sliderValues[0] + 1}
              highValue={sliderValues[1]}
              label="Minor"
            />
            <Label
              color={colors.moderator}
              lowValue={sliderValues[1] + 1}
              highValue={sliderValues[2]}
              label="Moderate"
            />
            <Label
              color={colors.major}
              lowValue={sliderValues[2] === 99 ? 99 : sliderValues[2] + 1}
              highValue={sliderValues[3] === 100 ? 99 : sliderValues[3]}
              label="Major"
            />
            <Label
              color={colors.catostrophic}
              lowValue={
                sliderValues[3] < 100 ? sliderValues[3] + 1 : sliderValues[3]
              }
              highValue={100}
              label="Catastrophic"
            />
          </Box>
        </Box>
      </Grid>
    </>
  );
}

CustomSlider.propTypes = {
  colors: PropTypes.object,
  onChange: PropTypes.func,
  sliderValues: PropTypes.array,
  setSliderValues: PropTypes.func,
};

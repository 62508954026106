import { useState } from 'react';
import {
  Backdrop,
  Box,
  ButtonBase,
  ListItem,
  styled,
  Typography,
} from '@mui/material';
import ResetIcon from '../../../assets/img/ResetFilterIcon.svg';
import ResetDisabledIcon from '../../../assets/img/ResetDisabledFilterIcon.svg';
import DownloadIcon from '../../../assets/img/download.svg';
import DownloadDisabledIcon from '../../../assets/img/DownloadDisabledIcon.svg';
import UploadIcon from '../../../assets/img/whiteUploadIcon.svg';
import UploadDisabledIcon from '../../../assets/img/UploadDisabledIcon.svg';
import InfoIcon from '../../../assets/img/infoIcon.svg';
import { useRecipeContext } from '../../../context/recipe/reducer';
import { recipeActions } from '../../../context/recipe/actions';
import PropTypes from 'prop-types';
import CustomTooltip from '../../../components/Tooltip';
import { CONFIRMER_MESSAGES, MESSAGE_STRINGS } from '../../../constants/en-us';
import TEST_IDS from '../../../constants/testids';
import Confirmer from '../../../components/Confirmer';
import InfoIndicator from '../../../components/InfoIndicator';
import { useDropzone } from 'react-dropzone';
import { getAcceptType } from '../../../utils/helperFunctions';
import { FilterButton } from '../../../components/Filter/styledPeers';

const LabelTypo = styled(Typography, {
  shouldForwardProp: (p) => p !== 'isDisabled' && p !== 'isReset',
})(({ theme, isDisabled, isReset }) => ({
  fontWeight: isReset ? 600 : 400,
  fontSize: isReset ? '.875rem' : '1rem',
  lineHeight: isReset ? '1rem' : '1.5rem',
  color: theme.customColors[isDisabled ? 'darkGrey' : 'white'],
  marginLeft: '.5rem',
}));

const InfoSection = ({ message }) => {
  return (
    <CustomTooltip
      placement="top-end"
      maxWidth="15rem"
      textSize="0.75rem"
      message={message}
    >
      <InfoIcon
        style={{
          cursor: 'pointer',
          margin: '0 1rem',
        }}
      />
    </CustomTooltip>
  );
};

const ICON_TYPE = {
  RESET: 'RESET',
  DOWNLOAD: 'DOWNLOAD',
  UPLOAD: 'UPLOAD',
};

const Icon = ({ type, isDisabled }) => {
  const ICONS = {
    [ICON_TYPE.RESET]: {
      enabled: <ResetIcon />,
      disabled: <ResetDisabledIcon />,
    },
    [ICON_TYPE.DOWNLOAD]: {
      enabled: <DownloadIcon width={32} height={32} />,
      disabled: <DownloadDisabledIcon width={32} height={32} />,
    },
    [ICON_TYPE.UPLOAD]: {
      enabled: <UploadIcon width={32} height={32} />,
      disabled: <UploadDisabledIcon width={32} height={32} />,
    },
  };
  return ICONS[type][isDisabled ? 'disabled' : 'enabled'];
};

const HeaderSection = ({
  exportAllRecipe,
  getFileUploadURL,
  isFileUploadError,
}) => {
  const { recipeState, recipeDispatch } = useRecipeContext();

  const isDisabled = recipeState.recipesFromAPI.length === 0;
  const isResetDisabled =
    recipeState.recipesFromAPI.length === 0 ||
    recipeState.isRecipesDirty ||
    (recipeState.sorting.filterName === '' &&
      recipeState.identifierFilter.badgeCount === 0 &&
      recipeState.nameFilter.badgeCount === 0);

  const isShowingUploader = recipeState.uploader.showUploader;

  const resetHandler = () => {
    recipeDispatch(recipeActions.resetFilters());
  };

  const handleExportAll = () => {
    exportAllRecipe();
  };

  const [openConfirmer, setOpenConfirmer] = useState(false);

  const onDropAccepted = (acceptedFilesData) => {
    recipeDispatch(
      recipeActions.setUploader({
        ...recipeState.uploader,
        showUploader: true,
        fileObject: acceptedFilesData[0],
      })
    );
    getFileUploadURL(acceptedFilesData[0]);
    setOpenConfirmer(false);
  };

  const { getRootProps, getInputProps } = useDropzone({
    multiple: false,
    onDropAccepted,
    accept: getAcceptType('XLSX'),
  });

  return (
    <Box
      sx={{
        minHeight: '3.5rem',
        backgroundColor: (theme) => theme.customColors.blackGrey,
        border: (theme) => `1px solid ${theme.customColors.wrapperGrey}`,
      }}
    >
      <Box
        sx={{
          display: 'flex',
          height: '3.5rem',
          alignItems: 'center',
          justifyContent: 'flex-end',
        }}
      >
        {!isShowingUploader && (
          <ButtonBase
            sx={{
              border: (theme) =>
                `1px solid ${
                  theme.customColors[isResetDisabled ? 'darkGrey' : 'white']
                }`,
              padding: '.5rem 1rem',
              borderRadius: '5rem',
              marginRight: '1rem',
            }}
            disabled={isResetDisabled}
            onClick={() => resetHandler()}
            data-testid={TEST_IDS.RECIPE_RESET_FILTERS_BUTTON}
          >
            <Icon type={ICON_TYPE.RESET} isDisabled={isResetDisabled} />
            <LabelTypo isDisabled={isResetDisabled} isReset>
              Reset Filters
            </LabelTypo>
          </ButtonBase>
        )}
        <ButtonBase
          disabled={isDisabled}
          onClick={handleExportAll}
          data-testid={TEST_IDS.RECIPE_EXPORT_ALL_BUTTON}
        >
          <Icon type={ICON_TYPE.DOWNLOAD} isDisabled={isDisabled} />
          <LabelTypo isDisabled={isDisabled}>Export Data</LabelTypo>
        </ButtonBase>
        <InfoSection
          message={MESSAGE_STRINGS['soc.header.export.infoIcon.message']}
          addMargin
        />
        {!isShowingUploader && (
          <>
            <ButtonBase
              disabled={isDisabled}
              onClick={() => setOpenConfirmer(true)}
              data-testid={TEST_IDS.RECIPE_UPLOAD_BUTTON}
            >
              <Icon type={ICON_TYPE.UPLOAD} isDisabled={isDisabled} />
              <LabelTypo isDisabled={isDisabled}>Upload XLSX</LabelTypo>
            </ButtonBase>
            <InfoSection
              message={MESSAGE_STRINGS['soc.header.upload.infoIcon.message']}
            />
          </>
        )}
      </Box>
      {isFileUploadError &&
        isShowingUploader &&
        recipeState.uploader.uploadErrors.length > 0 && (
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              padding: '0 .5rem',
              margin: '.5rem 0',
            }}
          >
            <InfoIndicator iconSize={24} iconAlignCenter={false}>
              {recipeState.uploader.uploadErrors.map((errMsg, i) => (
                <ListItem
                  key={i}
                  disableGutters
                  sx={{
                    textTransform: 'none',
                    paddingBottom: '0.5rem',
                    paddingTop: '0.125rem',
                  }}
                >
                  <Typography
                    variant="subtitle2"
                    sx={{ textTransform: 'none' }}
                    data-testid={`${TEST_IDS.RECIPE_UPLOAD_ERROR_MESSAGE}-${i}`}
                  >
                    <span style={{ fontWeight: 'bold' }}>
                      {`${i + 1}. ${errMsg.header}`}
                    </span>
                    {` ${errMsg.description}`}
                  </Typography>
                </ListItem>
              ))}
            </InfoIndicator>
          </Box>
        )}
      {openConfirmer && (
        <Backdrop open sx={{ zIndex: 100 }}>
          <Confirmer
            message={CONFIRMER_MESSAGES.UPLOAD}
            NoHandler={() => setOpenConfirmer(false)}
            isRecipeConfirm
          >
            <FilterButton
              isApply
              data-testid={TEST_IDS.CONFIRMER_YES_BUTTON}
              {...getRootProps({
                className: 'recipe-top-dropzone',
              })}
              onClick={(e) => {
                getRootProps().onClick(e);
              }}
            >
              <input {...getInputProps()} />
              Yes
            </FilterButton>
          </Confirmer>
        </Backdrop>
      )}
    </Box>
  );
};

InfoSection.propTypes = {
  message: PropTypes.string,
};

HeaderSection.propTypes = {
  exportAllRecipe: PropTypes.func,
  getFileUploadURL: PropTypes.func,
  isFileUploadError: PropTypes.bool,
};

export default HeaderSection;

import { ACCORDION_STATUS, REFRESH_RATE_CONSTANTS } from '../constants';
import { MESSAGE_STRINGS } from '../constants/en-us';

export const getStatusTextFromConstants = (status) => {
  switch (status) {
    case ACCORDION_STATUS.NOT_STARTED:
      return MESSAGE_STRINGS['SOC.status.notStarted'];
    case ACCORDION_STATUS.IN_PROGRESS:
      return MESSAGE_STRINGS['SOC.status.inProgress'];
    case ACCORDION_STATUS.COMPLETED:
      return MESSAGE_STRINGS['SOC.status.completed'];
    default:
      return '';
  }
};

export const getStringValue = (value = '') =>
  value === null ? '' : String(value);

const checkIsNumber = (value) => {
  return (
    typeof value === 'number' &&
    Number.isFinite(value) &&
    Math.floor(value) === value
  );
};

export function validateStringIsWholeNumber(str) {
  if (typeof str != 'string') return false;
  const num = +str;
  return checkIsNumber(num) && str.indexOf('.') == -1 && num % 1 === 0;
}

export function validateRefreshRate(str, type) {
  const n = Number(str);

  if (!validateStringIsWholeNumber(str)) {
    return {
      val: false,
      msg: MESSAGE_STRINGS['ERROR_MESSAGES.validWholeNumber'],
    };
  }

  if (type === 1) {
    return {
      val: !str.includes('.') && Number.isInteger(n) && n >= 15,
      msg: MESSAGE_STRINGS['ERROR_MESSAGES.secondsValidation'],
    };
  }
  return {
    val:
      !str.includes('.') &&
      Number.isInteger(n) &&
      n >= REFRESH_RATE_CONSTANTS.MIN_VAL_REFRESHRATE,
    msg: MESSAGE_STRINGS['ERROR_MESSAGES.zeroValidation'],
  };
}

export const simulateUrlClick = (url, fileAcceptType) => {
  const link = document.createElement('a');
  link.setAttribute('href', url);
  link.setAttribute('type', fileAcceptType);
  link.setAttribute('download', true);
  link.setAttribute('referrerpolicy', 'same-origin');
  link.click();
};

export function getAcceptType(type = 'XLSX') {
  switch (type) {
    case 'XLSX':
      return {
        'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': [
          '.xlsx',
        ],
      };
    case 'CSV':
      return { 'text/csv': ['.csv'] };
    default:
      return {};
  }
}

export function formatBytes(bytes, decimals = 2) {
  if (bytes === 0) return '0 Bytes';

  const k = 1024;
  const dm = decimals < 0 ? 0 : decimals;
  const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];

  const i = Math.floor(Math.log(bytes) / Math.log(k));

  return `${parseFloat((bytes / k ** i).toFixed(dm))} ${sizes[i]}`;
}

export const updateCriticalityRatingsState = (old, modified) => {
  modified.forEach((update) => {
    old[update.rating] = update.criticalityValue;
  });
  return old;
};

export const checkisRecipeStateDirty = (recipes) => {
  let result = false;
  recipes.forEach((recipe) => {
    if (recipe.isEditMode) return (result = true);
  });
  return result;
};

export const getRecipesSorted = (recipes, sortingKey, sortBy) => {
  recipes.sort((r1, r2) => {
    const r1Value = r1[sortingKey].toLowerCase();
    const r2Value = r2[sortingKey].toLowerCase();
    if (r1Value < r2Value) {
      return sortBy === 'asc' ? -1 : 1;
    }
    if (r1Value > r2Value) {
      return sortBy === 'asc' ? 1 : -1;
    }
    return 0;
  });
  return recipes;
};

export const recipesFiltering = (recipes, filterKey, selections) => {
  return recipes.filter((recipe) => selections.includes(recipe[filterKey]));
};

export const getAvailOptionsAndBadgeCount = (recipes, key, selections) => {
  const availOptions = [];
  let availOptionsCount = 0;

  recipes.forEach((recipe) => {
    const checked = selections.includes(recipe[key]);

    availOptions.push({
      value: recipe[key],
      isChecked: checked,
    });

    if (checked) availOptionsCount++;
  });

  return [availOptions, availOptionsCount];
};

export const getLeftOutValues = (apiRecipes, filteredRecipes, key) => {
  const filteredRecipesId = new Set(filteredRecipes.map((r) => r.recipeId));
  const leftOutValues = new Set();

  apiRecipes.forEach((recipe) => {
    if (!filteredRecipesId.has(recipe.recipeId)) leftOutValues.add(recipe[key]);
  });

  return leftOutValues;
};

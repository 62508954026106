import { Box, Typography } from '@mui/material';
import PropTypes from 'prop-types';
import { formatBytes } from '../../utils/helperFunctions';
import {
  CustomLinearProgress,
  FileContainer,
  LightGreyTypo,
} from './styledPeers';
import XLSXFileIcon from '../../assets/img/xslxIcon.svg';
import CSVFileIcon from '../../assets/img/csvIcon.svg';
import CheckedIcon from '../../assets/img/uploadSuccess.svg';
import ErrorIcon from '../../assets/img/exclamationMark.svg';
import TEST_IDS from '../../constants/testids';

const FileViewer = ({
  error = false,
  file = {},
  isLoading,
  uploadPercent,
  isRecipeUpload,
}) => {
  const { name = '', size = 0 } = file;
  return (
    <FileContainer data-testid={TEST_IDS.UPLOADER_PREVIEW_CONTAINER}>
      {isRecipeUpload ? (
        <XLSXFileIcon height={52} width={52} />
      ) : (
        <CSVFileIcon height={52} width={52} />
      )}
      <Box
        sx={{
          marginLeft: '2rem',
          width: '75%',
        }}
      >
        <Box sx={{ display: 'flex', alignItems: 'center', width: '100%' }}>
          <Typography
            variant="subtitle2"
            data-testid={TEST_IDS.UPLOADER_PREVIEW_FILENAME}
            sx={{ textTransform: 'none' }}
          >
            {name}
          </Typography>
          {isLoading && (
            <LightGreyTypo
              data-testid={TEST_IDS.UPLOADER_PREVIEW_PERCENT}
              sx={{
                marginLeft: 'auto',
              }}
            >
              {uploadPercent}%
            </LightGreyTypo>
          )}
        </Box>
        <LightGreyTypo data-testid={TEST_IDS.UPLOADER_PREVIEW_FILESIZE}>
          {formatBytes(size)}
        </LightGreyTypo>
        {(isLoading || error) && (
          <CustomLinearProgress
            value={Math.round(uploadPercent)}
            data-testid={TEST_IDS.UPLOADER_PREVIEW_PROGRESS_LINE}
          />
        )}
      </Box>
      {!isLoading && (
        <>
          {error ? (
            <ErrorIcon
              height={24}
              width={24}
              data-testid={TEST_IDS.UPLOADER_PREVIEW_ERROR_ICON}
            />
          ) : (
            <CheckedIcon
              height={24}
              width={24}
              data-testid={TEST_IDS.UPLOADER_PREVIEW_SUCCESS_ICON}
            />
          )}
        </>
      )}
    </FileContainer>
  );
};

FileViewer.propTypes = {
  error: PropTypes.bool,
  file: PropTypes.shape({ name: PropTypes.string, size: PropTypes.number }),
  isLoading: PropTypes.bool,
  uploadPercent: PropTypes.number,
  isRecipeUpload: PropTypes.bool,
};

export default FileViewer;

import React from 'react';
import { Tooltip, alpha, styled, tooltipClasses } from '@mui/material';
import PropTypes from 'prop-types';

const CustomizedTooltip = styled(
  ({ className, ...props }) => (
    <Tooltip classes={{ popper: className }} {...props} />
  ),
  {
    shouldForwardProp: (prop) =>
      prop !== 'textSize' && prop !== 'maxWidth' && prop !== 'isBorderEnabled',
  }
)(
  ({
    theme,
    maxWidth: toolTipMaxWidth,
    textSize,
    background,
    isBorderEnabled,
  }) => ({
    [`& .${tooltipClasses.tooltip}`]: {
      backgroundColor: background ?? theme.palette.background.eerieBlack,
      maxWidth: toolTipMaxWidth,
      fontSize: textSize,
      lineHeight: '1rem',
      fontWeight: 400,
      boxShadow: `0 0.25rem 0.25rem ${alpha(theme.customColors.black, 0.25)}`,
      borderRadius: '0.25em',
      padding: '.75rem',
      color: theme.palette.text.lightYellowishGray,
      border: isBorderEnabled
        ? `.0625rem solid ${theme.palette.background.metalGrey}`
        : 'none',
    },
    [`& .${tooltipClasses.arrow}`]: {
      fontSize: 30,
      color: background ?? theme.palette.background.eerieBlack,
    },
  })
);

const CustomTooltip = ({
  message,
  children,
  maxWidth = '18.75rem',
  placement = 'bottom-start',
  textSize = '0.875rem',
  isBorderEnabled = false,
  ...other
}) => {
  if (!message) return children;

  return (
    <CustomizedTooltip
      title={message}
      {...other}
      placement={placement}
      maxWidth={maxWidth}
      textSize={textSize}
      isBorderEnabled={isBorderEnabled}
    >
      <span>{children}</span>
    </CustomizedTooltip>
  );
};

CustomTooltip.propTypes = {
  message: PropTypes.node,
  children: PropTypes.node,
  maxWidth: PropTypes.string,
  placement: PropTypes.string,
  textSize: PropTypes.string,
  isBorderEnabled: PropTypes.bool,
};

export default CustomTooltip;

export const SOC_MESSAGES = {
  REFRESH_RATE: 'Refresh Rate',
  ACTUALS: 'Actuals',
  CRITERIA_FOR_CRITICALITY_RATINGS: 'Criteria For Criticality Ratings',
  ASSET_CRITICALITY: 'Asset Criticality',
  CELL_CRITICALITY: 'Cell Criticality',
  RISK_CATEGORY_THRESHOLDS: 'Risk Category Thresholds',
  STANDARD_OPRERATING_CONDITIONS: 'Standard Operating Conditions',
  RECIPE_MANAGEMENT: 'Recipe Management',
  CSV_UPLOAD_ERROR:
    'Error occurred while uploading data. Please ensure that the column header text and the column order in the csv template remain unchanged',
  SAVE: 'Save',
  LOADING_DATA: 'Loading Data...',
  INITIAL_LOADING_MESSAGE: 'Loading table data...',
  NO_DATA_AVAILABLE: 'No Data Available',
  PLEASE_SELECT_A_FILTER: 'Please select the filters',
  NO_DATA_AVAILABLE_FOR_SELECTED_FILTERS:
    'No data is available for the selected filter combination',
  NEW_EQUIPMENT_ADDED_WATCHLIST:
    'New equipment has been added to the AIP table. Please update and upload the Criticality Ratings for the new equipment through the Condition Based Monitoring Configuration screen',
  NEW_EQUIPMENT_ADDED_OPERATING_CONDITIONS:
    'New equipment has been added to the AIP table. Please update and upload the Standard Operating Conditions for the new equipment through the Condition Based Monitoring Configuration screen',
};

export const CRITICALITY_RATINGS_MESSAGES = {
  HIGHEST_RISK: 'Highest Risk',
  LOWEST_RISK: 'Lowest Risk',
  ENTER_TEXT_HERE: 'Enter text here',
  SAME_VALUE_CANNOT_BE_ASSIGNED:
    'Same value cannot be assigned to multiple criticality ratings',
  ENSURE_ALL_FIELDS_ARE_FILLED: 'Required field',
  NO_CHANGES_DETECTED: 'No changes detected!',
  ASTERISK: '*',
};

export const UPLOAD_COMPONENT_MESSAGES = {
  DOWNLOAD: 'Download CSV Template',
  ROTATIONS_EMPTY: "Rotations can't be empty in csv template. Retry Uploading.",
  REUPLOAD: 'Re-Upload',
  KILO_BYTES: 'kB',
  EXPORT_DATA: 'Export Data',
  UPLOAD_CSV: 'Upload CSV',
};

export const MESSAGE_STRINGS = {
  /**
   * Accordion Status
   */
  'SOC.status.notStarted': 'Not Started',
  'SOC.status.inProgress': 'In Progress',
  'SOC.status.completed': 'Completed',
  /**
   * Cell Criticality
   */
  'CellCriticality.buttonText.uploadAssetCriticality':
    'Upload Cell Criticality File CSV',
  'CellCriticality.sheetUpdate.warning':
    'There has been an update on the Asset Intelligence Parameter table on the General Configuration screen. Please re-upload the Cell Criticality file with data for the new equipment',
  /**
   * Asset Criticality
   */
  'ASSET_CRITICALITY.heading.uploadAssetCriticality':
    'Upload Asset Criticality File CSV',
  'ASSET_CRITICALITY.sheetUpdate.warning':
    'There has been an update on the Asset Intelligence Parameter table on the General Configuration screen. Please re-upload the Asset Criticality file with data for the new equipment',
  'Asset_Criticality.header.infoIcon.message':
    'Entering Criticality Ratings at the Asset level is optional and will be enabled only after Criticality Ratings have been uploaded for all Cells.Additionally, if Asset Criticality Ratings are left blank, the Criticality Ratings of the parent Cell are used for all Risk Factor calculations of Assets',
  'ASSET_CRITICALITY.caption.fileUploadedSuccessfully':
    'File has been successfully uploaded!',
  /**
   * CSV File Messages
   */
  'CRITICALITY.caption.fileSuccessfullyUpload': 'File successfully uploaded',
  'CRITICALITY.caption.fileUploadedSuccessfully':
    'File has been successfully uploaded!',
  'UPLOAD_CSV.error.rejectedFileTypeCsv':
    'Invalid file type provided. Use CSV file.',
  'UPLOAD_XLSX.error.rejectedFileTypeXlsx':
    'Invalid file type provided. Use only XLSX file.',
  'TARGETS.uploadCsv': 'Upload CSV',
  'Criticality.uploadCsv.info':
    'If you have already uploaded a CSV file, clicking this icon again will allow you to upload a new CSV that will replace the previous CSV file. All data from the first CSV file will be removed.',
  /**
   * Risk Category Thresholds
   */
  'Risk_Category.validation.errors.riskFullRangeError':
    'The Risk Categories must cover the range from 0% to 100% risk',
  'Risk_Category.validation.errors.riskCategoryCantOverlap':
    "The range of one Risk Category can't overlap with the range of another Risk Category",
  'Risk_Category.validation.errors.riskCategoryBoth':
    'A Risk Category must have both Lower Bound and Upper Bound values entered. Please enter both values for the risk category',
  'ERROR_MESSAGES.validWholeNumber': 'Enter a valid whole number',
  'ERROR_MESSAGES.secondsValidation': 'Value must be more than 15 seconds',
  'ERROR_MESSAGES.zeroValidation': 'Values should be greater than zero',
  'Risk_Category.api.success': 'Successfully updated the Refresh rate.',
  'Risk_Category_Threshold.api.success':
    'Your changes have been successfully saved!',
  'Risk_Category_Threshold.header.infoIcon.message':
    'Please set thresholds for each risk category using the sliders below',

  /**
   * SOC View
   */
  'SOCView.create.api.success': 'Successfully created recipes.',
  'SOCView.update.api.success': 'Successfully updated recipes.',
  'SOCView.delete.api.success': 'Successfully deleted recipes.',
  'SOCView.heading.mainHeader': 'Standard Operating Conditions',
  'SOCView.heading.subHeading.addRecipe': 'Add Recipe',
  'SOCView.AddRecipe.repeatedRecipeName':
    'Recipe Name already exists please enter a different name',
  'SOCView.AddRecipe.blankRecipeName':
    'Invalid Input: Recipe name cannot be blank. Please enter a valid recipe',
  'SOCView.AddRecipe.ZeroValuedRecipeName':
    'Invalid Input: Recipe name cannot be zero. Please enter a valid recipe',
  'SOCView.AddRecipe.NegativeValuedRecipeName':
    'Invalid Input: Recipe name cannot be negative. Please enter a valid recipe',
  'SOCView.AddRecipe.noRecipePresentAdd':
    'Enter Recipe Name and click enter to add row here',
  'SOCView.modal.heading.ADD_RECIPE': 'Add Recipe',
  'SOCView.modal.rightButtonText.ADD_RECIPE': 'Save',
  'SOCView.modal.heading.EDIT_RECIPE': 'Edit Recipe',
  'SOCView.modal.rightButtonText.EDIT_RECIPE': 'Save',
  'SOCView.modal.heading.DOWNLOAD_CSV': 'Download CSV',
  'SOCView.modal.rightButtonText.DOWNLOAD_CSV': 'Download',
  'SOCView.modal.delete.warning.heading': 'Warning',
  'SOCView.modal.delete.warning.confirmQuestion': 'Do you wish to continue?',
  'SOCView.modal.delete.warning.mainText':
    'Deleting a recipe will also delete all the standard operating conditions associated with it.',
  'SOCView.modal.subheading.downloadCsv': 'Select Recipe',
  'SOCView.modal.subheading.enterRecipeName': 'Enter Recipe Name',
  'SOCView.addRecipe.validationError.onlyCharacters':
    'Invalid recipe name format, valid format eg: Recipe 50',
  'SOCView.modal.downloadCsv.message.selectionLimitReached':
    'Row Limit Reached! You have selected the maximum number of Recipes allowed to be downloaded in one template',
  'SOCView.heading.uploadTitle': 'Upload Standard Operating Conditions CSV',
  'SOCView.captions.exportData': 'Export Data',
  'SOCView.heading.table.recipeName': 'Recipe Name',
  'SOCView.error.rowLimitReached':
    'Row Limit Reached! You have selected the maximum number of Recipes allowed to be downloaded in one template ',
  'SOCView.error.fileUploadFailed': 'Error in file upload',
  'SOCView.captions.uploadInfo.heading':
    "Please review the definitions for the permissible values for the 'Type of Parameter Tag' field in the template",
  'SOCView.captions.uploadInfo.pointA':
    'a. Recipe monitored setpoint value: The value entered on an asset by the line operator, often through an HMI screen',
  'SOCView.captions.uploadInfo.pointB':
    'b. Recipe monitored present value: The value of a parameter at the current point in time as measured by a sensor or controller',
  'SOCView.captions.uploadInfo.pointC':
    'c. Non-recipe present value: A attribute of an asset or the material flowing through said asset at the current point in time, not related to the recipe, such as the total count of goods passing through an asset',
  'SOCView.captions.noRecipe':
    'Click on add recipe button to add recipe here...',
  'SOCView.socket.errorMessage': 'Error occured while processing CSV',
  'SOCView.addRecipe.recipeName.validations.cantBeZero':
    'Invalid Input: Recipe name cannot be zero. Please enter a valid recipe',
  'SOCView.addRecipe.recipeName.validations.cantBeNegative':
    'Invalid Input: Recipe name cannot be negative. Please enter a valid recipe',
  'SOCView.addRecipe.recipeName.validations.cantBeBlank':
    'Invalid Input: Recipe name cannot be blank. Please enter a valid recipe',
  'SOCView.addRecipe.recipeName.validations.stringStartWithLetter':
    'Invalid Input: Recipe Name should start with character or be purely a number',
  'SOCView.addRecipe.messages.addingInProgress': 'Add Recipes in progress...',
  'SOCView.addRecipe.toast.success': 'Recipes were added successfully',
  'SOCView.addRecipe.toast.failure': 'There was some trouble adding recipes',
  'SOCView.editRecipe.messages.editInProgress': 'Edit Recipes in progress...',
  'SOCView.editRecipe.toast.success':
    'Your changes have been successfully saved',
  'SOCView.editRecipe.toast.failure': 'There was trouble editing these recipes',
  'SOCView.fetchRecipe.updating': 'Updating...',
  'SOCView.deleteRecipe.toast.success': 'Recipes Delete Successfully',
  'SOCView.deleteRecipe.toast.failure': 'Recipes Deletion Failed',
  'SOCView.deleteRecipe.messages.deletionInProgress': 'Deleting Recipes...',
  'SOCView.captions.recipeUploadLoading':
    'This process may take a few minutes, please wait',
  'SOCView.Criticality_Ratings.api.success':
    'Your changes have been successfully saved!',
  'SOCView.Refresh_Rate.api.success':
    'Your changes have been successfully saved!',
  'SOCView.sheetUpdate.warning':
    'There has been an update on the Asset Intelligence Parameter table on the General Configuration screen. Please re-upload the Standard Operating Conditions file with data for the new equipment',
  'soc.header.export.infoIcon.message':
    'Clikcing this icon will download an XLSX file with all current recipies.',
  'soc.header.upload.infoIcon.message':
    'If you have already uploaded an XLSX file, clicking this icon again will allow you to upload a new XLSX file that will overwrite uploaded values.',

  'GeneralTable.Pagination.caption.label1': 'Displaying',
  'GeneralTable.Pagination.caption.label2': 'of',
  'GeneralTable.Pagination.caption.label3': 'results',
  'GeneralTable.Pagination.caption.rowsLabel': 'Rows per page',

  // Navigation Modal
  'NavigationModal.title': 'Confirm!',
  'NavigationModal.button.save': 'Save',
  'NavigationModal.button.discard': 'Discard',
  'NavigationModal.customText': 'You have unsaved changes.',
  'NavigationModal.noteText': 'Do you want to save or discard your changes?',
};

export const CONFIRMER_MESSAGES = {
  DELETE: 'Deleting a recipe will remove all saved parameter settings.',
  UPLOAD:
    'Uploading a new file will replace the current file and any related data throughout the application.',
  UNSAVED_CHANGES: 'Navigating away will result in losing any unsaved changes.',
};

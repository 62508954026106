import React, { useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import Accordion from '../../../components/Accordion';
import { alpha, Grid } from '@mui/material';
import {
  CRITICALITY_RATINGS_MESSAGES,
  SOC_MESSAGES,
} from '../../../constants/en-us';
import { ACCORDION_STATUS, SOC_TYPES } from '../../../constants';
import Button from '../../../components/Button';
import Text from '../../../components/Text';
import CriticalityRatingsBody from './body';
import { getStringValue } from '../../../utils/helperFunctions';
import { SOC_STATUS_ACTIONS } from '../../../context/statusReducer';

const CriticalityRatings = ({
  expanded,
  expandUpdate,
  criticalityRatings,
  onSave,
  status = '',
  socStatusDispatch = () => null,
}) => {
  const ratingsObject = useMemo(() => {
    const result = {};
    Object.keys(criticalityRatings).forEach((item) => {
      result[item] = {
        text: getStringValue(criticalityRatings[item]),
        error: '',
      };
    });
    return result;
  }, [criticalityRatings]);

  const [ratings, setRatings] = useState(ratingsObject);

  const onChangeRatings = (text, key) => {
    socStatusDispatch({
      type: SOC_STATUS_ACTIONS.UPDATE_CRITICALITY_RTINGS_STATUS_FLAG,
      payload: { updatedStatus: ACCORDION_STATUS.IN_PROGRESS },
    });
    setRatings((prevRatings) => {
      /**
       * Checks if current text matches any of the pre-existing values
       */
      const sameTextRatings = Object.keys(ratings).filter(
        (item) =>
          key !== item &&
          prevRatings[item].text.toLowerCase() === text.toLowerCase() &&
          text !== ''
      );
      const newRatings = {
        ...prevRatings,
      };
      if (text !== '' && sameTextRatings.length) {
        newRatings[key] = {
          text,
          error: CRITICALITY_RATINGS_MESSAGES.SAME_VALUE_CANNOT_BE_ASSIGNED,
        };
        sameTextRatings.forEach((item) => {
          newRatings[item] = {
            ...prevRatings[item],
            error: CRITICALITY_RATINGS_MESSAGES.SAME_VALUE_CANNOT_BE_ASSIGNED,
          };
        });
      } else {
        newRatings[key] = { text, error: '' };
      }
      /**
       * Checks if any pre-existing values were same as the old text and removes error from them
       */
      const oldSameTextRatings = Object.keys(prevRatings).filter(
        (item) =>
          prevRatings[key].text.toLowerCase() ===
            prevRatings[item].text.toLowerCase() && prevRatings[key].text !== ''
      );
      /**
       * If oldSameTextRatings.length > 2, error continues to be rendered for the ones which have same text
       */
      if (oldSameTextRatings.length > 2) {
        oldSameTextRatings.forEach((item) => {
          if (key !== item) {
            newRatings[item] = {
              ...newRatings[item],
              error: CRITICALITY_RATINGS_MESSAGES.SAME_VALUE_CANNOT_BE_ASSIGNED,
            };
          }
        });
      } else {
        /**
         * Else keeps the error same as before
         */
        oldSameTextRatings.forEach((item) => {
          if (key !== item) {
            newRatings[item] = {
              ...newRatings[item],
              error:
                newRatings[item].error ===
                CRITICALITY_RATINGS_MESSAGES.ENSURE_ALL_FIELDS_ARE_FILLED
                  ? newRatings[item].error
                  : '',
            };
          }
        });
      }
      return newRatings;
    });
  };

  const checkIfChangesExist = () => {
    let areTheObjectsDifferent = false;
    const changedObjects = [];
    Object.keys(ratingsObject).forEach((item) => {
      if (ratingsObject[item].text !== ratings[item].text) {
        areTheObjectsDifferent = true;
        changedObjects.push({ rating: item, ...ratings[item] });
      }
    });
    return { changedObjects, areTheObjectsDifferent };
  };

  const saveHandler = () => {
    const { changedObjects } = checkIfChangesExist();
    const areAllFieldsFilled = Object.keys(ratings).every(
      (item) => ratings[item].text.length
    );
    /**
     * Checks that all fields are filled and shows appropriate error if not
     */
    if (!areAllFieldsFilled) {
      setRatings((prevRatings) => {
        const newRatings = {
          ...prevRatings,
        };
        Object.keys(ratings).forEach((item) => {
          if (!ratings[item].text.length) {
            newRatings[item] = {
              ...newRatings[item],
              error: CRITICALITY_RATINGS_MESSAGES.ENSURE_ALL_FIELDS_ARE_FILLED,
            };
          }
        });
        return newRatings;
      });
    } else {
      /**
       * Checks that all fields are filled before making API call
       */
      const areAllFieldsValid = Object.keys(ratings).every(
        (item) => !ratings[item].error.length
      );
      if (areAllFieldsValid) {
        setRatings((prevRatings) => {
          const newRatings = {
            ...prevRatings,
          };
          Object.keys(ratings).forEach((item) => {
            newRatings[item] = {
              ...newRatings[item],
              error: '',
            };
          });
          return newRatings;
        });
        const postBody = [];
        changedObjects.forEach((item) => {
          postBody.push({
            rating: parseInt(item.rating, 10),
            criticalityValue: item.text,
          });
        });
        onSave(postBody);
      }
    }
  };

  const isSaveEnabled = () => checkIfChangesExist().areTheObjectsDifferent;

  return (
    <Accordion
      expanded={expanded}
      onChange={() => expandUpdate(SOC_TYPES.CRITICALITY_RATINGS)}
      data-testid="smf_criticality_rating"
    >
      <Accordion.AccordionSummary
        aria-controls="soc-criticalityRatings"
        id="soc-criticalityRatings"
      >
        <Text isHeading text={SOC_MESSAGES.CRITERIA_FOR_CRITICALITY_RATINGS} />
        <Accordion.AccordionStatus status={status} />
      </Accordion.AccordionSummary>
      <Accordion.AccordionDetails>
        <Grid
          item
          sx={{
            padding: '2em 0em',
            backgroundColor: (theme) => theme.customColors.blackGrey,
          }}
        >
          <CriticalityRatingsBody
            ratings={ratings}
            onChangeRatings={onChangeRatings}
          />
        </Grid>
        <Grid
          container
          item
          justifyContent="flex-end"
          sx={{
            background: (theme) =>
              `${alpha(theme.palette.background.darkishBlackBlue, 0.5)}`,
            border: (theme) =>
              `0.0625em solid ${alpha(theme.palette.border.darkGrey, 0.3)}`,
            margin: 'auto',
            padding: '0.34375em',
          }}
        >
          <Button
            data-testid="smf_savebtn"
            text={SOC_MESSAGES.SAVE}
            disabled={!isSaveEnabled()}
            onClick={saveHandler}
          >
            <Text isBody text={SOC_MESSAGES.SAVE} />
          </Button>
        </Grid>
      </Accordion.AccordionDetails>
    </Accordion>
  );
};

CriticalityRatings.propTypes = {
  expanded: PropTypes.bool,
  expandUpdate: PropTypes.func,
  status: PropTypes.string,
  criticalityRatings: PropTypes.shape({
    1: PropTypes.string,
    2: PropTypes.string,
    3: PropTypes.string,
    4: PropTypes.string,
    5: PropTypes.string,
    6: PropTypes.string,
    7: PropTypes.string,
    8: PropTypes.string,
  }),
  onSave: PropTypes.func,
  socStatusDispatch: PropTypes.func,
};

export default CriticalityRatings;

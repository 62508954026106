import React, { forwardRef } from 'react';
import PropTypes from 'prop-types';
import {
  TextField as MuiTextField,
  InputAdornment,
  styled,
} from '@mui/material';

const CustomTextField = styled(MuiTextField, {
  shouldForwardProp: (prop) => prop !== 'textAlign',
})(({ theme, textAlign }) => ({
  '& label.Mui-focused': {
    color: theme.palette.text.primary,
  },
  '& .MuiOutlinedInput-input.Mui-disabled': {
    color: theme.palette.background.shuttleGray,
    WebkitTextFillColor: theme.palette.background.shuttleGray,
    cursor: 'not-allowed',
  },
  '& .MuiOutlinedInput-input': {
    padding: '0.6em 0 0.6em 0.8em',
    fontSize: '14px',
    fontWeight: 400,
    textAlign,
    color: theme.palette.text.primary,
    '&[type=number]': {
      MozAppearance: 'textfield',
    },
    '&::-webkit-outer-spin-button': {
      WebkitAppearance: 'none',
      margin: 0,
    },
    '&::-webkit-inner-spin-button': {
      WebkitAppearance: 'none',
      margin: 0,
    },
  },
  '& .MuiTypography-colorTextSecondary': {
    color: theme.palette.text.primary,
  },
  '& .MuiFormHelperText-root.Mui-error': {
    color: theme.palette.text.errorText,
    fontSize: '0.75rem',
    marginLeft: '0.2rem',
    minWidth: '100%',
  },
  '& .MuiOutlinedInput-root': {
    backgroundColor: theme.palette.background.blackGrey,
    height: '2em',
    '& fieldset': {
      borderColor: theme.palette.border.lightWhiteGrey,
    },
    '&:hover fieldset': {
      borderColor: theme.palette.border.primary,
    },
    '&.Mui-focused fieldset': {
      borderColor: theme.palette.selected.greenSelect,
    },
    '&.Mui-disabled fieldset': {
      borderColor: theme.palette.border.lightWhiteGrey,
    },
  },
  '& .MuiFormHelperText-root': {
    color: theme.palette.text.primary,
    marginLeft: '0.2rem',
  },
  '& .MuiInputAdornment-root .MuiTypography-root': {
    color: theme.palette.text.primary,
  },
}));

const TextField = forwardRef((props, ref) => {
  const {
    value,
    variant,
    error,
    helperText,
    type,
    onChange,
    suffixText = '',
    maxLen = 5,
    placeholder,
    customInputProps = {},
    fullWidth = true,
    disabledGrey = false,
    textAlign = 'left',
    ...other
  } = props;
  return (
    <CustomTextField
      data-testid="smf_textfield"
      disabled={disabledGrey}
      value={value}
      error={error || false}
      helperText={helperText || ''}
      variant={variant || 'outlined'}
      type={type || 'text'}
      onChange={onChange}
      fullWidth={fullWidth}
      InputProps={{
        endAdornment: (
          <InputAdornment position="end">{suffixText}</InputAdornment>
        ),
      }}
      className="custom-text-field"
      inputProps={{
        maxLength: maxLen,
        form: {
          autocomplete: 'off',
        },
        ...customInputProps,
      }}
      placeholder={placeholder}
      autoComplete="no"
      ref={ref}
      {...other}
      textAlign={textAlign}
    />
  );
});

TextField.displayName = 'TextField';

TextField.propTypes = {
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  variant: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  error: PropTypes.bool,
  helperText: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  type: PropTypes.string,
  suffixText: PropTypes.string,
  maxLen: PropTypes.number,
  placeholder: PropTypes.string,
  customInputProps: PropTypes.shape({}),
  fullWidth: PropTypes.bool,
  disabledGrey: PropTypes.bool,
  textAlign: PropTypes.string,
};

export default TextField;

import { useState } from 'react';
import { Box, Grid, IconButton, styled } from '@mui/material';
import Filter from '../../../components/Filter';
import { recipeActions } from '../../../context/recipe/actions';
import { useRecipeContext } from '../../../context/recipe/reducer';
import PlusIconIcon from '../../../assets/img/PlusInCircle.svg';
import TEST_IDS from '../../../constants/testids';

const GridItem = styled((props) => <Grid item {...props} />)(() => ({
  display: 'flex',
  alignItems: 'center',
}));

const ListHeader = () => {
  const { recipeDispatch } = useRecipeContext();
  const [identifierAnchorEl, setIdentifierAnchorEl] = useState(null);
  const [nameAnchorEl, setNameAnchorEl] = useState(null);

  return (
    <Grid
      sx={{
        height: '3.5rem',
        border: (theme) => `1px solid ${theme.customColors.wrapperGrey}`,
        borderWidth: '0 1px',
        backgroundColor: (theme) => theme.customColors.blackishGrey,
      }}
      container
    >
      <GridItem xs={0.6} />
      <GridItem xs={3}>
        <Filter
          filterName="identifierFilter"
          displayName="Recipe Identifier"
          anchorEl={identifierAnchorEl}
          setAnchorEl={setIdentifierAnchorEl}
        />
      </GridItem>
      <GridItem xs={7.4}>
        <Filter
          filterName="nameFilter"
          displayName="Recipe Name"
          anchorEl={nameAnchorEl}
          setAnchorEl={setNameAnchorEl}
        />
      </GridItem>
      <GridItem xs={1}>
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'flex-end',
            width: '100%',
          }}
        >
          <IconButton
            sx={{ marginRight: '.5rem' }}
            onClick={() => recipeDispatch(recipeActions.addNewRecipe())}
            data-testid={TEST_IDS.RECIPE_ADD_NEW_ICON}
          >
            <PlusIconIcon />
          </IconButton>
        </Box>
      </GridItem>
    </Grid>
  );
};

export default ListHeader;

import React from 'react';
import { Box, Backdrop, CircularProgress } from '@mui/material';
import PropTypes from 'prop-types';

export default function LoadingIndicator({ size }) {
  return (
    <Backdrop
      sx={{ zIndex: (theme) => theme.zIndex.drawer - 1 }}
      data-testid="smf_backdrop_loader"
      open
    >
      <Box
        sx={{
          color: (theme) => theme.palette.background.default,
          textAlign: 'center',
        }}
      >
        <CircularProgress color="inherit" size={size} />
      </Box>
    </Backdrop>
  );
}

LoadingIndicator.propTypes = {
  size: PropTypes.number,
};

import { createContext, useContext, useReducer } from 'react';
import PropTypes from 'prop-types';
import { RECIPE_ACTIONS } from './actions';
import {
  FILTER_MANIPULATIONS,
  RECIPE_VALIDATIONS_ERROR_MSG,
} from '../../constants';
import {
  checkisRecipeStateDirty,
  getAvailOptionsAndBadgeCount,
  getRecipesSorted,
  recipesFiltering,
} from '../../utils/helperFunctions';
import { cloneDeep } from 'lodash';

import { updateEditState, EDIT_STATE_KEYS } from '@smf/ui-util-app';

const recipeInit = {
  recipesFromAPI: [],
  recipesForList: [],
  recipesResultsCount: 0,
  isRecipesDirty: false,
  showInputErrors: false,
  sorting: { filterName: '', type: '' },
  identifierFilter: {
    key: 'recipeIdentifier',
    optionsFromAPI: [],
    selections: [],
    optionsForFilter: [],
    isAllChecked: false,
    badgeCount: 0,
  },
  nameFilter: {
    key: 'recipeName',
    optionsFromAPI: [],
    selections: [],
    optionsForFilter: [],
    isAllChecked: false,
    badgeCount: 0,
  },
  uploader: {
    showUploader: false,
    fileObject: null,
    isUploadError: false,
    uploadErrors: [],
  },
};

const recipeReducer = (state, action) => {
  switch (action.type) {
    case RECIPE_ACTIONS.SET_API_DATA:
      const recipesWithMeta = action.payload.map((recipe) => ({
        ...recipe,
        isEditMode: false,
      }));

      const identifierOptionsWithMeta = [];
      const nameOptionsWithMeta = [];

      action.payload.forEach((recipe) => {
        identifierOptionsWithMeta.push({
          value: recipe.recipeIdentifier,
          isChecked: false,
        });
        nameOptionsWithMeta.push({
          value: recipe.recipeName,
          isChecked: false,
        });
      });

      return {
        ...state,
        recipesFromAPI: [...recipesWithMeta],
        recipesForList: [...recipesWithMeta],
        recipesResultsCount: recipesWithMeta.length,
        identifierFilter: {
          ...state.identifierFilter,
          optionsFromAPI: identifierOptionsWithMeta,
          optionsForFilter: [...identifierOptionsWithMeta],
        },
        nameFilter: {
          ...state.nameFilter,
          optionsFromAPI: nameOptionsWithMeta,
          optionsForFilter: [...nameOptionsWithMeta],
        },
        sorting: recipeInit.sorting,
        isRecipesDirty: recipeInit.isRecipesDirty,
      };

    case RECIPE_ACTIONS.ADD_NEW_RECIPE:
      const withNewRecipe = state.recipesForList;
      withNewRecipe.unshift({
        isEditMode: true,
        recipeId: null,
        recipeIdentifier: '',
        recipeName: '',
        errors: {
          recipeName: {
            error: true,
            helperText: RECIPE_VALIDATIONS_ERROR_MSG.REQUIRED_FIELDS,
          },
          recipeIdentifier: {
            error: true,
            helperText: RECIPE_VALIDATIONS_ERROR_MSG.REQUIRED_FIELDS,
          },
        },
      });
      updateEditState(
        {},
        { isUnsavedRecipe: true },
        EDIT_STATE_KEYS.SOC_EDIT_STATE
      );
      return {
        ...state,
        isRecipesDirty: true,
        recipesForList: withNewRecipe,
      };

    case RECIPE_ACTIONS.EDIT_RECIPE:
      const withEditedRecipes = state.recipesForList;
      withEditedRecipes.splice(action.payload.position, 1, {
        ...action.payload.recipe,
        isEditMode: true,
      });
      updateEditState(
        {},
        { isUnsavedRecipe: true },
        EDIT_STATE_KEYS.SOC_EDIT_STATE
      );
      return {
        ...state,
        isRecipesDirty: true,
        recipesForList: withEditedRecipes,
      };

    case RECIPE_ACTIONS.TOGGLE_SORTING:
      const { filterName: sortingFilter, sortingType } = action.payload;
      const sortingKey = state[sortingFilter].key;
      const sortedRecipes = getRecipesSorted(
        state.recipesForList,
        sortingKey,
        sortingType
      );
      return {
        ...state,
        sorting: { filterName: sortingFilter, type: sortingType },
        recipesForList: sortedRecipes,
      };

    case RECIPE_ACTIONS.TOGGLE_CHECK:
      const { filterName: checkFilter, value, checked } = action.payload;
      let isAllChecked = checked;
      const checkSelections = state[checkFilter].selections;
      const checkToggleOptions = cloneDeep(state[checkFilter].optionsForFilter);
      const toggledOptions = checkToggleOptions.map((o) => {
        if (o.value === value || value === 'all') {
          o.isChecked = checked;
        }
        isAllChecked = isAllChecked && o.isChecked;
        const valueIndex = checkSelections.indexOf(o.value);
        if (o.isChecked && valueIndex === -1) {
          checkSelections.push(o.value);
        }
        if (!o.isChecked && valueIndex !== -1) {
          checkSelections.splice(valueIndex, 1);
        }
        return o;
      });
      return {
        ...state,
        [checkFilter]: {
          ...state[checkFilter],
          selections: checkSelections,
          optionsForFilter: toggledOptions,
          isAllChecked,
        },
      };

    case RECIPE_ACTIONS.CHECK_RESTORE:
      return {
        ...state,
        [action.payload.filterName]: {
          ...state[action.payload.filterName],
          optionsForFilter: action.payload.checkSnapshot.optionsForFilter,
          selections: action.payload.checkSnapshot.selections,
          isAllChecked: action.payload.checkSnapshot.isAllChecked,
        },
      };

    case RECIPE_ACTIONS.SAVE_RECIPES:
      updateEditState(
        {},
        { isUnsavedRecipe: false },
        EDIT_STATE_KEYS.SOC_EDIT_STATE
      );
      return {
        ...state,
        isRecipesDirty: false,
        isSubmitButtonClicked: false,
      };

    case RECIPE_ACTIONS.SHOW_INPUT_ERRORS:
      return {
        ...state,
        showInputErrors: action.payload,
      };

    case RECIPE_ACTIONS.DELETE_NEW_RECIPE:
      const withRecipeRemoved = state.recipesForList;
      withRecipeRemoved.splice(action.payload, 1);
      updateEditState(
        {},
        { isUnsavedRecipe: checkisRecipeStateDirty(withRecipeRemoved) },
        EDIT_STATE_KEYS.SOC_EDIT_STATE
      );
      return {
        ...state,
        recipesForList: withRecipeRemoved,
        isRecipesDirty: checkisRecipeStateDirty(withRecipeRemoved),
      };

    case RECIPE_ACTIONS.SET_FILTER:
      const { filterName: setFilter, type: setType } = action.payload;

      const isReset = [
        FILTER_MANIPULATIONS.FULL_RESET,
        FILTER_MANIPULATIONS.CHECK_RESET,
      ].includes(setType);

      const setSorting = [
        FILTER_MANIPULATIONS.CHECK_RESET,
        FILTER_MANIPULATIONS.APPLY,
      ].includes(setType);

      const isFullReset = setType === FILTER_MANIPULATIONS.FULL_RESET;

      const setFilterKey = state[setFilter].key;
      const setSiblingFilter =
        setFilter === 'identifierFilter' ? 'nameFilter' : 'identifierFilter';
      const setSiblingFilterKey = state[setSiblingFilter].key;

      const setReferenceRecipes = [...state.recipesFromAPI];

      let recipesAfterSiblingFilterApplied = setReferenceRecipes;

      if (state[setSiblingFilter].badgeCount > 0) {
        recipesAfterSiblingFilterApplied = recipesFiltering(
          setReferenceRecipes,
          setSiblingFilterKey,
          state[setSiblingFilter].selections
        );
      }

      let recipesAfterFilterApplied = recipesAfterSiblingFilterApplied;

      if (!state[setFilter].isAllChecked && !isReset) {
        recipesAfterFilterApplied = recipesFiltering(
          recipesAfterSiblingFilterApplied,
          setFilterKey,
          state[setFilter].selections
        );
      }

      const [, setBadgeCount] = getAvailOptionsAndBadgeCount(
        recipesAfterFilterApplied,
        setFilterKey,
        isReset ? [] : state[setFilter].selections
      );

      const [siblingAvailOptions, siblingBadgeCount] =
        getAvailOptionsAndBadgeCount(
          setBadgeCount > 0 ? recipesAfterFilterApplied : setReferenceRecipes,
          setSiblingFilterKey,
          state[setSiblingFilter].selections
        );

      let recipesAfterSorted = recipesAfterFilterApplied;

      if (setSorting && state.sorting.filterName !== '') {
        recipesAfterSorted = getRecipesSorted(
          recipesAfterFilterApplied,
          state[state.sorting.filterName].key,
          state.sorting.type
        );
      }

      if (isFullReset && setFilter === state.sorting.filterName) {
        recipesAfterSorted.sort((r1, r2) => r1.recipeId < r2.recipeId);
      }

      return {
        ...state,
        recipesForList: recipesAfterSorted,
        recipesResultsCount: recipesAfterSorted.length,
        [setFilter]: {
          ...state[setFilter],
          badgeCount: setBadgeCount,
          isAllChecked:
            setBadgeCount === state[setFilter].optionsForFilter.length,
          ...(isReset && {
            optionsForFilter: state[setFilter].optionsForFilter.map((o) => ({
              ...o,
              isChecked: false,
            })),
            selections: [],
          }),
        },
        [setSiblingFilter]: {
          ...state[setSiblingFilter],
          optionsForFilter: siblingAvailOptions,
          badgeCount: siblingBadgeCount,
          isAllChecked: siblingAvailOptions.length === siblingBadgeCount,
        },
        ...(isFullReset &&
          setFilter === state.sorting.filterName && {
            sorting: recipeInit.sorting,
          }),
      };

    case RECIPE_ACTIONS.RESET_FILTERS:
      return {
        ...state,
        sorting: recipeInit.sorting,
        recipesForList: [...state.recipesFromAPI],
        recipesResultsCount: state.recipesFromAPI.length,
        identifierFilter: {
          ...state.identifierFilter,
          selections: [],
          optionsForFilter: [...state.identifierFilter.optionsFromAPI],
          isAllChecked: false,
          badgeCount: 0,
        },
        nameFilter: {
          ...state.nameFilter,
          selections: [],
          optionsForFilter: [...state.nameFilter.optionsFromAPI],
          isAllChecked: false,
          badgeCount: 0,
        },
      };

    case RECIPE_ACTIONS.SET_UPLOADER:
      return {
        ...state,
        uploader: action.payload,
      };

    default:
      return state;
  }
};

const RecipeContext = createContext();
const RecipeProvider = ({ children }) => {
  const [recipeState, recipeDispatch] = useReducer(recipeReducer, recipeInit);
  return (
    <RecipeContext.Provider value={{ recipeState, recipeDispatch }}>
      {children}
    </RecipeContext.Provider>
  );
};

RecipeProvider.propTypes = {
  children: PropTypes.node,
};

export const useRecipeContext = () => useContext(RecipeContext);
export default RecipeProvider;

const RECIPE_ACTIONS = {
  SET_API_DATA: 'SET_API_DATA',
  ADD_NEW_RECIPE: 'ADD_NEW_RECIPE',
  EDIT_RECIPE: 'EDIT_RECIPE',
  SAVE_RECIPES: 'SAVE_RECIPES',
  SHOW_INPUT_ERRORS: 'SHOW_INPUT_ERRORS',
  TOGGLE_SORTING: 'TOGGLE_SORTING',
  TOGGLE_CHECK: 'TOGGLE_CHECK',
  CHECK_RESTORE: 'CHECK_RESTORE',
  DELETE_NEW_RECIPE: 'DELETE_NEW_RECIPE',
  SET_FILTER: 'SET_FILTER',
  RESET_FILTERS: 'RESET_FILTERS',
  SET_UPLOADER: 'SET_UPLOADER',
};

const recipeActions = {
  setApiData: (apiData) => ({
    type: RECIPE_ACTIONS.SET_API_DATA,
    payload: apiData,
  }),
  addNewRecipe: () => ({ type: RECIPE_ACTIONS.ADD_NEW_RECIPE }),
  editRecipe: (position, recipe) => ({
    type: RECIPE_ACTIONS.EDIT_RECIPE,
    payload: { position, recipe },
  }),
  saveRecipes: () => ({ type: RECIPE_ACTIONS.SAVE_RECIPES }),
  showInputErrors: (status) => ({
    type: RECIPE_ACTIONS.SHOW_INPUT_ERRORS,
    payload: status,
  }),
  toggleSorting: (filterName, sortingType) => ({
    type: RECIPE_ACTIONS.TOGGLE_SORTING,
    payload: { filterName, sortingType },
  }),
  toggleCheck: (filterName, value, checked) => ({
    type: RECIPE_ACTIONS.TOGGLE_CHECK,
    payload: { filterName, value, checked },
  }),
  checkRestore: (filterName, checkSnapshot) => ({
    type: RECIPE_ACTIONS.CHECK_RESTORE,
    payload: { filterName, checkSnapshot },
  }),
  deleteNewRecipe: (position) => ({
    type: RECIPE_ACTIONS.DELETE_NEW_RECIPE,
    payload: position,
  }),
  setFilter: (filterName, type) => ({
    type: RECIPE_ACTIONS.SET_FILTER,
    payload: { filterName, type },
  }),
  resetFilters: () => ({
    type: RECIPE_ACTIONS.RESET_FILTERS,
  }),
  setUploader: (uploaderData) => ({
    type: RECIPE_ACTIONS.SET_UPLOADER,
    payload: uploaderData,
  }),
};

export { RECIPE_ACTIONS, recipeActions };

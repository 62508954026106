import { Box, Typography, ListItem } from '@mui/material';
import { useDropzone } from 'react-dropzone';
import PropTypes from 'prop-types';
import DownloadIcon from '../../assets/img/download.svg';
import UploadIcon from '../../assets/img/whiteUploadIcon.svg';
import FileUploadIcon from '../../assets/img/upload.svg';
import { getAcceptType } from '../../utils/helperFunctions';
import {
  MESSAGE_STRINGS,
  UPLOAD_COMPONENT_MESSAGES,
} from '../../constants/en-us';
import InfoIndicator from '../InfoIndicator';
import Tooltip from '../Tooltip';
import {
  ButtonBaseLabel,
  CustomButtonBase,
  CustomizedDropZone,
  InfoTooltipIcon,
} from './styledPeers';
import FileViewer from './fileViewer';
import TEST_IDS from '../../constants/testids';

const FileUploader = ({
  exportTooltipContent = '',
  onExport = () => {},
  uploadTitle = '',
  showInitialStep = true,
  fileType = 'XLSX',
  getUploadUrl = () => null,
  isLoading = false,
  fileObject = null,
  setFileObject = () => null,
  isFileUploadError = false,
  errorMessages = '',
  uploadPercent = 0,
  isRecipeUpload = false,
  isParentExpanded = false,
}) => {
  const getDropzoneProps = (getRootProps, isPropEnabled = true) => {
    return isPropEnabled ? getRootProps({ className: 'dropzone' }) : {};
  };

  const onDropAccepted = (acceptedFilesData) => {
    setFileObject({
      name: acceptedFilesData[0].name,
      size: acceptedFilesData[0].size,
    });
    getUploadUrl(acceptedFilesData[0]);
  };

  const { acceptedFiles, getRootProps, getInputProps } = useDropzone({
    multiple: false,
    onDropAccepted,
    accept: getAcceptType(fileType),
  });

  const isUploadFromBoxEnabled = showInitialStep || isFileUploadError;

  return (
    <Box display="flex" flexDirection="column" height="100%">
      {!isRecipeUpload && (
        <Box display="flex" alignItems="center" marginBottom=".5rem">
          <Box
            display="flex"
            alignItems="center"
            justifyContent="flex-end"
            flexGrow={1}
          >
            <Box display="flex" alignItems="center" justifyContent="flex-end">
              <CustomButtonBase
                onClick={onExport}
                disabled={false}
                data-testid={TEST_IDS.UPLOADER_EXPORT_BUTTON}
              >
                <DownloadIcon height={32} width={32} />
                <ButtonBaseLabel>
                  {UPLOAD_COMPONENT_MESSAGES.EXPORT_DATA}
                </ButtonBaseLabel>
              </CustomButtonBase>
              {exportTooltipContent && (
                <Tooltip
                  data-testid={TEST_IDS.UPLOADER_EXPORT_TOOLTIP}
                  maxWidth="23.438rem"
                  message={
                    <Typography
                      variant="subtitle1"
                      data-testid={TEST_IDS.UPLOADER_EXPORT_TOOLTIP_CONTENT}
                    >
                      {exportTooltipContent}
                    </Typography>
                  }
                >
                  <InfoTooltipIcon />
                </Tooltip>
              )}
            </Box>
            {!showInitialStep && !isLoading && !isFileUploadError && (
              <Box
                display="flex"
                alignItems="center"
                justifyContent="flex-end"
                marginLeft="1rem"
              >
                <CustomButtonBase
                  data-testid={TEST_IDS.UPLOADER_UPLOAD_BUTTON}
                  {...getDropzoneProps(getRootProps)}
                >
                  <input {...getInputProps()} />
                  <UploadIcon height={32} width={32} />
                  <ButtonBaseLabel>
                    {MESSAGE_STRINGS['TARGETS.uploadCsv']}
                  </ButtonBaseLabel>
                </CustomButtonBase>
                <Tooltip
                  data-testid={TEST_IDS.UPLOADER_UPLOAD_TOOLTIP}
                  maxWidth="23.438rem"
                  message={
                    <Typography
                      variant="subtitle1"
                      data-testid={TEST_IDS.UPLOADER_UPLOAD_TOOLTIP_CONTENT}
                    >
                      {MESSAGE_STRINGS['Criticality.uploadCsv.info']}
                    </Typography>
                  }
                >
                  {isParentExpanded && <InfoTooltipIcon />}
                </Tooltip>
              </Box>
            )}
          </Box>
        </Box>
      )}
      {isFileUploadError && errorMessages && (
        <InfoIndicator>
          {errorMessages.split('\n').map((errMsg, i) => (
            <ListItem key={i} disableGutters>
              <Typography variant="subtitle2" sx={{ textTransform: 'none' }}>
                <span
                  style={{
                    ...(errMsg.split(':').length > 1 && {
                      fontWeight: 'bold',
                    }),
                  }}
                >{`${errMsg.split(':')[0]}`}</span>
                {errMsg.split(':')[1] && `: ${errMsg.split(/:(.*)/s)[1]}`}
              </Typography>
            </ListItem>
          ))}
        </InfoIndicator>
      )}
      <Box
        display="flex"
        height="23.125rem"
        justifyContent="center"
        alignItems="center"
      >
        <CustomizedDropZone
          data-testid={TEST_IDS.UPLOADER_PRIMARY_UPLOAD_BUTTON}
          {...getDropzoneProps(getRootProps, isUploadFromBoxEnabled)}
          width={790}
          minHeight="14.75rem"
          sx={{
            justifyContent: 'center',
            display: 'flex',
            alignItems: 'center',
            flexDirection: 'column',
            paddingY: '1rem',
            '&:hover': {
              cursor: isUploadFromBoxEnabled ? 'pointer' : 'inherit',
            },
          }}
        >
          {showInitialStep && acceptedFiles.length === 0 && (
            <>
              <input {...getInputProps()} />
              {isParentExpanded && (
                <FileUploadIcon
                  width={52}
                  height={50}
                  data-testid={TEST_IDS.UPLOADER_PRIMARY_UPLOAD_ICON}
                />
              )}
              <Typography variant="subtitle1" sx={{ margin: '0.65rem' }}>
                {uploadTitle}
              </Typography>
            </>
          )}
          {(fileObject || acceptedFiles.length > 0) && (
            <>
              <FileViewer
                file={fileObject || acceptedFiles[0]}
                error={isFileUploadError}
                isLoading={isLoading}
                uploadPercent={uploadPercent}
                isRecipeUpload={isRecipeUpload}
              />
              {isFileUploadError && (
                <Box
                  display="flex"
                  alignItmes="center"
                  justifyContent="center"
                  flexDirection="column"
                  marginTop="1rem"
                  alignItems="center"
                >
                  <input {...getInputProps()} />
                  {isParentExpanded && (
                    <FileUploadIcon
                      width={52}
                      height={50}
                      data-testid={TEST_IDS.UPLOADER_RE_UPLOAD_ICON}
                    />
                  )}
                  <Typography variant="subtitle1" sx={{ marginTop: '0.65rem' }}>
                    {UPLOAD_COMPONENT_MESSAGES.REUPLOAD}
                  </Typography>
                </Box>
              )}
            </>
          )}
        </CustomizedDropZone>
      </Box>
    </Box>
  );
};

FileUploader.propTypes = {
  exportTooltipContent: PropTypes.string,
  onExport: PropTypes.func,
  uploadTitle: PropTypes.string,
  showInitialStep: PropTypes.bool,
  fileType: PropTypes.string,
  getUploadUrl: PropTypes.func,
  isLoading: PropTypes.bool,
  fileObject: PropTypes.shape({
    name: PropTypes.string,
    size: PropTypes.number,
  }),
  setFileObject: PropTypes.func,
  isFileUploadError: PropTypes.bool,
  errorMessages: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
  uploadPercent: PropTypes.number,
  isRecipeUpload: PropTypes.bool,
  isParentExpanded: PropTypes.bool,
};

export default FileUploader;

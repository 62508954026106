import { Backdrop, Box, IconButton } from '@mui/material';
import PropTypes from 'prop-types';
import { useState } from 'react';
import RowDownloadIcon from '../../../assets/img/rowDownloadIcon.svg';
import RowDustbinIcon from '../../../assets/img/rowDustbinIcon.svg';
import RowEditPenIcon from '../../../assets/img/rowEditPen.svg';
import RowDownloadIconInverted from '../../../assets/img/rowDownloadIconInverted.svg';
import RowDustbinIconInverted from '../../../assets/img/rowDustbinIconInverted.svg';
import RowEditPenIconInverted from '../../../assets/img/rowEditPenInverted.svg';
import { useRecipeContext } from '../../../context/recipe/reducer';
import { recipeActions } from '../../../context/recipe/actions';
import Confirmer from '../../../components/Confirmer';
import { useMutation, useQueryClient } from 'react-query';
import { QUERY_CONSTANTS } from '../../../utils/queryConstants';
import { MESSAGE_STRINGS, TOAST_REDUCER_CONSTANTS } from '../../../constants';
import { useToastContext } from '../../../context/toastContext';
import { deleteRecipeData } from '../../../utils/apiHelpers';
import LoadingIndicator from '../../../components/LoadingIndicator';
import TEST_IDS from '../../../constants/testids';
import { CONFIRMER_MESSAGES } from '../../../constants/en-us';
import { checkisRecipeStateDirty } from '../../../utils/helperFunctions';
import { SOC_STATUS_ACTIONS } from '../../../context/statusReducer';

const ListOptions = ({
  recipe,
  position,
  showOptions,
  isEditMode,
  exportRecipe,
  afterRecipeDelete,
}) => {
  const { recipeDispatch, recipeState } = useRecipeContext();
  const [isDownloadIconInverted, setDownloadIconInverted] = useState(false);
  const [isDustbinIconInverted, setDustbinIconInverted] = useState(false);
  const [isEditPenIconInverted, setEditPenIconInverted] = useState(false);
  const [openConfirmer, setOpenConfirmer] = useState(false);
  const queryClient = useQueryClient();
  const { toastDispatch } = useToastContext();

  const { mutate: deleteRecipe, isLoading: isDeleteRecipeLoading } =
    useMutation((recipeId) => deleteRecipeData(recipeId), {
      onSuccess: (response) => {
        recipeDispatch(recipeActions.deleteNewRecipe(position));
        afterRecipeDelete(response);
        if (!checkisRecipeStateDirty(recipeState.recipesForList)) {
          recipeDispatch(recipeActions.resetFilters());
          recipeDispatch(recipeActions.showInputErrors(false));
          queryClient.invalidateQueries([QUERY_CONSTANTS.GET_RECIPES]);
        }
        toastDispatch({
          type: TOAST_REDUCER_CONSTANTS.SHOW_SUCCESS_TOAST,
          payload: {
            message: MESSAGE_STRINGS['Toast.message.SUCCESS'],
            direction: 'up',
          },
        });
      },
      onError: (error) => {
        toastDispatch({
          type: TOAST_REDUCER_CONSTANTS.SHOW_ERROR_TOAST,
          payload: {
            message: error.message ?? MESSAGE_STRINGS['Toast.message.ERROR'],
            direction: 'up',
          },
        });
      },
    });

  const deleteHandler = () => {
    if (recipe.recipeId) {
      deleteRecipe(recipe.recipeId);
    } else {
      recipeDispatch(recipeActions.deleteNewRecipe(position));
      if (!checkisRecipeStateDirty(recipeState.recipesForList)) {
        recipeDispatch(recipeActions.showInputErrors(false));
      }
    }
    setOpenConfirmer(false);
  };

  const clickHandler = (type) => {
    if (type === 'edit' && !recipe.isEditMode) {
      recipeDispatch(recipeActions.editRecipe(position, recipe));
    }

    if (type === 'delete') {
      if (recipe.recipeId) {
        setOpenConfirmer(true);
      } else {
        deleteHandler();
      }
    }

    if (type === 'download') {
      exportRecipe([recipe.recipeId]);
    }
  };

  const buttons = [
    {
      type: 'download',
      isInverted: isDownloadIconInverted,
      setInvertion: setDownloadIconInverted,
      icon: <RowDownloadIcon />,
      invertedIcon: <RowDownloadIconInverted />,
      onClick: clickHandler,
      renderMe: showOptions && !isEditMode,
    },
    {
      type: 'delete',
      isInverted: isDustbinIconInverted,
      setInvertion: setDustbinIconInverted,
      icon: <RowDustbinIcon />,
      invertedIcon: <RowDustbinIconInverted />,
      onClick: clickHandler,
      renderMe: showOptions,
    },
    {
      type: 'edit',
      isInverted: isEditPenIconInverted,
      setInvertion: setEditPenIconInverted,
      icon: <RowEditPenIcon />,
      invertedIcon: <RowEditPenIconInverted />,
      onClick: clickHandler,
      renderMe: showOptions && !isEditMode,
    },
  ];

  return (
    <Box sx={{ width: '100%', display: 'flex', alignItems: 'top' }}>
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'flex-end',
          flex: 1,
          height: '3rem',
        }}
      >
        {buttons.map((button, idx) => (
          <div key={idx}>
            {button.renderMe && (
              <IconButton
                data-testid={`${TEST_IDS.RECIPE_LIST_OPTION}-${button.type}-${position}`}
                onClick={() => button.onClick(button.type)}
                onMouseEnter={() => button.setInvertion(true)}
                onMouseLeave={() => button.setInvertion(false)}
              >
                {button.isInverted ? button.invertedIcon : button.icon}
              </IconButton>
            )}
          </div>
        ))}
      </Box>
      {openConfirmer && (
        <Backdrop open sx={{ zIndex: (theme) => theme.zIndex.drawer + 2 }}>
          <Confirmer
            message={CONFIRMER_MESSAGES.DELETE}
            YesHandler={() => deleteHandler()}
            NoHandler={() => setOpenConfirmer(false)}
          />
        </Backdrop>
      )}
      {isDeleteRecipeLoading && <LoadingIndicator />}
    </Box>
  );
};

ListOptions.propTypes = {
  recipe: PropTypes.object,
  position: PropTypes.number,
  showOptions: PropTypes.bool,
  isEditMode: PropTypes.bool,
  exportRecipe: PropTypes.func,
  afterRecipeDelete: PropTypes.func,
};

export default ListOptions;

export const SOC_TYPES = {
  REFRESH_RATE: 'refreshRate',
  CRITICALITY_RATINGS: 'criteriaForCriticalityRatings',
  CELL_CRITICALITY: 'cellCriticality',
  ASSET_CRITICALITY: 'assetCriticality',
  RECIPE_MANAGEMENT: 'recipeManagement',
  RISK_CATEGORY_THRESHOLDS: 'riskCategoryThresholds',
};

export const ACCORDION_STATUS = {
  NOT_STARTED: 'NOT_STARTED',
  IN_PROGRESS: 'IN_PROGRESS',
  COMPLETED: 'COMPLETED',
};

export const REFRESH_RATE_CONSTANTS = {
  LABEL: 'Risk Factor Watch Lists Refresh Rate',
  DROPDOWN_DEFAULT_VALUE: 'SELECT',
  DROPDOWN_PLACEHOLDER: 'Select',
  MIN_VAL_REFRESHRATE: 1,
};

export const BUTTON_TYPE = {
  PRIMARY: 'primary',
  SECONDARY: 'secondary',
};

export const TOAST_TYPE = {
  SUCCESS: 'SUCCESS',
  ERROR: 'ERROR',
  WARNING: 'WARNING',
  INFO: 'INFO',
};

export const TOAST_REDUCER_CONSTANTS = {
  SHOW_SUCCESS_TOAST: 'SHOW_SUCCESS_TOAST',
  SHOW_ERROR_TOAST: 'SHOW_ERROR_TOAST',
  SHOW_WARNING_TOAST: 'SHOW_WARNING_TOAST',
  SHOW_INFO_TOAST: 'SHOW_INFO_TOAST',
  HIDE_TOAST: 'HIDE_TOAST',
};

export const MESSAGE_STRINGS = {
  //TOAST MESSAGES
  'Toast.message.SUCCESS': 'Your changes have been successfully saved!',
  'Toast.message.ERROR': 'Something went wrong. Please try again later!',
  'Toast.message.WARNING': 'Oops, something went wrong!',
  'Toast.message.INFO': 'Please wait',
};

export const FILE_ACCEPT_TYPES = {
  CSV: '.csv, text/csv',
  IMAGE_PNG_JPEG: '.jpeg,.png,.jpg',
  XLSX: '.xlsx',
};

export const FILE_CONTENT_TYPES = {
  CSV: 'text/csv',
  XLSX: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
};

export const CSV_FILE_UPLOAD_CATEGORIES = {
  RECIPE: 'recipe',
  ASSET_CRITICALITY: 'assetCriticality',
  ASSET_INTELLIGENCE: 'assetHierarchy',
  FAULT_CODES: 'faultCodes',
  CELL_CRITICALITY: 'cellCriticality',
};

export const HTTP_METHODS = {
  PUT: 'PUT',
  POST: 'POST',
  GET: 'GET',
  DELETE: 'DELETE',
};

export const FILE_UPLOAD_STATUSES = {
  DONE: 'done',
  UPLOADING: 'uploading',
  ABORTED: 'aborted',
  RESTARTED: 'restarted',
  REMOVED: 'removed',
  STARTED: 'started',
  READY: 'ready',
  PREPARING: 'preparing',
  GETTING_UPLOAD_PARAMS: 'getting_upload_params',
  HEADERS_RECEIVED: 'headers_received',
  REJECTED_FILE: 'rejected_file_type',
};

export const INFO_TYPES = {
  ERROR: 'error',
  WARNING: 'warning',
  SUCCESS: 'success',
  CONFIRM: 'confirm',
  INFO: 'info',
};

// SOC View
export const SOC_TABS = {
  ALL_RECIPES: 'Add Recipes',
  STANDARD_OPERATING_CONDITIONS: 'Standard Operating Conditions',
};

export const SOC_REDUCER_CONSTANTS = {
  NEXT_STEP: 'NEXT_STEP',
  PREVIOUS_STEP: 'PREVIOUS_STEP',
  ADD_RECIPE: 'ADD_RECIPE',
  OPEN_ADD_RECIPE: 'OPEN_ADD_RECIPE',
  OPEN_MODAL: 'OPEN_MODAL',
  CLOSE_MODAL: 'CLOSE_MODAL',
  CLOSE_MODAL_AND_MARK_STEP_COMPLETE: 'CLOSE_MODAL_AND_MARK_STEP_COMPLETE',
  SET_SHOW_UPLOAD_BUTTON: 'SET_SHOW_UPLOAD_BUTTON',
  SET_IS_UPLOAD_URL_FETCHING: 'SET_IS_UPLOAD_URL_FETCHING',
  SET_PROCESS_CSV_ERROR_MESSAGE: 'SET_PROCESS_CSV_ERROR_MESSAGE',
  SET_SHOW_UPLOAD_SUCCESS_DIALOG_BOX: 'SET_SHOW_UPLOAD_SUCCESS_DIALOG_BOX',
  SET_CSV_PROCESSING: 'SET_CSV_PROCESSING',
  SET_CSV_PROCESSING_ERROR: 'SET_CSV_PROCESSING_ERROR',
  UPDATE_RECIPE_SELECTED: 'UPDATE_RECIPE_SELECTED',
  UPDATE_ALL_RECIPES_SELECTED: 'UPDATE_ALL_RECIPES_SELECTED',
  LOAD_ALL_RECIPES: 'LOAD_ALL_RECIPES',
  SET_TEMPLATE_DOWNLOAD_URL: 'SET_TEMPLATE_DOWNLOAD_URL',
  SET_RECIPE_LIMIT_DATA: 'SET_RECIPE_LIMIT_DATA',
  ON_MESSAGE: 'ON_MESSAGE',
  ON_SECONDARY_UPLOAD: 'ON_SECONDARY_UPLOAD',
  UPLOAD_URL_FETCHING_START: 'UPLOAD_URL_FETCHING_START',
  REMOVE_FILE_DATA: 'REMOVE_FILE_DATA',
  HANDLE_FILE_CHANGE_TRIGGER: 'HANDLE_FILE_CHANGE_TRIGGER',
  SET_FILE_DATA: 'SET_FILE_DATA',
  HANDLE_PROCESS_CSV_SUCCESS: 'HANDLE_PROCESS_CSV_SUCCESS',
  SET_GLOBAL_PROCESSING: 'SET_GLOBAL_PROCESSING',
  SET_THINGS: 'SET_THINGS',
  SET_GLOBAL_AND_CSV_PROCESSING: 'SET_GLOBAL_AND_CSV_PROCESSING',
  SET_INGESTION_FAILED: 'SET_INGESTION_FAILED',
  ON_UPLOAD_SUCCESS: 'ON_UPLOAD_SUCCESS',
};

export const SOC_CONSTANTS = {
  ADD_RECIPE: 'ADD_RECIPE',
  EDIT_RECIPE: 'EDIT_RECIPE',
  DELETE_RECIPE: 'DELETE_RECIPE',
  DOWNLOAD_CSV: 'DOWNLOAD_CSV',
};

const TIMEZONES = {
  WASHINGTON: 'Eastern Standard Time',
  LA: 'Pacific Standard Time',
  CHICAGO: 'Central Standard Time',
  PHOENIX: 'Mountain Standard Time',
  ALASKA: 'Alaska Standard Time',
  HONOLULU: 'Hawaii-Aleutian Standard Time',
};

const TZ_NAME = {
  CHICAGO: 'America/Chicago',
  LA: 'America/Los_Angeles',
  PHOENIX: 'America/Phoenix',
  WASHINGTON: 'America/New_York',
  ALASKA: 'America/Anchorage',
  HONOLULU: 'Pacific/Honolulu',
};

export const LABEL_ZONE_MAPPING = {
  [TZ_NAME.WASHINGTON]: TIMEZONES.WASHINGTON,
  [TZ_NAME.LA]: TIMEZONES.LA,
  [TZ_NAME.CHICAGO]: TIMEZONES.CHICAGO,
  [TZ_NAME.PHOENIX]: TIMEZONES.PHOENIX,
  [TZ_NAME.ALASKA]: TIMEZONES.ALASKA,
  [TZ_NAME.HONOLULU]: TIMEZONES.HONOLULU,
};

export const INTRA_CELL = 'Intra-cell';

export const CREATE_PLANT = {
  PLANT_NAME: 'plantName',
  BUSINESS_UNIT: 'businessUnit',
  CITY: 'city',
  STATE: 'stateCode',
  ZIPCODE: 'zipcode',
  STREET: 'street',
  DEFAULT_PLACEHOLDER_VALUE: 'emptyValue',
};

export const WEB_SOCKET = {
  UPDATE_ASSET_HIERARCHY_CSV: 'updateAssetHierarchyCsv',
  UPDATE_SOC: 'saveSetpointValues',
};

export const WINDOW_EVENTS = {
  BEFORE_UNLOAD: 'beforeunload',
};

export const UPLOAD_ERROR_CODE = {
  INVALID_TYPE: 'file-invalid-type',
  LARGE: 'file-too-large',
  MANY_FILES: 'too-many-files',
};

export const CREATE_PLANT_UPLOAD = {
  MAX_SIZE: 100000,
  MAX_FILE: 1,
};

export const RECIPE_VALIDATIONS_CONSTANTS = {
  RECIPE_NAME_KEY: 'recipeName',
  RECIPE_IDENTIFER_KEY: 'recipeIdentifier',
  RECIPE_NAME_MAX_LENGTH: 64,
  RECIPE_IDENTIFIER_MAX_LENGTH: 32,
  RECIPE_IDENTIFER_REGEX:
    /^(?!\s)(?!.*\s$)[a-zA-Z0-9!@#$%&*(){}\[\]|\\/?><+=_\-,.~\s]*$/,
  RECIPE_NAME_REGEX:
    /^(?!\s)(?!.*\s$)[a-zA-Z0-9!@#$%&*(){}\[\]|\\/?><+=_\-,.~\s]*$/,
};

export const RECIPE_VALIDATIONS_ERROR_MSG = {
  REQUIRED_FIELDS: 'Required Fields',
  INAVLID_CHARACTERS: 'Invalid Character(s)',
  UNIQUE_VALUE: 'Value must be unique',
  RECIPE_IDENTIFER_MAX_LENGTH: `Value must contain ${RECIPE_VALIDATIONS_CONSTANTS.RECIPE_IDENTIFIER_MAX_LENGTH} characters or less`,
  RECIPE_NAME_MAX_LENGTH: `Value must contain ${RECIPE_VALIDATIONS_CONSTANTS.RECIPE_NAME_MAX_LENGTH} characters or less`,
};

export const FILTER_MANIPULATIONS = {
  FULL_RESET: 'FULL_RESET',
  CHECK_RESET: 'CHECK_RESET',
  APPLY: 'APPLY',
};

export const MODAL_TYPES = {
  ERROR: 'error',
  WARNING: 'warning',
  SUCCESS: 'success',
  CONFIRM: 'confirm',
};

export const APP_URL = {
  SOC_CONFIG: '/config/standard_operating_conditions',
  CONFIG: '/config',
};

import React from 'react';
import PropTypes from 'prop-types';
import Accordion from '../../../components/Accordion';
import { Box } from '@mui/material';
import { MESSAGE_STRINGS, SOC_MESSAGES } from '../../../constants/en-us';
import { SOC_TYPES } from '../../../constants';
import FileUploader from '../../../components/FileUploader';
import Text from '../../../components/Text';
import { AipUpdateWarning } from '../AssestCriticality';

const CellCriticality = ({
  expanded,
  expandUpdate,
  status = '',
  isNewRowsAddedForCell = false,
  showInitialStep,
  onDownload,
  isLoading,
  getUploadUrl,
  isUploadError,
  uploadErrorMessage,
  fileObject,
  setFileObject,
  uploadPercent,
}) => {
  return (
    <Accordion
      data-testid="cellCriticality-accordian"
      expanded={expanded}
      onChange={() => expandUpdate(SOC_TYPES.CELL_CRITICALITY)}
    >
      <Accordion.AccordionSummary
        data-testid="cellCriticality-accordianSummary"
        isAlert={isNewRowsAddedForCell}
      >
        <Box sx={{ display: 'flex', alignItems: 'center', width: '100%' }}>
          <Box sx={{ display: 'flex', marginRight: '1rem' }}>
            <Text isHeading text={SOC_MESSAGES.CELL_CRITICALITY} />
          </Box>
          {isNewRowsAddedForCell && (
            <AipUpdateWarning
              message={MESSAGE_STRINGS['CellCriticality.sheetUpdate.warning']}
            />
          )}
        </Box>
        <Accordion.AccordionStatus status={status} />
      </Accordion.AccordionSummary>
      <Accordion.AccordionDetails>
        <Box
          bgcolor="background.blackGrey"
          padding={2}
          justifyContent="center"
          flexDirection="column"
          flex={1}
          display="flex"
        >
          <FileUploader
            onExport={onDownload}
            uploadTitle={
              MESSAGE_STRINGS[
                'CellCriticality.buttonText.uploadAssetCriticality'
              ]
            }
            showInitialStep={showInitialStep}
            fileType="CSV"
            getUploadUrl={getUploadUrl}
            isLoading={isLoading}
            fileObject={fileObject}
            setFileObject={setFileObject}
            isFileUploadError={isUploadError}
            errorMessages={uploadErrorMessage}
            uploadPercent={uploadPercent}
            isParentExpanded={expanded}
          />
        </Box>
      </Accordion.AccordionDetails>
    </Accordion>
  );
};

CellCriticality.propTypes = {
  expanded: PropTypes.bool,
  expandUpdate: PropTypes.func,
  status: PropTypes.string,
  isNewRowsAddedForCell: PropTypes.bool,
  showInitialStep: PropTypes.bool,
  onDownload: PropTypes.func,
  isLoading: PropTypes.bool,
  getUploadUrl: PropTypes.func,
  isUploadError: PropTypes.bool,
  uploadErrorMessage: PropTypes.string,
  fileObject: PropTypes.shape({
    fileName: PropTypes.string,
    fileSize: PropTypes.number,
  }),
  setFileObject: PropTypes.func,
  uploadPercent: PropTypes.number,
};

export default CellCriticality;

import React from 'react';
import PropTypes from 'prop-types';
import Accordion from '../../../components/Accordion';
import { Box } from '@mui/material';
import { SOC_MESSAGES, MESSAGE_STRINGS } from '../../../constants/en-us';
import Tooltip from '../../../components/Tooltip';
import InfoIcon from '../../../assets/img/infoIcon.svg';
import WarningIcon from '../../../assets/img/warningIcon.svg';
import { SOC_TYPES } from '../../../constants';
import FileUploader from '../../../components/FileUploader';
import Text from '../../../components/Text';

export const AipUpdateWarning = ({ message }) => {
  return (
    <Box
      sx={{ display: 'flex', alignItems: 'center', width: '70%', flexGrow: 1 }}
    >
      <WarningIcon width={24} height={24} />

      <Box sx={{ margin: '0 0.5rem' }}>
        <Text isBody isAlert text={message} />
      </Box>
    </Box>
  );
};

AipUpdateWarning.propTypes = { message: PropTypes.string };

const AssetCriticality = ({
  expanded,
  disabled,
  expandUpdate,
  isNewRowsAddedForAsset = false,
  onDownload,
  getUploadUrl,
  isUploadError,
  uploadErrorMessage,
  fileObject,
  setFileObject,
  status = '',
  showInitialStep,
  uploadPercent,
  isLoading,
}) => {
  /*
   * allowing pointer events for info-box, that should not trigger an open when disabled.
   */
  const isOpen = !disabled && expanded;

  return (
    <Accordion
      expanded={isOpen}
      onChange={() => expandUpdate(SOC_TYPES.ASSET_CRITICALITY)}
    >
      <Accordion.AccordionSummary
        aria-controls="panel3d-content"
        id="panel3d-header"
        disabled={disabled}
        isAlert={isNewRowsAddedForAsset}
      >
        <Box sx={{ display: 'flex', alignItems: 'center', width: '100%' }}>
          <Box
            sx={{ display: 'flex', marginRight: '1rem', alignItems: 'center' }}
          >
            <Text isHeading text={SOC_MESSAGES.ASSET_CRITICALITY} />
            <Box marginLeft={1} sx={{ pointerEvents: 'auto' }}>
              <Tooltip
                maxWidth="40rem"
                textSize="0.75rem"
                message={
                  MESSAGE_STRINGS['Asset_Criticality.header.infoIcon.message']
                }
              >
                <InfoIcon />
              </Tooltip>
            </Box>
          </Box>
          {isNewRowsAddedForAsset && (
            <AipUpdateWarning
              message={MESSAGE_STRINGS['ASSET_CRITICALITY.sheetUpdate.warning']}
            />
          )}
        </Box>
        <Accordion.AccordionStatus status={status} />
      </Accordion.AccordionSummary>
      <Accordion.AccordionDetails>
        <Box
          bgcolor="background.blackGrey"
          padding={2}
          justifyContent="center"
          flexDirection="column"
          flex={1}
          display="flex"
        >
          <FileUploader
            onExport={onDownload}
            uploadTitle={
              MESSAGE_STRINGS[
                'ASSET_CRITICALITY.heading.uploadAssetCriticality'
              ]
            }
            isFileUploadError={isUploadError}
            showInitialStep={showInitialStep}
            fileType="CSV"
            getUploadUrl={getUploadUrl}
            isLoading={isLoading}
            fileObject={fileObject}
            setFileObject={setFileObject}
            errorMessages={uploadErrorMessage}
            uploadPercent={uploadPercent}
            isParentExpanded={isOpen}
          />
        </Box>
      </Accordion.AccordionDetails>
    </Accordion>
  );
};

AssetCriticality.propTypes = {
  expanded: PropTypes.bool,
  disabled: PropTypes.bool,
  expandUpdate: PropTypes.func,
  status: PropTypes.string,
  isNewRowsAddedForAsset: PropTypes.bool,
  showInitialStep: PropTypes.bool,
  onDownload: PropTypes.func,
  isLoading: PropTypes.bool,
  getUploadUrl: PropTypes.func,
  isUploadError: PropTypes.bool,
  uploadErrorMessage: PropTypes.string,
  fileObject: PropTypes.shape({
    fileName: PropTypes.string,
    fileSize: PropTypes.number,
  }),
  setFileObject: PropTypes.func,
  uploadPercent: PropTypes.number,
};

export default AssetCriticality;

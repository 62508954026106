import { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import Accordion from '../../../components/Accordion';
import {
  ACCORDION_STATUS,
  APP_URL,
  CSV_FILE_UPLOAD_CATEGORIES,
  FILE_CONTENT_TYPES,
  MESSAGE_STRINGS,
  SOC_TYPES,
  TOAST_REDUCER_CONSTANTS,
} from '../../../constants';
import Text from '../../../components/Text';
import {
  CONFIRMER_MESSAGES,
  SOC_MESSAGES,
  MESSAGE_STRINGS as MESSAGE_STRINGS_ENG,
} from '../../../constants/en-us';
import HeaderSection from './headerSection';
import ListSection from './listSection';
import FooterSection from './footerSection';
import { useRecipeContext } from '../../../context/recipe/reducer';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { navigateToUrl } from 'single-spa';
import {
  saveRecipeData,
  getRecipeData,
  getCriticalityPresignedUrl,
  uploadFileToS3,
} from '../../../utils/apiHelpers';
import { recipeActions } from '../../../context/recipe/actions';
import { useToastContext } from '../../../context/toastContext';
import { QUERY_CONSTANTS } from '../../../utils/queryConstants';
import LoadingIndicator from '../../../components/LoadingIndicator';
import {
  globalstate$,
  websocket,
  updateEditState,
  EDIT_STATE_KEYS,
} from '@smf/ui-util-app';
import { SOC_STATUS_ACTIONS } from '../../../context/statusReducer';
import Uploader from '../../../components/FileUploader';
import useRxjsState from '../../../utils/hooks/useRxjsState';
import Confirmer from '../../../components/Confirmer';
import { Backdrop, Box } from '@mui/material';
import { AipUpdateWarning } from '../AssestCriticality';

const RecipeManagement = ({
  expanded,
  expandUpdate,
  status,
  plantId,
  socStatusDispatch,
  fetchConfigStatuses,
  isNewRowsAddedForSOCParameters,
  updateRecipeEIPWarningState,
}) => {
  const { recipeState, recipeDispatch } = useRecipeContext();
  const [rxjsState, updateRxjsState] = useRxjsState();
  const { toastDispatch } = useToastContext();
  const queryClient = useQueryClient();

  const [uploadPercent, setUploadPercent] = useState(0);
  const [callFetchStatus, setCallFetchStatus] = useState(false);

  const setFileObject = (file) => {
    recipeDispatch(
      recipeActions.setUploader({
        ...recipeState.uploader,
        fileObject: file,
      })
    );
  };

  const afterRecipeDelete = (deleteResponse) => {
    socStatusDispatch({
      type: SOC_STATUS_ACTIONS.UPDATE_SOC_RECIPE_STATUS_FLAG,
      payload: { updatedStatus: deleteResponse.status },
    });
    fetchConfigStatuses();
  };

  const { isFetching: isRecipesFetching } = useQuery(
    [QUERY_CONSTANTS.GET_RECIPES],
    () => getRecipeData(plantId),
    {
      onSuccess: (recipes) => {
        recipeDispatch(recipeActions.setApiData(recipes));
      },
      retry: false,
      refetchOnWindowFocus: false,
    }
  );

  const exportAllRecipe = () => {
    const ids = recipeState.recipesFromAPI.map((recipe) => recipe.recipeId);
    exportRecipe(ids);
  };

  const exportRecipe = (ids) => {
    const messageBody = [];
    ids.forEach((item) => messageBody.push({ recipeId: item }));
    const data = {
      action: 'downloadRecipeCSV',
      plantId,
      recipes: messageBody,
      authorizationToken: globalstate$._value.sessionToken,
    };
    websocket.sendMessage(data);
    toastDispatch({
      type: TOAST_REDUCER_CONSTANTS.SHOW_SUCCESS_TOAST,
      payload: {
        message: 'Your Download will start shortly',
      },
    });
  };

  const { mutate: saveRecipe, isLoading: isSaveRecipeLoading } = useMutation(
    (data) => saveRecipeData(plantId, data),
    {
      onSuccess: (response) => {
        recipeDispatch(recipeActions.resetFilters());
        recipeDispatch(recipeActions.showInputErrors(false));
        queryClient.invalidateQueries([QUERY_CONSTANTS.GET_RECIPES]);
        socStatusDispatch({
          type: SOC_STATUS_ACTIONS.UPDATE_SOC_RECIPE_STATUS_FLAG,
          payload: { updatedStatus: response.status },
        });
        fetchConfigStatuses();
        toastDispatch({
          type: TOAST_REDUCER_CONSTANTS.SHOW_SUCCESS_TOAST,
          payload: {
            message: MESSAGE_STRINGS['Toast.message.SUCCESS'],
            direction: 'up',
          },
        });
      },
      onError: (error) => {
        toastDispatch({
          type: TOAST_REDUCER_CONSTANTS.SHOW_ERROR_TOAST,
          payload: {
            message:
              error?.response?.data?.message ??
              MESSAGE_STRINGS['Toast.message.ERROR'],
            direction: 'up',
          },
        });
      },
    }
  );

  const clearUploadError = () => {
    recipeDispatch(
      recipeActions.setUploader({
        ...recipeState.uploader,
        isUploadError: false,
        uploadErrors: [],
      })
    );
    updateRxjsState({
      recipeUpload: { ...rxjsState.recipeUpload, status: '' },
    });
  };

  const {
    isLoading: isFileUploadUrlLoading,
    isError: isFileUploadUrlLoadingError,
    mutate: getFileUploadUrl,
  } = useMutation(
    (file) => {
      clearUploadError();
      setUploadPercent(0);
      return getCriticalityPresignedUrl({
        plantId: plantId,
        fileName: file.name,
        contentType: FILE_CONTENT_TYPES.XLSX,
        category: CSV_FILE_UPLOAD_CATEGORIES.RECIPE,
      });
    },
    {
      onSuccess: async (res, file) => {
        await uploadFileToS3(res, file);
        setUploadPercent(70);
        setCallFetchStatus(true);
        processUploadedFile(file.name, file.size);
      },
    }
  );

  const processUploadedFile = (fileName, fileSize) => {
    const data = {
      action: 'uploadRecipeCSV',
      plantId,
      fileName,
      authorizationToken: globalstate$._value.sessionToken,
    };
    updateRxjsState({
      recipeUpload: {
        status: 'inProgress',
        fileName,
        fileSize,
      },
    });
    websocket.sendMessage(data);
    toastDispatch({
      type: TOAST_REDUCER_CONSTANTS.SHOW_SUCCESS_TOAST,
      payload: {
        message: 'The XLSX will be processed and status will be notified',
        direction: 'up',
      },
    });
  };

  useEffect(() => {
    if (rxjsState) {
      if (
        rxjsState.recipeUpload &&
        rxjsState.recipeUpload.status === 'failed'
      ) {
        recipeDispatch(
          recipeActions.setUploader({
            ...recipeState.uploader,
            isUploadError: true,
            uploadErrors: rxjsState.recipeUpload.errors,
          })
        );
        updateRxjsState({
          recipeUpload: { ...rxjsState.recipeUpload, status: '' },
        });
      }
      if (
        rxjsState.recipeUpload &&
        rxjsState.recipeUpload.status === 'inProgress'
      ) {
        if (uploadPercent === 0) {
          setUploadPercent(70);
        }
        socStatusDispatch({
          type: SOC_STATUS_ACTIONS.UPDATE_SOC_RECIPE_STATUS_FLAG,
          payload: {
            updatedStatus:
              rxjsState.recipeUpload.uploadStatus ||
              ACCORDION_STATUS.IN_PROGRESS,
          },
        });
      }
      if (
        rxjsState.recipeUpload &&
        rxjsState.recipeUpload.status === 'success'
      ) {
        if (callFetchStatus) {
          fetchConfigStatuses();
          setCallFetchStatus(false);
        }
        updateRecipeEIPWarningState(false);
        socStatusDispatch({
          type: SOC_STATUS_ACTIONS.UPDATE_SOC_RECIPE_STATUS_FLAG,
          payload: {
            updatedStatus:
              rxjsState.recipeUpload.uploadStatus || ACCORDION_STATUS.COMPLETED,
          },
        });
        updateRxjsState({
          recipeUpload: { ...rxjsState.recipeUpload, status: '' },
        });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [rxjsState]);

  const isLoading =
    isRecipesFetching || isSaveRecipeLoading || isFileUploadUrlLoading;

  const { socEditState: { action: socAction = {} } = {} } = rxjsState;
  const isSameLocation = socAction.location === APP_URL.SOC_CONFIG;

  const confirmYesButtonAction = () => {
    const navloc = rxjsState.socEditState?.action?.location;
    updateEditState(
      { isUserNavigating: false, location: '' },
      { isUnsavedRecipe: false },
      EDIT_STATE_KEYS.SOC_EDIT_STATE
    );
    navigateToUrl(navloc);
  };

  const confirmNoButtonAction = () => {
    updateEditState(
      { isUserNavigating: false, location: '' },
      {},
      EDIT_STATE_KEYS.SOC_EDIT_STATE
    );
  };

  return (
    <Box>
      <Accordion
        expanded={expanded}
        onChange={() => expandUpdate(SOC_TYPES.RECIPE_MANAGEMENT)}
        id="soc-recipeManagement"
        data-testid="soc-recipeManagement"
      >
        <Accordion.AccordionSummary
          aria-controls="soc-refreshRate-content"
          id="soc-refreshRate-header"
        >
          <div style={{ marginRight: '.5rem' }}>
            <Text isHeading text={SOC_MESSAGES.RECIPE_MANAGEMENT} />
          </div>
          {isNewRowsAddedForSOCParameters && (
            <AipUpdateWarning
              message={MESSAGE_STRINGS_ENG['SOCView.sheetUpdate.warning']}
            />
          )}
          <Accordion.AccordionStatus status={status} />
        </Accordion.AccordionSummary>
        <Accordion.AccordionDetails data-testid="soc-recipemanagement">
          <>
            {isLoading && <LoadingIndicator />}
            <HeaderSection
              exportAllRecipe={exportAllRecipe}
              getFileUploadURL={getFileUploadUrl}
              isFileUploadError={
                isFileUploadUrlLoadingError ||
                recipeState.uploader.isUploadError
              }
              uploadPercent={uploadPercent}
              isParentExpanded={expanded}
            />
            {!recipeState.uploader.showUploader ? (
              <>
                <ListSection
                  exportRecipe={exportRecipe}
                  afterRecipeDelete={afterRecipeDelete}
                />
                <FooterSection saveRecipe={saveRecipe} />
              </>
            ) : (
              <Uploader
                isRecipeUpload
                uploadTitle="Upload Recipe File"
                showInitialStep={false}
                getUploadUrl={getFileUploadUrl}
                isLoading={
                  isFileUploadUrlLoading ||
                  rxjsState.recipeUpload.status === 'inProgress'
                }
                fileObject={recipeState.uploader.fileObject}
                setFileObject={setFileObject}
                isFileUploadError={
                  isFileUploadUrlLoadingError ||
                  recipeState.uploader.isUploadError
                }
                uploadPercent={uploadPercent}
              />
            )}
          </>
        </Accordion.AccordionDetails>
      </Accordion>
      {Boolean(rxjsState.socEditState?.action?.isUserNavigating) &&
        !isSameLocation && (
          <Backdrop open>
            <Confirmer
              message={CONFIRMER_MESSAGES.UNSAVED_CHANGES}
              YesHandler={confirmYesButtonAction}
              NoHandler={confirmNoButtonAction}
            />
          </Backdrop>
        )}
    </Box>
  );
};

RecipeManagement.propTypes = {
  expanded: PropTypes.bool,
  expandUpdate: PropTypes.func,
  socStatusDispatch: PropTypes.func,
  fetchConfigStatuses: PropTypes.func,
  updateRecipeEIPWarningState: PropTypes.func,
  status: PropTypes.string,
  plantId: PropTypes.string,
  isNewRowsAddedForSOCParameters: PropTypes.bool,
};

export default RecipeManagement;

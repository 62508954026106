import React from 'react';
import { Button as MuiButton, Typography, styled } from '@mui/material';
import PropTypes from 'prop-types';
import { BUTTON_TYPE } from '../../constants';

const PrimaryButton = styled(MuiButton)(({ theme }) => ({
  margin: theme.spacing(0.5),
  color: theme.palette.text.secondary,
  border: `0.0625em solid ${theme.palette.background.saveGreen}`,
  boxSizing: 'border-box',
  borderRadius: '1.25em',
  minWidth: '7.5em',
  background: theme.palette.background.saveGreen,
  '&:hover': { color: theme.palette.text.primary, background: 'inherit' },
  '&:disabled': {
    color: theme.palette.text.lightYellowishGray,
    background: theme.palette.background.darkGrey,
    border: `0.0625em solid ${theme.palette.border.lightRaven}`,
  },
  '& .MuiTypography-root': { textTransform: 'none' },
}));

const SecondaryButton = styled(MuiButton)(({ theme }) => ({
  margin: theme.spacing(0.5),
  color: theme.palette.text.primary,
  border: `0.0625em solid ${theme.palette.text.primary}`,
  boxSizing: 'border-box',
  borderRadius: '1.25em',
  minWidth: '7.5em',
  background: 'inherit',
  '&:hover': {
    color: theme.palette.text.secondary,
    background: theme.palette.text.primary,
  },
  '&:disabled': {
    color: theme.palette.text.lightYellowishGray,
    background: theme.palette.background.darkGrey,
    border: `0.0625em solid ${theme.palette.border.lightRaven}`,
  },
  '& .MuiTypography-root': { textTransform: 'none' },
}));

const Button = ({
  type = BUTTON_TYPE.PRIMARY,
  text,
  size,
  color,
  variant,
  onClick,
  disable,
  children,
  ...other
}) => {
  return type === BUTTON_TYPE.PRIMARY ? (
    <PrimaryButton
      variant={variant || 'contained'}
      size={size || 'medium'}
      color={color || 'primary'}
      onClick={onClick}
      disabled={disable || false}
      {...other}
    >
      {children || <Typography variant="subtitle1">{text}</Typography>}
    </PrimaryButton>
  ) : (
    <SecondaryButton
      variant={variant || 'contained'}
      size={size || 'medium'}
      color={color || 'primary'}
      onClick={onClick}
      disabled={disable || false}
      {...other}
    >
      {children || <Typography variant="subtitle1">{text}</Typography>}
    </SecondaryButton>
  );
};

Button.propTypes = {
  type: PropTypes.string,
  text: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  size: PropTypes.string,
  color: PropTypes.string,
  variant: PropTypes.string,
  disable: PropTypes.bool,
  onClick: PropTypes.func,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
};

export default Button;

import { useReducer, useState } from 'react';
import RefreshRate from './RefreshRate';
import CriticalityRatings from './CriticalityRatings';
import CellCriticality from './CellCriticality';
import AssetCriticality from './AssestCriticality';
import RiskCategoryThresholds from './RiskCategoryThresholds';
import { Box, styled, Typography } from '@mui/material';
import {
  ACCORDION_STATUS,
  CSV_FILE_UPLOAD_CATEGORIES,
  FILE_ACCEPT_TYPES,
  FILE_CONTENT_TYPES,
  SOC_TYPES,
  TOAST_REDUCER_CONSTANTS,
} from '../../constants';
import { useToastContext } from '../../context/toastContext';
import {
  initialSocStatus,
  SOC_STATUS_ACTIONS,
  SocStatusReducer,
} from '../../context/statusReducer';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { some, cloneDeep, omit } from 'lodash';
import {
  downloadAssetCriticalityCSV,
  downloadCellCriticalityCSV,
  getCriticalityPresignedUrl,
  getSocData,
  getUoMs,
  postProcessAssetCriticalityCsv,
  postProcessCellCriticalityCsv,
  QueryConstants,
  updateCriticalityRatings,
  updateRefreshRate,
  updateRiskCategoryThreshold,
  getFactoryConfigStatuses,
  uploadFileToS3,
} from '../../utils/apiHelpers';
import LoadingIndicator from '../../components/LoadingIndicator';
import { MESSAGE_STRINGS, SOC_MESSAGES } from '../../constants/en-us';
import {
  simulateUrlClick,
  updateCriticalityRatingsState,
} from '../../utils/helperFunctions';
import useRxjsState from '../../utils/hooks/useRxjsState';
import { updateRxjsState } from '../../utils/rxjsStateNext';
import RecipeManagement from './RecipeManagement';
import RecipeProvider from '../../context/recipe/reducer';

const BodyBox = styled(Box)(({ theme }) => ({
  backgroundColor: theme.palette.background.layoutBackgroundColor,
  overflowY: 'scroll',
  overflowX: 'hidden',
  padding: '0.3rem',
  margin: '1.5rem',
  flexGrow: 1,
}));

const SOC = () => {
  /**
   ** ESSENTIALS
   */
  const [rxjsState] = useRxjsState();
  const plantId = rxjsState.plantId;
  const { toastDispatch } = useToastContext();
  const queryClient = useQueryClient();

  /**
   ** STANDARD OPERATING CONDITIONS
   */
  const [socData, setSocData] = useState(null);
  const [socStatus, socStatusDispatch] = useReducer(
    SocStatusReducer,
    initialSocStatus
  );

  const [collapse, setCollapse] = useState([
    { type: SOC_TYPES.REFRESH_RATE, expanded: true },
    { type: SOC_TYPES.CRITICALITY_RATINGS, expanded: false },
    { type: SOC_TYPES.CELL_CRITICALITY, expanded: false },
    { type: SOC_TYPES.ASSET_CRITICALITY, expanded: false },
    { type: SOC_TYPES.RISK_CATEGORY_THRESHOLDS, expanded: false },
    { type: SOC_TYPES.RECIPE_MANAGEMENT, expanded: false },
  ]);

  const expandUpdate = (type) => {
    const data = [...collapse];
    data.find((d) => d.expanded).expanded = false;
    data.find((d) => d.type === type).expanded = true;
    setCollapse([...data]);
  };

  const invalidateConfigStatus = (accordionKey = '') => {
    const rest = omit(socStatus, accordionKey);
    if (!some(rest, (val) => val === ACCORDION_STATUS.IN_PROGRESS)) {
      queryClient.invalidateQueries([
        QueryConstants.GET_CONFIG_STATUS,
        plantId,
      ]);
    }
  };

  const { isFetching: isSocDataFetching, isSuccess: isSocDataFetchingSuccess } =
    useQuery(
      [QueryConstants.CBM_GET_DATA, plantId],
      async () => getSocData(plantId),
      {
        onSuccess: (response) => {
          setSocData(response);
          const { cellCriticality, assetCriticality } = response;
          if (cellCriticality.fileName && cellCriticality.fileSize) {
            setCellFileData({
              name: cellCriticality.fileName,
              size: cellCriticality.fileSize,
            });
            setShowCellInitSetup(false);
          }
          if (assetCriticality.fileName && assetCriticality.fileSize) {
            setAssetFileData({
              name: assetCriticality.fileName,
              size: assetCriticality.fileSize,
            });
            setShowAssetInitSetup(false);
          }
          const {
            statuses: {
              assetCriticalityStatus = ACCORDION_STATUS.NOT_STARTED,
              cellCriticalityStatus = ACCORDION_STATUS.NOT_STARTED,
              criteriaCriticalityStatus = ACCORDION_STATUS.NOT_STARTED,
              refreshRateStatus = ACCORDION_STATUS.NOT_STARTED,
              riskCategoryStatus = ACCORDION_STATUS.NOT_STARTED,
              standardOperationRecipeStatus = ACCORDION_STATUS.NOT_STARTED,
            } = {},
          } = response;
          socStatusDispatch({
            type: SOC_STATUS_ACTIONS.SET_ALL_STATUS,
            payload: {
              status: {
                assetCriticalityStatus,
                cellCriticalityStatus,
                criteriaCriticalityStatus,
                refreshRateStatus,
                riskCategoryStatus,
                standardOperationRecipeStatus,
              },
            },
          });
        },
        onError: (error) => {
          if (
            error &&
            error.response &&
            error.response.data &&
            error.response.data.message
          ) {
            toastDispatch({
              type: TOAST_REDUCER_CONSTANTS.SHOW_ERROR_TOAST,
              payload: {
                message: error.response.data.message,
              },
            });
          }
        },
        retry: false,
        refetchOnWindowFocus: false,
        enabled: Boolean(plantId),
      }
    );

  /**
   ** ACTUALS
   */
  const [refreshRateChange, setRefreshRateChange] = useState(() => new Set());
  const [actualsUOM, setActualsUOM] = useState([]);

  const { isFetching: isActualsUoMFetching } = useQuery(
    [QueryConstants.GET_UOMS],
    async () => {
      const response = await getUoMs();
      setActualsUOM(response);
    },
    { retry: false, refetchOnWindowFocus: false }
  );

  const {
    mutate: putRefreshRate,
    reset: resetRefreshRate,
    isLoading: isPutRefreshRateLoading,
  } = useMutation(
    (data) => updateRefreshRate({ ...data, factoryId: plantId }),
    {
      onSuccess: (res, data) => {
        socStatusDispatch({
          type: SOC_STATUS_ACTIONS.UPDATE_REFRESH_RATE_STATUS_FLAG,
          payload: { updatedStatus: ACCORDION_STATUS.COMPLETED },
        });
        fetchConfigStatuses();
        setSocData((prevSocData) => ({
          ...prevSocData,
          refreshRate: { ...data, value: parseInt(data.value, 10) },
        }));
        setRefreshRateChange(new Set());
        toastDispatch({
          type: TOAST_REDUCER_CONSTANTS.SHOW_SUCCESS_TOAST,
          payload: {
            message:
              MESSAGE_STRINGS['SOCView.Refresh_Rate.api.success'] ||
              res.message,
          },
        });
        resetRefreshRate();
        invalidateConfigStatus('refreshRatePhase');
      },
      onError: (error) => {
        toastDispatch({
          type: TOAST_REDUCER_CONSTANTS.SHOW_ERROR_TOAST,
          payload: {
            message: error.message,
          },
        });
      },
    }
  );

  /**
   ** CRITICALITY RATINGS
   */
  const {
    mutate: putCriticalityRatings,
    isLoading: isPutCriticalityRatingsLoading,
    reset: resetPutCriticalityRatings,
  } = useMutation(
    (data) =>
      updateCriticalityRatings({
        itemsToBeUpdated: data,
        factoryId: plantId,
      }),
    {
      onSuccess: (res, data) => {
        socStatusDispatch({
          type: SOC_STATUS_ACTIONS.UPDATE_CRITICALITY_RTINGS_STATUS_FLAG,
          payload: { updatedStatus: ACCORDION_STATUS.COMPLETED },
        });
        setSocData((prevSocData) => ({
          ...prevSocData,
          criticalityRating: updateCriticalityRatingsState(
            cloneDeep(prevSocData.criticalityRating),
            data
          ),
        }));
        fetchConfigStatuses();
        toastDispatch({
          type: TOAST_REDUCER_CONSTANTS.SHOW_SUCCESS_TOAST,
          payload: {
            message:
              MESSAGE_STRINGS['SOCView.Criticality_Ratings.api.success'] ||
              res.message,
          },
        });
        resetPutCriticalityRatings();
        invalidateConfigStatus('criteriaCriticalityPhase');
      },
      onError: (error) => {
        if (
          error.response &&
          error.response.data &&
          error.response.data.message
        ) {
          toastDispatch({
            type: TOAST_REDUCER_CONSTANTS.SHOW_ERROR_TOAST,
            payload: {
              message: error.response.data.message,
            },
          });
        }
        resetPutCriticalityRatings();
      },
    }
  );

  /**
   ** CELL CRITIACALITY
   */
  const [showCellInitSetup, setShowCellInitSetup] = useState(true);
  const [cellFileData, setCellFileData] = useState(null);
  const [cellUploadPercent, setCellUploadPercent] = useState(0);
  const [processCellErrorMessage, setProcessCellErrorMessage] = useState(null);

  const {
    isFetching: isDownloadCellUrlFetching,
    refetch: fetchDownloadCellUrl,
  } = useQuery(
    [QueryConstants.GET_CELL_CRITICALITY_CSV],
    () => downloadCellCriticalityCSV(plantId),
    {
      onSuccess: (response) => {
        const { presignedUrl, status } = response;
        if (presignedUrl) {
          simulateUrlClick(response.presignedUrl, FILE_ACCEPT_TYPES.CSV);
        }
        socStatusDispatch({
          type: SOC_STATUS_ACTIONS.UPDATE_CELL_CRITICALITY_STATUS_FLAG,
          payload: { updatedStatus: status },
        });
      },
      onError: (error) => {
        if (error && error.response && error.response.data) {
          toastDispatch({
            type: TOAST_REDUCER_CONSTANTS.SHOW_ERROR_TOAST,
            payload: {
              message: error.response.data,
            },
          });
        }
      },
      refetchOnWindowFocus: false,
      retry: false,
      enabled: false,
    }
  );

  const {
    isLoading: isCellUploadUrlLoading,
    isError: isCellUploadUrlLoadingError,
    mutate: getCellCriticalityUploadUrl,
  } = useMutation(
    (file) => {
      clearCellUploadError();
      setCellUploadPercent(0);
      return getCriticalityPresignedUrl({
        plantId: plantId,
        fileName: file.name,
        contentType: FILE_CONTENT_TYPES.CSV,
        category: CSV_FILE_UPLOAD_CATEGORIES.CELL_CRITICALITY,
      });
    },
    {
      onSuccess: async (res, file) => {
        await uploadFileToS3(res, file);
        setShowCellInitSetup(false);
        setCellUploadPercent(70);
        processCell({ plantId, fileName: file.name });
      },
      onError: () => {
        setShowCellInitSetup(false);
      },
    }
  );

  const {
    isLoading: isProcessCellLoading,
    isError: isProcessCellError,
    mutate: processCell,
    reset: resetProcessCell,
  } = useMutation((data) => postProcessCellCriticalityCsv(data), {
    onSuccess: (res) => {
      setSocData((prevSocData) => ({
        ...prevSocData,
        hasAssetHierarchyChanged: res.hasAssetHierarchyChanged,
        cellCriticality: {
          ...prevSocData.cellCriticality,
          rows: res.rows || prevSocData.cellCriticality.rows,
          csvPreviouslyUploaded: res.isCellCriticalityUploaded,
          isNewRowsAddedForCell: false,
        },
      }));
      fetchConfigStatuses();
      toastDispatch({
        type: TOAST_REDUCER_CONSTANTS.SHOW_SUCCESS_TOAST,
        payload: {
          message:
            MESSAGE_STRINGS['CRITICALITY.caption.fileUploadedSuccessfully'],
        },
      });
      socStatusDispatch({
        type: SOC_STATUS_ACTIONS.UPDATE_CELL_CRITICALITY_STATUS_FLAG,
        payload: { updatedStatus: res.status },
      });
      invalidateConfigStatus('cellCriticalityPhase');
    },
    onError: (error) => {
      if (
        error &&
        error.response &&
        error.response.data &&
        error.response.data.message
      ) {
        setProcessCellErrorMessage(error.response.data.message);
      } else {
        toastDispatch({
          type: TOAST_REDUCER_CONSTANTS.SHOW_ERROR_TOAST,
          payload: {
            message: SOC_MESSAGES.CSV_UPLOAD_ERROR,
          },
        });
      }
    },
  });

  const clearCellUploadError = () => {
    resetProcessCell();
    setProcessCellErrorMessage(null);
  };

  /**
   ** ASSET CRITIACALITY
   */
  const [showAssetInitSetup, setShowAssetInitSetup] = useState(true);
  const [assetFileData, setAssetFileData] = useState(null);
  const [assetUploadPercent, setAssetUploadPercent] = useState(0);
  const [processAssetErrorMessage, setProcessAssetErrorMessage] =
    useState(null);

  const {
    isFetching: isDownloadAssetUrlFetching,
    refetch: fetchDownloadAssetUrl,
  } = useQuery(
    [QueryConstants.GET_ASSET_CRITICALITY_CSV],
    () => downloadAssetCriticalityCSV(plantId),
    {
      onSuccess: (response) => {
        const { presignedUrl, status } = response;
        if (presignedUrl) {
          simulateUrlClick(response.presignedUrl, FILE_ACCEPT_TYPES.CSV);
        }
        socStatusDispatch({
          type: SOC_STATUS_ACTIONS.UPDATE_ASSET_CRITICALITY_STATUS_FLAG,
          payload: { updatedStatus: status },
        });
      },
      onError: (error) => {
        if (error && error.response && error.response.data) {
          toastDispatch({
            type: TOAST_REDUCER_CONSTANTS.SHOW_ERROR_TOAST,
            payload: {
              message: error.response.data,
            },
          });
        }
      },
      refetchOnWindowFocus: false,
      retry: false,
      enabled: false,
    }
  );

  const {
    isLoading: isAssetUploadUrlLoading,
    isError: isAssetUploadUrlLoadingError,
    mutate: getAssetUploadUrl,
  } = useMutation(
    (file) => {
      clearAssetUploadError();
      setAssetUploadPercent(0);
      return getCriticalityPresignedUrl({
        plantId: plantId,
        fileName: file.name,
        contentType: FILE_CONTENT_TYPES.CSV,
        category: CSV_FILE_UPLOAD_CATEGORIES.ASSET_CRITICALITY,
      });
    },
    {
      onSuccess: async (res, file) => {
        await uploadFileToS3(res, file);
        setShowAssetInitSetup(false);
        setAssetUploadPercent(70);
        processAsset({ plantId, fileName: file.name });
      },
      onError: () => {
        setShowAssetInitSetup(false);
      },
    }
  );

  const {
    isLoading: isProcessAssetLoading,
    isError: isProcessAssetError,
    mutate: processAsset,
    reset: resetProcessAsset,
  } = useMutation((data) => postProcessAssetCriticalityCsv(data), {
    onSuccess: (res) => {
      setSocData((prevSocData) => ({
        ...prevSocData,
        hasAssetHierarchyChanged: res.hasAssetHierarchyChanged,
        assetCriticality: {
          ...prevSocData.assetCriticality,
          rows: res.rows || prevSocData.assetCriticality.rows,
          csvPreviouslyUploaded: true,
          isNewRowsAddedForAsset: false,
        },
      }));
      fetchConfigStatuses();
      toastDispatch({
        type: TOAST_REDUCER_CONSTANTS.SHOW_SUCCESS_TOAST,
        payload: {
          message:
            MESSAGE_STRINGS[
              'ASSET_CRITICALITY.caption.fileUploadedSuccessfully'
            ],
        },
      });
      socStatusDispatch({
        type: SOC_STATUS_ACTIONS.UPDATE_ASSET_CRITICALITY_STATUS_FLAG,
        payload: { updatedStatus: res.status },
      });
      invalidateConfigStatus('assetCriticalityPhase');
    },
    onError: (error) => {
      if (
        error &&
        error.response &&
        error.response.data &&
        error.response.data.message
      ) {
        setProcessAssetErrorMessage(error.response.data.message);
      } else {
        toastDispatch({
          type: TOAST_REDUCER_CONSTANTS.SHOW_ERROR_TOAST,
          payload: {
            message: SOC_MESSAGES.CSV_UPLOAD_ERROR,
          },
        });
      }
    },
  });

  const clearAssetUploadError = () => {
    resetProcessAsset();
    setProcessAssetErrorMessage(null);
  };

  /**
   ** RISK CATEGORY THRESHOLDS
   */
  const [isRiskCategorySliderChanged, setIsRiskCategorySliderChanged] =
    useState(false);

  const {
    mutate: putRiskCategoryThreshold,
    reset: resetRiskCategoryThreshold,
    isLoading: isPutRiskCategoryThresholdLoading,
  } = useMutation(
    (data) => updateRiskCategoryThreshold({ ...data, factoryId: plantId }),
    {
      onSuccess: (res) => {
        socStatusDispatch({
          type: SOC_STATUS_ACTIONS.UPDATE_RISK_CATEGORY_STATUS_FLAG,
          payload: { updatedStatus: ACCORDION_STATUS.COMPLETED },
        });
        setIsRiskCategorySliderChanged(false);
        fetchConfigStatuses();
        toastDispatch({
          type: TOAST_REDUCER_CONSTANTS.SHOW_SUCCESS_TOAST,
          payload: {
            message:
              MESSAGE_STRINGS['Risk_Category_Threshold.api.success'] ||
              res.message,
          },
        });
      },
      onError: (error) => {
        toastDispatch({
          type: TOAST_REDUCER_CONSTANTS.SHOW_ERROR_TOAST,
          payload: {
            message: error.message,
          },
        });
      },
    }
  );

  const showLoader =
    isSocDataFetching ||
    isActualsUoMFetching ||
    isPutRefreshRateLoading ||
    isPutCriticalityRatingsLoading ||
    isDownloadCellUrlFetching ||
    isCellUploadUrlLoading ||
    isProcessCellLoading ||
    isDownloadAssetUrlFetching ||
    isAssetUploadUrlLoading ||
    isProcessAssetLoading ||
    isPutRiskCategoryThresholdLoading;

  const isAssetCriticalityDisabled =
    isSocDataFetchingSuccess &&
    !socData?.cellCriticality?.csvPreviouslyUploaded;

  const fetchConfigStatuses = () => {
    setTimeout(() => {
      fetchConfigStatusesQuery();
    }, 4000);
  };

  const updateRecipeEIPWarningState = (showEIPWarning) => {
    setSocData((prevSocData) => ({
      ...prevSocData,
      isNewRowsAddedForSOCParameters: showEIPWarning,
    }));
  };

  const { refetch: fetchConfigStatusesQuery } = useQuery(
    [QueryConstants.GET_CONFIG_STATUSES, plantId],
    async () => {
      return getFactoryConfigStatuses(plantId);
    },
    {
      onSuccess: (response) => {
        const { general: factoryGeneral, factoryHealth, soc } = response;
        updateRxjsState({
          configStatus: {
            ...rxjsState.configStatus,
            factoryGeneral,
            factoryHealth,
            soc,
          },
        });
      },
      enabled: false,
      refetchOnWindowFocus: false,
      retry: false,
    }
  );

  return (
    <Box sx={{ display: 'flex', height: '100%', flexDirection: 'column' }}>
      <Box margin="1.5rem 0 0 1.5rem">
        <Typography style={{ fontWeight: 700 }}>
          Standard Operating Conditions
        </Typography>
      </Box>
      {showLoader && <LoadingIndicator />}
      {isSocDataFetchingSuccess && socData && (
        <BodyBox>
          <RefreshRate
            expanded={
              collapse.find((d) => d.type === SOC_TYPES.REFRESH_RATE).expanded
            }
            expandUpdate={expandUpdate}
            status={socStatus.refreshRatePhase}
            socStatusDispatch={socStatusDispatch}
            putRefreshRate={putRefreshRate}
            refreshRateChange={refreshRateChange}
            setRefreshRateChange={setRefreshRateChange}
            refreshRate={socData.refreshRate}
            actualsUOM={actualsUOM}
          />
          {socData.criticalityRating && (
            <CriticalityRatings
              expanded={
                collapse.find((d) => d.type === SOC_TYPES.CRITICALITY_RATINGS)
                  .expanded
              }
              expandUpdate={expandUpdate}
              status={socStatus.criteriaCriticalityPhase}
              criticalityRatings={socData.criticalityRating}
              onSave={putCriticalityRatings}
              socStatusDispatch={socStatusDispatch}
            />
          )}
          <RecipeProvider>
            {socData.cellCriticality && (
              <CellCriticality
                expanded={
                  collapse.find((d) => d.type === SOC_TYPES.CELL_CRITICALITY)
                    .expanded
                }
                expandUpdate={expandUpdate}
                status={socStatus.cellCriticalityPhase}
                isNewRowsAddedForCell={
                  socData.cellCriticality.isNewRowsAddedForCell
                }
                showInitialStep={showCellInitSetup}
                onDownload={fetchDownloadCellUrl}
                getUploadUrl={getCellCriticalityUploadUrl}
                isLoading={isCellUploadUrlLoading || isProcessCellLoading}
                isUploadError={
                  isCellUploadUrlLoadingError || isProcessCellError
                }
                uploadErrorMessage={processCellErrorMessage}
                fileObject={cellFileData}
                setFileObject={setCellFileData}
                uploadPercent={cellUploadPercent}
              />
            )}
            {socData.assetCriticality && (
              <AssetCriticality
                disabled={isAssetCriticalityDisabled}
                expanded={
                  collapse.find((d) => d.type === SOC_TYPES.ASSET_CRITICALITY)
                    .expanded
                }
                expandUpdate={expandUpdate}
                status={socStatus.assetCriticalityPhase}
                isNewRowsAddedForAsset={
                  socData.assetCriticality.isNewRowsAddedForAsset
                }
                showInitialStep={showAssetInitSetup}
                onDownload={fetchDownloadAssetUrl}
                isLoading={isAssetUploadUrlLoading || isProcessAssetLoading}
                getUploadUrl={getAssetUploadUrl}
                isUploadError={
                  isAssetUploadUrlLoadingError || isProcessAssetError
                }
                uploadErrorMessage={processAssetErrorMessage}
                fileObject={assetFileData}
                setFileObject={setAssetFileData}
                uploadPercent={assetUploadPercent}
              />
            )}
            {socData && (
              <RecipeManagement
                expanded={
                  collapse.find((d) => d.type === SOC_TYPES.RECIPE_MANAGEMENT)
                    .expanded
                }
                expandUpdate={expandUpdate}
                status={socStatus.recipeManagementPhase}
                socStatusDispatch={socStatusDispatch}
                plantId={plantId}
                fetchConfigStatuses={fetchConfigStatuses}
                updateRecipeEIPWarningState={updateRecipeEIPWarningState}
                isNewRowsAddedForSOCParameters={
                  socData.isNewRowsAddedForSOCParameters
                }
              />
            )}
          </RecipeProvider>
          {socData && (
            <RiskCategoryThresholds
              expanded={
                collapse.find(
                  (d) => d.type === SOC_TYPES.RISK_CATEGORY_THRESHOLDS
                ).expanded
              }
              expandUpdate={expandUpdate}
              status={socStatus.riskCategoryPhase}
              socStatusDispatch={socStatusDispatch}
              putRiskCategoryThreshold={putRiskCategoryThreshold}
              resetRiskCategoryThreshold={resetRiskCategoryThreshold}
              isPutRiskCategoryThresholdLoading={
                isPutRiskCategoryThresholdLoading
              }
              riskCategories={socData.riskCategories}
              setIsRiskCategorySliderChanged={setIsRiskCategorySliderChanged}
              isRiskCategorySliderChanged={isRiskCategorySliderChanged}
            />
          )}
        </BodyBox>
      )}
    </Box>
  );
};

export default SOC;

import {
  standardOperatingConditions,
  recipe,
  factoryHealth,
  entity,
} from '@smf/ui-util-app';

export const QueryConstants = {
  GET_CONFIG_STATUS: 'getConfigStatus',
  GET_CELL_CRITICALITY_CSV: 'getCellCiticality',
  GET_ASSET_CRITICALITY_CSV: 'getAssetCiticality',
  GET_UOMS: 'getUOMS',
  GET_RECIPE: 'getRecipe',
  CBM_GET_DATA: 'fetchCbmData',
  GET_SOC_RECIPE_LIMIT: 'getSocRecipeDownloadLimit',
  GET_SOC_RISKTYPE: 'getSocRiskType',
  GET_CONFIG_STATUSES: 'getFactoryConfigStatuses',
};

export async function getSocData(id) {
  return standardOperatingConditions.getSocData(id);
}

export async function getUoMs() {
  return standardOperatingConditions.getUoMs();
}

export async function updateRefreshRate(data) {
  return standardOperatingConditions.updateRefreshRate(data);
}

export async function updateRiskCategoryThreshold(data) {
  return standardOperatingConditions.updateRiskCategoryThreshold(data);
}

export async function updateCriticalityRatings(data) {
  return standardOperatingConditions.updateCriticalityRatings(data);
}

export async function downloadCellCriticalityCSV(id) {
  return standardOperatingConditions.downloadCellCriticalityCSV(id);
}

export async function postProcessCellCriticalityCsv(params) {
  return standardOperatingConditions.postProcessCellCriticalityCsv(params);
}

export async function getCriticalityPresignedUrl(params) {
  const result = await standardOperatingConditions.getCriticalityPresignedUrl(
    params
  );
  return result.data;
}

export async function downloadAssetCriticalityCSV(id) {
  return standardOperatingConditions.downloadAssetCriticalityCSV(id);
}

export async function postProcessAssetCriticalityCsv(params) {
  return standardOperatingConditions.postProcessAssetCriticalityCsv(params);
}

export async function getRecipeData(plantId) {
  return recipe.getRecipes(plantId);
}

export async function saveRecipeData(plantId, recipeList) {
  return recipe.updateRecipes({ plantId, recipeList });
}

export async function deleteRecipeData(recipeId) {
  return recipe.deleteRecipe(recipeId);
}

export async function getSocRecipeRowsLimit(id) {
  return recipe.getSocRecipeRowsLimit(id);
}

export async function getriskTypes() {
  return standardOperatingConditions.getSocRiskTypes();
}

export async function getFactoryConfigStatuses(entityId) {
  return factoryHealth.getFactoryConfigStatuses(entityId);
}

export async function uploadFileToS3(presignedUrl, file) {
  return entity.uploadEquipmentIntelligence(presignedUrl, file);
}

import React from 'react';
import PropTypes from 'prop-types';
import {
  Accordion as MuiAccordion,
  AccordionSummary as MuiAccordionSummary,
  AccordionDetails as MuiAccordionDetails,
  alpha,
  Box,
  styled,
} from '@mui/material';
import DownArrow from '../../assets/img/downArrow.svg';
import { ACCORDION_STATUS } from '../../constants';
import { getStatusTextFromConstants } from '../../utils/helperFunctions';
import Text from '../Text';

const CustomAccordion = styled(MuiAccordion)(({ theme }) => ({
  width: '100%',
  placeSelf: 'center',
  boxShadow: 'none',
  margin: '0 0 0.3em 0',
  background: theme.palette.background.configLighterDark,
  '&:not(:last-child)': {
    borderBottom: 0,
  },
  '&:before': {
    display: 'none',
  },
  '&.Mui-expanded': {
    margin: '0 0 0.3rem 0',
  },
}));

const CustomAccordionSummary = styled(MuiAccordionSummary, {
  shouldForwardProp: (prop) => prop !== 'isAlert',
})(({ theme, isAlert }) => ({
  backgroundColor: theme.palette.background.darkishBlackBlue,
  border: `0.0625em solid ${alpha(theme.palette.border.darkGrey, 0.3)}`,
  borderBottom: `1px solid ${alpha(theme.customColors.black, 0.125)}`,
  paddingLeft: '1.5rem',
  paddingRight: '1.5rem',
  minHeight: '3em',
  height: isAlert ? '' : '3em',
  '&.Mui-expanded': {
    minHeight: '3em',
  },
  '&.Mui-disabled': {
    color: theme.palette.text.darkGrey,
    opacity: 1,
    '& .MuiAccordionSummary-expandIconWrapper': {
      filter:
        'brightness(0) saturate(100%) invert(35%) sepia(3%) saturate(630%) hue-rotate(175deg) brightness(96%) contrast(98%)',
    },
  },
  '& .MuiAccordionSummary-content': {
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  '& .MuiAccordionSummary-expandIconWrapper': {
    marginLeft: '12px',
  },
}));

function AccordionSummary({ expandIcon = null, children, ...props }) {
  return (
    <CustomAccordionSummary
      expandIcon={expandIcon || <DownArrow height={8} width={11} />}
      {...props}
    >
      {children}
    </CustomAccordionSummary>
  );
}

AccordionSummary.propTypes = {
  expandIcon: PropTypes.node,
  children: PropTypes.node,
};

const CustomAccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
  backgroundColor: theme.palette.background.configLighterDark,
  padding: 0,
  overflowX: 'hidden',
  overflowY: 'auto',
}));

function AccordionDetails({ children, ...other }) {
  return <CustomAccordionDetails {...other}>{children}</CustomAccordionDetails>;
}
AccordionDetails.propTypes = {
  children: PropTypes.node,
};

function AccordionStatus(props) {
  const { status = ACCORDION_STATUS.NOT_STARTED, disabled = false } = props;
  return (
    <Box
      display="flex"
      alignItems="center"
      bgcolor="background.gunmetalGrey"
      justifyContent="center"
      paddingY={0.5}
      paddingX={1}
      borderRadius={1}
      minWidth="5.8rem"
    >
      <Text
        isBody
        isDisabled={disabled}
        text={getStatusTextFromConstants(status)}
      />
    </Box>
  );
}

AccordionStatus.propTypes = {
  status: PropTypes.string,
  disabled: PropTypes.bool,
};
function CustomizedAccordions(props) {
  const { children, ...other } = props;

  return <CustomAccordion {...other}>{children}</CustomAccordion>;
}

CustomizedAccordions.propTypes = {
  children: PropTypes.node.isRequired,
};

const CustomBoxWrapper = styled(Box)(({ theme }) => ({
  backgroundColor: alpha(theme.palette.background.darkishBlackBlue, 0.5),
  '& > div:last-child': {
    border: `.05em solid ${alpha(theme.palette.border.darkGrey, 0.3)}`,
  },
}));

function DefaultBoxWrapper({ children }) {
  return <CustomBoxWrapper width="100%">{children}</CustomBoxWrapper>;
}

DefaultBoxWrapper.propTypes = {
  children: PropTypes.node,
};

const CustomDarkBox = styled(Box)(({ theme }) => ({
  background: theme.palette.background.configLighterDark,
  padding: '0.5rem',
  flex: 1,
  overflow: 'auto',
}));

function AccordionsDarkContainer({ children }) {
  return <CustomDarkBox width="100%">{children}</CustomDarkBox>;
}

AccordionsDarkContainer.propTypes = {
  children: PropTypes.node,
};

CustomizedAccordions.AccordionSummary = AccordionSummary;
CustomizedAccordions.AccordionDetails = AccordionDetails;
CustomizedAccordions.DefaultBoxWrapper = DefaultBoxWrapper;
CustomizedAccordions.AccordionsDarkContainer = AccordionsDarkContainer;
CustomizedAccordions.AccordionStatus = AccordionStatus;

export default CustomizedAccordions;

import StandardOperatingConditions from './containers/SOC';
import { ThemeProvider } from '@mui/material';
import { theme } from '@smf/ui-util-app';
import { ToastProvider } from './context/toastContext';
import ReactQueryProvider from './context/react-queryContext';

export default function Root() {
  return (
    <ReactQueryProvider>
      <ThemeProvider theme={theme}>
        <ToastProvider>
          <StandardOperatingConditions />
        </ToastProvider>
      </ThemeProvider>
    </ReactQueryProvider>
  );
}

import React from 'react';
import PropTypes from 'prop-types';
import { Typography, styled } from '@mui/material';

const HeadingText = styled(Typography)(() => ({
  fontSize: '14px',
  fontWeight: 700,
}));

const BodyText = styled(Typography, {
  shouldForwardProp: (prop) =>
    prop !== 'isDisabled' && prop !== 'isError' && prop !== 'isAlert',
})(({ theme, isDisabled, isError, isAlert }) => ({
  fontSize: '14px',
  fontWeight: 400,
  color: isDisabled
    ? theme.palette.background.darkGrey
    : isError
    ? theme.palette.text.errorText
    : isAlert
    ? theme.palette.text.lightYellowishGray
    : 'inherit',
}));

const Text = ({
  isHeading = false,
  isBody = false,
  isDisabled = false,
  isError = false,
  isAlert = false,
  text,
}) => {
  if (isHeading) return <HeadingText>{text}</HeadingText>;
  if (isBody)
    return (
      <BodyText isDisabled={isDisabled} isError={isError} isAlert={isAlert}>
        {text}
      </BodyText>
    );

  return <Typography>{text}</Typography>;
};

Text.propTypes = {
  isHeading: PropTypes.bool,
  isBody: PropTypes.bool,
  isDisabled: PropTypes.bool,
  isError: PropTypes.bool,
  isAlert: PropTypes.bool,
  text: PropTypes.string || PropTypes.number,
};

export default Text;

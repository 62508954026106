import React, { useState, useEffect } from 'react';
import Accordion from '../../../components/Accordion';
import Tooltip from '../../../components/Tooltip';
import Text from '../../../components/Text';
import Slider from './CustomSlider';
import { alpha, Box, Grid, styled } from '@mui/material';
import Button from '../../../components/Button';
import { SOC_MESSAGES, MESSAGE_STRINGS } from '../../../constants/en-us';
import { SOC_TYPES, ACCORDION_STATUS } from '../../../constants';
import { SOC_STATUS_ACTIONS } from '../../../context/statusReducer';
import InfoIcon from '../../../assets/img/infoIcon.svg';
import PropTypes from 'prop-types';

const DEFAULT_VALUES = {
  colors: {
    insignificant: '#c4c4c4',
    minor: '#62b5e5',
    moderator: '#ffcd00',
    major: '#ed8b00',
    catostrophic: '#da291c',
  },
  values: [5, 10, 15, 25],
};

const FooterGrid = styled(Grid)(({ theme }) => ({
  background: `${alpha(theme.palette.background.darkishBlackBlue, 0.5)}`,
  border: `0.0625em solid ${alpha(theme.palette.border.darkGrey, 0.3)}`,
  margin: 'auto',
  padding: '0.34375em',
}));

const RiskCategoryThresholdsView = ({
  expanded,
  expandUpdate = () => null,
  status = '',
  socStatusDispatch = () => null,
  putRiskCategoryThreshold,
  isPutRiskCategoryThresholdLoading,
  riskCategories,
  setIsRiskCategorySliderChanged,
  isRiskCategorySliderChanged,
}) => {
  const [sliderValues, setSliderValues] = useState(DEFAULT_VALUES.values);

  useEffect(() => {
    let categtoryValues = [];
    for (let a in riskCategories) {
      if (riskCategories[a].upperBound !== 100)
        categtoryValues.push(riskCategories[a].upperBound);
    }
    if (categtoryValues.length == 4) {
      categtoryValues = categtoryValues.sort((a, b) => a - b);
      if (categtoryValues[3] === 99) categtoryValues[3] = 100;
      setSliderValues(categtoryValues);
    }
  }, [riskCategories]);

  function saveHandler() {
    let riskCategories = [];
    let lowerLimit = 0;
    for (let key = 0; key < sliderValues.length; key++) {
      const cell = {
        riskTypeId: key + 1,
        lowerBound: lowerLimit,
        upperBound: sliderValues[key],
      };
      riskCategories.push(cell);
      lowerLimit = sliderValues[key] === 100 ? 100 : sliderValues[key] + 1;
    }
    if (riskCategories[3] && riskCategories[3]?.upperBound === 100) {
      riskCategories[3].upperBound = 99;
    }
    const lastcell = {
      riskTypeId: 5,
      lowerBound: lowerLimit,
      upperBound: 100,
    };
    riskCategories.push(lastcell);
    putRiskCategoryThreshold({ riskCategories });
  }

  function onChangeHandler() {
    socStatusDispatch({
      type: SOC_STATUS_ACTIONS.UPDATE_RISK_CATEGORY_STATUS_FLAG,
      payload: { updatedStatus: ACCORDION_STATUS.IN_PROGRESS },
    });
    setIsRiskCategorySliderChanged(true);
  }

  return (
    <Accordion
      expanded={expanded}
      onChange={() => expandUpdate(SOC_TYPES.RISK_CATEGORY_THRESHOLDS)}
    >
      <Accordion.AccordionSummary
        aria-controls="panel4d-content"
        id="panel4d-header"
      >
        <Grid container direction="row" alignItems="center">
          <Box display="flex" marginRight="1rem">
            <Text isHeading text={SOC_MESSAGES.RISK_CATEGORY_THRESHOLDS} />
            <Box marginLeft={1} sx={{ pointerEvents: 'auto' }}>
              <Tooltip
                maxWidth="40rem"
                textSize="0.75rem"
                message={
                  MESSAGE_STRINGS[
                    'Risk_Category_Threshold.header.infoIcon.message'
                  ]
                }
              >
                <InfoIcon />
              </Tooltip>
            </Box>
          </Box>
        </Grid>
        {status && <Accordion.AccordionStatus status={status} />}
      </Accordion.AccordionSummary>
      <Accordion.AccordionDetails
        sx={{ backgroundColor: (theme) => theme.customColors.blackGrey }}
      >
        <Grid container direction="column">
          <Slider
            colors={DEFAULT_VALUES.colors}
            defaultValues={DEFAULT_VALUES.values}
            onChange={onChangeHandler}
            riskCategories={riskCategories}
            sliderValues={sliderValues}
            setSliderValues={setSliderValues}
          />
          <FooterGrid container item justifyContent="flex-end">
            <Button
              text={SOC_MESSAGES.SAVE}
              onClick={saveHandler}
              disabled={
                isPutRiskCategoryThresholdLoading ||
                !isRiskCategorySliderChanged
              }
            >
              <Text isBody text={SOC_MESSAGES.SAVE} />
            </Button>
          </FooterGrid>
        </Grid>
      </Accordion.AccordionDetails>
    </Accordion>
  );
};

RiskCategoryThresholdsView.propTypes = {
  expanded: PropTypes.bool,
  expandUpdate: PropTypes.func,
  status: PropTypes.string,
  socStatusDispatch: PropTypes.func,
  putRiskCategoryThreshold: PropTypes.func,
  isPutRiskCategoryThresholdLoading: PropTypes.bool,
  riskCategories: PropTypes.array,
  setIsRiskCategorySliderChanged: PropTypes.func,
  isRiskCategorySliderChanged: PropTypes.bool,
};

export default RiskCategoryThresholdsView;

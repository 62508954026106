import React, { useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import { alpha, Box } from '@mui/material';
import TextField from '../../../components/TextField';
import Text from '../../../components/Text';
import { CRITICALITY_RATINGS_MESSAGES } from '../../../constants/en-us';
import { theme as muiTheme } from '@smf/ui-util-app';
import Chart from './chart';
import ExclamationMark from '../../../assets/img/exclamationMark.svg';

const CriticalityRatingsBody = ({ ratings, onChangeRatings }) => {
  const [selectedItem, setSelectedItem] = useState(
    Object.keys(ratings)[Object.keys(ratings).length - 1]
  );

  const renderTextInputs = useCallback(
    () =>
      Object.keys(ratings)
        .reverse()
        .map((item, index) => (
          <Box
            sx={{
              padding: '0.6em',
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'flex-end',
              borderTop: (theme) =>
                `1px solid ${alpha(theme.customColors.darkGrey, 0.3)}`,
              borderBottom: (theme) =>
                `1px solid ${alpha(theme.customColors.darkGrey, 0.3)}`,
            }}
            key={item}
            id="Text input container"
          >
            <Box
              id="text input inner container"
              sx={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'flex-end',
                width: '100%',
              }}
            >
              <Box
                item
                id="text final wrapper"
                sx={{ display: 'flex', paddingLeft: '20%' }}
              >
                <TextField
                  data-testid={`smf_bodytext${index}`}
                  key={item}
                  value={ratings[item].text}
                  placeholder={CRITICALITY_RATINGS_MESSAGES.ENTER_TEXT_HERE}
                  onChange={(event) => {
                    setSelectedItem(item);
                    onChangeRatings(event.target.value, item);
                  }}
                  autoFocus={selectedItem === item}
                  maxLen={255}
                  error={!!ratings[item].error}
                />
              </Box>
              {!!ratings[item].error && (
                <Box
                  id="text input error wrapper"
                  sx={{
                    display: 'flex',
                    paddingLeft: '20%',
                    marginTop: '0.5rem',
                  }}
                >
                  <ExclamationMark
                    style={{ width: '1.4rem', marginRight: '5px' }}
                  />
                  <Text isBody text={ratings[item].error} />
                </Box>
              )}
            </Box>
          </Box>
        )),
    [onChangeRatings, ratings, selectedItem]
  );

  const getChartHeight = () => {
    let sum = 0;
    for (let i = 0; i < Object.keys(ratings).length; i += 1) {
      sum += ratings[Object.keys(ratings)[i]].error ? 78 : 42;
    }
    return sum;
  };

  return (
    <Box
      id="Main Box"
      sx={{
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        width: '100%',
      }}
    >
      <Box
        id="Left Box"
        sx={{
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <Box
          id="Left Box Numbers Wrapper"
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          {Object.keys(ratings)
            .reverse()
            .map((item, idx) => (
              <Box
                key={item}
                sx={{
                  display: 'flex',
                  flexDirection: 'row',
                  alignItems: 'center',
                  padding: ratings[item].error ? '2em 1em' : '1em',
                  borderTop: `1px solid ${alpha(
                    muiTheme.customColors.darkGrey,
                    0.3
                  )}`,
                  borderBottom: `1px solid ${alpha(
                    muiTheme.customColors.darkGrey,
                    0.3
                  )}`,
                }}
                id={`numbers -- ${idx}`}
              >
                <Text isBody text={item} />
                <Text
                  isBody
                  isError
                  text={CRITICALITY_RATINGS_MESSAGES.ASTERISK}
                />
              </Box>
            ))}
        </Box>
        <Box
          id="Chart Box Wrapper"
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            padding: '16px',
          }}
        >
          <Box id="Upper text grid">
            <Text isBody text={CRITICALITY_RATINGS_MESSAGES.HIGHEST_RISK} />
          </Box>
          <Box item id="chart Box">
            <Chart height={getChartHeight()} />
          </Box>
          <Box item id="lower text Box">
            <Text isBody text={CRITICALITY_RATINGS_MESSAGES.LOWEST_RISK} />
          </Box>
        </Box>
      </Box>
      <Box
        id="Box right inputs wrapper"
        sx={{
          display: 'flex',
          flexDirection: 'column',
          flexGrow: 1,
          paddingRight: '2rem',
        }}
      >
        {renderTextInputs()}
      </Box>
    </Box>
  );
};

CriticalityRatingsBody.propTypes = {
  ratings: PropTypes.shape({
    1: PropTypes.shape({ text: PropTypes.string, error: PropTypes.string }),
    2: PropTypes.shape({ text: PropTypes.string, error: PropTypes.string }),
    3: PropTypes.shape({ text: PropTypes.string, error: PropTypes.string }),
    4: PropTypes.shape({ text: PropTypes.string, error: PropTypes.string }),
    5: PropTypes.shape({ text: PropTypes.string, error: PropTypes.string }),
    6: PropTypes.shape({ text: PropTypes.string, error: PropTypes.string }),
    7: PropTypes.shape({ text: PropTypes.string, error: PropTypes.string }),
    8: PropTypes.shape({ text: PropTypes.string, error: PropTypes.string }),
  }),
  onChangeRatings: PropTypes.func,
};

export default CriticalityRatingsBody;

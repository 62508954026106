import React from 'react';
import PropTypes from 'prop-types';
import ErrorIcon from '../../assets/img/smallErrorIcon.svg';
import WarningIcon from '../../assets/img/warningIconV2.svg';
import SuccessIcon from '../../assets/img/successIcon.svg';
import ConfirmIcon from '../../assets/img/confirmIcon.svg';
import { Box, alpha, styled } from '@mui/material';

const colorType = {
  error: 'errorColor',
  warning: 'tangerineColor',
  success: 'successColor',
  info: 'infoColor',
};

const CustomBox = styled(Box, {
  shouldForwardProp: (prop) => prop !== 'customColor',
})(({ theme, customColor }) => ({
  background: alpha(theme.palette.background[customColor], 0.05),
  border: `0.0625em solid ${theme.palette.background[customColor]}`,
  borderRadius: '0.25em',
}));

const InfoIndicator = ({
  type,
  children,
  iconSize = 19,
  iconAlignCenter = true,
}) => {
  const iconTypes = {
    error: <ErrorIcon height={iconSize} width={iconSize} />,
    warning: <WarningIcon height={iconSize} width={iconSize} />,
    success: <SuccessIcon height={iconSize} width={iconSize} />,
    info: <ConfirmIcon height={iconSize} width={iconSize} />,
  };

  const icon = iconTypes[type] || iconTypes.error;
  const color = colorType[type] || colorType.error;

  return (
    <Box display="flex" flexDirection="column" data-testid="smf_info_indicator">
      <CustomBox
        display="flex"
        className="page-level-indicator"
        customColor={color}
        paddingX={2}
        paddingY="0.5rem"
      >
        <Box
          marginRight={2}
          display="flex"
          marginTop="0.125rem"
          alignItems={iconAlignCenter && 'center'}
          data-testid="smf_info_icon"
        >
          {icon}
        </Box>
        <Box display="flex" alignItems="center">
          <Box
            paddingLeft={0}
            display="flex"
            flexDirection="column"
            flex={1}
            data-testid="smf_info_child"
          >
            {children}
          </Box>
        </Box>
      </CustomBox>
    </Box>
  );
};

InfoIndicator.propTypes = {
  type: PropTypes.string,
  children: PropTypes.node,
  iconSize: PropTypes.number,
  iconAlignCenter: PropTypes.bool,
};

export default InfoIndicator;

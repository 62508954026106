const TEST_IDS = {
  /**
   ** COMPONENTS
   *
   ** CONFIRMER
   */
  CONFIRMER_BOX: 'Confirmer-Box',
  CONFIRMER_YES_BUTTON: 'Confirmer-Yes-Button',
  CONFIRMER_NO_BUTTON: 'Confirmer-No-Button',
  /*
   ** FILE UPLOADER
   */
  UPLOADER_EXPORT_BUTTON: 'uploader-export-button',
  UPLOADER_EXPORT_TOOLTIP: 'uploader-export-tooltip',
  UPLOADER_EXPORT_TOOLTIP_CONTENT: 'uploader-export-tooltip-content',
  UPLOADER_UPLOAD_BUTTON: 'uploader-upload-button',
  UPLOADER_UPLOAD_TOOLTIP: 'uploader-upload-tooltip',
  UPLOADER_UPLOAD_TOOLTIP_CONTENT: 'uploader-upload-tooltip-content',
  UPLOADER_PRIMARY_UPLOAD_BUTTON: 'uploader-primary-upload-button',
  UPLOADER_PRIMARY_UPLOAD_ICON: 'uploader-primary-upload-icon',
  UPLOADER_RE_UPLOAD_ICON: 'uploader-re-upload-icon',
  UPLOADER_PREVIEW_CONTAINER: 'uploader-preview-container',
  UPLOADER_PREVIEW_FILENAME: 'uploader-preview-filename',
  UPLOADER_PREVIEW_PERCENT: 'uploader-preview-percent',
  UPLOADER_PREVIEW_FILESIZE: 'uploader-preview-filesize',
  UPLOADER_PREVIEW_PROGRESS_LINE: 'uploader-preview-progess-line',
  UPLOADER_PREVIEW_ERROR_ICON: 'uploader-preview-error-icon',
  UPLOADER_PREVIEW_SUCCESS_ICON: 'uploader-preview-success-icon',
  /**
   ** CONTAINERS
   *
   ** RECIPE MANAGEMENT
   */
  RECIPE_ADD_NEW_ICON: 'Recipe-add-new-icon',
  RECIPE_LIST_ROW: 'Recipe-list-row',
  RECIPE_LIST_HOVER_ME_BOX: 'Recipe-List-Hover-Me-Box',
  RECIPE_LIST_OPTION: 'Recipe-List-Option',
  RECIPES_RESULT_COUNT: 'Recipes-Result-Count',
  RECIPE_INITIAL_ADD_BUTTON: 'Recipe-initial-add-button',
  RECIPE_RESET_FILTERS_BUTTON: 'Recipe-reset-filters-button',
  RECIPE_EXPORT_ALL_BUTTON: 'Recipe-export-all-button',
  RECIPE_UPLOAD_BUTTON: 'Recipe-upload-button',
  RECIPE_SAVE_BUTTON: 'Recipe-save-button',
  RECIPE_IDENTIFIER_INPUT: 'Recipe-identifier-input',
  RECIPE_NAME_INPUT: 'Recipe-name-input',
  RECIPE_UPLOAD_ERROR_MESSAGE: 'Recipe-upload-error-message',
};

export default TEST_IDS;

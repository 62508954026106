import React from 'react';
import PropTypes from 'prop-types';

const Chart = ({ height }) => {
  return (
    <svg
      width="13"
      height={height}
      viewBox={`0 0 13 ${height}`}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect width="13" height={height} rx="6.5" fill="url(#paint0_linear)" />
      <defs>
        <linearGradient
          id="paint0_linear"
          x1="6.5"
          y1="0"
          x2="6.5"
          y2={height}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#DA291C" />
          <stop offset="0.505875" stopColor="#FF9900" />
          <stop offset="1" stopColor="#95CA3C" />
        </linearGradient>
      </defs>
    </svg>
  );
};

Chart.propTypes = {
  height: PropTypes.number,
};

export default Chart;

import { memo } from 'react';
import { Snackbar, Slide, Typography, Box, alpha, styled } from '@mui/material';
import PropTypes from 'prop-types';
import { TOAST_TYPE } from '../../constants';
import ErrorIcon from '../../assets/img/dangerIconV2.svg';
import WarningIcon from '../../assets/img/warningIconV2.svg';
import SuccessIcon from '../../assets/img/successIconV2.svg';
import InfoIcon from '../../assets/img/infoIconV2.svg';

const bgColors = {
  [TOAST_TYPE.ERROR]: 'cocoaToneRed',
  [TOAST_TYPE.WARNING]: 'bronzeTone',
  [TOAST_TYPE.SUCCESS]: 'blackOliveGreen',
  [TOAST_TYPE.INFO]: 'nileBlue',
};

const borderColors = {
  [TOAST_TYPE.ERROR]: 'lavaRed',
  [TOAST_TYPE.WARNING]: 'tangerineColor',
  [TOAST_TYPE.SUCCESS]: 'saveGreen',
  [TOAST_TYPE.INFO]: 'highlightBlue',
};

const toastIcons = {
  [TOAST_TYPE.ERROR]: () => <ErrorIcon height={32} width={32} />,
  [TOAST_TYPE.WARNING]: () => <WarningIcon height={32} width={32} />,
  [TOAST_TYPE.SUCCESS]: () => <SuccessIcon height={32} width={32} />,
  [TOAST_TYPE.INFO]: () => <InfoIcon height={32} width={32} />,
};

function SnackBarContent({ type = TOAST_TYPE.SUCCESS, message = '' }) {
  const Icon = toastIcons[type];
  return (
    <Box
      display="flex"
      justify="center"
      alignItems="center"
      minHeight={64}
      marginX="1rem"
      data-testid="toast"
    >
      <Box>
        <Icon />
      </Box>
      <Box marginLeft="1rem" paddingY="0.5rem">
        <Typography variant="subtitle1">{message}</Typography>
      </Box>
    </Box>
  );
}

SnackBarContent.propTypes = {
  type: PropTypes.string,
  message: PropTypes.string,
};

const CustomizedSnackbar = styled(Snackbar, {
  shouldForwardProp: (prop) => prop !== 'type',
})(({ theme, type }) => {
  const bgColor = bgColors[type] || bgColors[TOAST_TYPE.SUCCESS];
  const borderColor = borderColors[type] || borderColors[TOAST_TYPE.SUCCESS];
  return {
    zIndex: theme.zIndex.modal + 1,
    '.MuiSnackbarContent-root': {
      minWidth: '28.5rem',
      background: alpha(theme.palette.background[bgColor], 0.95),
      border: `0.0625rem solid ${theme.palette.border[borderColor]}`,
      borderRadius: '0.25rem',
      padding: 0,
    },
    '.MuiSnackbarContent-message': { width: '100%', padding: 0 },
  };
});
function Toast({
  open = false,
  type,
  message,
  vertical = 'bottom',
  horizontal = 'center',
  direction = 'right',
}) {
  const SlideTransitionComponent = memo((props) => (
    <Slide {...props} direction={direction} />
  ));

  SlideTransitionComponent.displayName = 'SlideTransition';

  return (
    <CustomizedSnackbar
      type={type}
      open={open}
      TransitionComponent={SlideTransitionComponent}
      message={<SnackBarContent type={type} message={message} />}
      key={type}
      anchorOrigin={{ vertical, horizontal }}
    />
  );
}

Toast.propTypes = {
  open: PropTypes.bool,
  type: PropTypes.string.isRequired,
  message: PropTypes.string,
  vertical: PropTypes.string,
  horizontal: PropTypes.string,
  direction: PropTypes.string,
};
export default Toast;

import { globalstate$ } from '@smf/ui-util-app';
import { useEffect, useState } from 'react';

const useRxjsState = () => {
  const [rxjsState, setRxjsState] = useState({});

  useEffect(() => {
    if (globalstate$) {
      const subscription = globalstate$.subscribe((globalstate) => {
        setRxjsState(globalstate);
      });

      return () => {
        if (subscription) {
          subscription.unsubscribe();
        }
      };
    }
  }, []);

  const updateRxjsState = (data) => {
    const prevState = globalstate$.getValue();
    globalstate$.next({ ...prevState, ...data });
  };

  return [rxjsState, updateRxjsState];
};

export default useRxjsState;

import { Box, Typography, styled } from '@mui/material';
import PropTypes from 'prop-types';
import ExclamationIcon from '../../assets/img/BlueExclamation.svg';
import TEST_IDS from '../../constants/testids';
import { FilterButton } from '../Filter/styledPeers';

const FlexBox = styled(Box)(() => ({ display: 'flex', alignItems: 'center' }));

const AlertText = styled(Typography, {
  shouldForwardProp: (p) => p != 'isBold',
})(({ isBold }) => ({
  ...(isBold && { fontWeight: 700 }),
  fontSize: '.875rem',
  lineHeight: '1.19rem',
}));

const Confirmer = ({ message, YesHandler, NoHandler, children = null }) => {
  return (
    <Box
      sx={{
        width: '30rem',
        backgroundColor: (theme) => theme.customColors.blackGrey,
        borderRadius: '.5rem',
      }}
      data-testid={TEST_IDS.CONFIRMER_BOX}
    >
      <FlexBox
        sx={{
          padding: '1rem 0 1rem 1.5rem',
          height: '4rem',
          borderBottom: (theme) =>
            `1px solid ${theme.customColors.wrapperGrey}`,
        }}
      >
        <ExclamationIcon width={33} height={33} />
        <Typography
          sx={{
            fontWeight: 700,
            fontSize: '1.25rem',
            lineHeight: '1.7rem',
            marginLeft: '.75rem',
          }}
          onClick={NoHandler}
        >
          Confirm
        </Typography>
      </FlexBox>
      <Box
        sx={{ flexDirection: 'column', padding: '.94rem 2rem .94rem 4.25rem' }}
      >
        <AlertText sx={{ marginBottom: '1rem' }}>{message}</AlertText>
        <AlertText isBold>Would you like to continue?</AlertText>
      </Box>
      <FlexBox
        sx={{ justifyContent: 'flex-end', padding: '1rem 1.5rem 1.5rem 0' }}
      >
        <FilterButton
          onClick={NoHandler}
          sx={{ marginRight: '1rem' }}
          data-testid={TEST_IDS.CONFIRMER_NO_BUTTON}
        >
          No
        </FilterButton>
        {children ? (
          <>{children}</>
        ) : (
          <FilterButton
            isApply
            onClick={(e) => YesHandler(e)}
            data-testid={TEST_IDS.CONFIRMER_YES_BUTTON}
          >
            Yes
          </FilterButton>
        )}
      </FlexBox>
    </Box>
  );
};

Confirmer.propTypes = {
  message: PropTypes.string,
  YesHandler: PropTypes.func,
  NoHandler: PropTypes.func,
  children: PropTypes.node,
};

export default Confirmer;

import { Typography, Box, styled } from '@mui/material';
import PropTypes from 'prop-types';

const ColoredRangeBox = styled(Box, {
  shouldForwardProp: (prop) => prop !== 'color',
})(({ color }) => ({
  position: 'relative',
  width: '100%',
  fontWeight: 700,
  fontSize: '14px',
  lineHeight: '19px',

  '&::before': {
    content: '""',
    position: 'absolute',
    width: '4px',
    height: '.8rem',
    top: '.4em',
    left: '-.45rem',
    backgroundColor: color,
  },
}));

const LabelBox = styled(Box)(() => ({
  fontStyle: 'normal',
  fontWeight: 400,
  fontSize: '14px',
  lineHeight: '19px',
}));

export default function Label({ color, lowValue, highValue, label }) {
  return (
    <Box
      display={'flex'}
      flexDirection="column"
      width="8em"
      height="4em"
      border="1px solid #40444a"
      borderRadius="4px"
      padding=".5em 0em 0em 1em"
      marginRight="8px"
    >
      <Box display={'flex'} flexDirection="row" width="100%">
        <ColoredRangeBox color={color}>
          <Typography>{`${lowValue}% to ${highValue}%`}</Typography>
        </ColoredRangeBox>
      </Box>
      <LabelBox>
        <Typography>{label}</Typography>
      </LabelBox>
    </Box>
  );
}

Label.propTypes = {
  color: PropTypes.string,
  lowValue: PropTypes.number,
  highValue: PropTypes.number,
  label: PropTypes.string,
};

import React from 'react';
import PropTypes from 'prop-types';
import { Grid, Box, MenuItem, alpha, Typography, styled } from '@mui/material';
import TextField from '../../../components/TextField';
import Text from '../../../components/Text';
import Dropdown from '../../../components/DropDown';
import { REFRESH_RATE_CONSTANTS } from '../../../constants';
import ExclamationMark from '../../../assets/img/exclamationMark.svg';

const HeaderGrid = styled(Grid)(({ theme }) => ({
  background: theme.palette.background.darkishBlackBlue,
  border: `0.0625em solid ${alpha(theme.palette.border.darkGrey, 0.3)}`,
  minHeight: '3.5em',
  color: '#BFBFBF',
  padding: '0.4em',
}));

const RowGrid = styled(Grid)(({ theme }) => ({
  padding: '0.4em',
  background: theme.palette.background.blackGrey,
  minHeight: '3.5em',
  border: `0.0625em solid ${alpha(theme.palette.border.darkGrey, 0.3)}`,
}));

const ErrorMessage = styled(Typography)(() => ({
  fontSize: '12px',
  fontWeight: 400,
  marginLeft: '0.5rem',
}));

const RefreshRateBody = ({
  refreshRateData,
  inputChangeHandler,
  uomChangeHandler,
  showError = false,
  actualsUOM,
}) => {
  const { value, uoMId, error, errorText } = refreshRateData;

  return (
    <>
      <HeaderGrid container item data-testid="smf_rrbody">
        <Grid item sm={7}>
          <Box p={1} paddingLeft="1em">
            <Text isBody text={'Parameter'} />
          </Box>
        </Grid>
        <Grid item sm={5}>
          <Box p={1}>
            <Text isBody text={'Value'} />
          </Box>
        </Grid>
      </HeaderGrid>
      <RowGrid container item>
        <Grid item sm={7}>
          <Box p={1} paddingLeft="1em">
            <Text isBody text={REFRESH_RATE_CONSTANTS.LABEL} />
          </Box>
        </Grid>
        <Grid item sm={5}>
          <Box p="0.25em" display="flex">
            <Box
              width="10em"
              pr="0.75rem"
              display="flex"
              alignItems="center"
              flexDirection="column"
            >
              <TextField
                value={value}
                onChange={inputChangeHandler}
                error={error}
                placeholder="-"
              />
              {error && (
                <Box display="flex" marginTop={'10px'} alignItems="flex-start">
                  <ExclamationMark style={{ width: '1.4rem' }} />
                  <ErrorMessage>{errorText}</ErrorMessage>
                </Box>
              )}
            </Box>
            <Box width="10em">
              <Dropdown
                data-testid="smf_rr_dropdown"
                value={uoMId || REFRESH_RATE_CONSTANTS.DROPDOWN_DEFAULT_VALUE}
                placeholderValue={REFRESH_RATE_CONSTANTS.DROPDOWN_DEFAULT_VALUE}
                onChange={uomChangeHandler}
                error={showError && uoMId === null}
              >
                <MenuItem
                  value={REFRESH_RATE_CONSTANTS.DROPDOWN_DEFAULT_VALUE}
                  disabled
                  sx={{ display: 'none' }}
                >
                  <Text
                    isBody
                    text={REFRESH_RATE_CONSTANTS.DROPDOWN_PLACEHOLDER}
                  />
                </MenuItem>
                {actualsUOM &&
                  actualsUOM.map(
                    ({ weight: itemValue, uoMName: itemLabel }) => (
                      <MenuItem value={itemValue} key={itemValue}>
                        <Text isBody text={itemLabel} />
                      </MenuItem>
                    )
                  )}
              </Dropdown>
            </Box>
          </Box>
        </Grid>
      </RowGrid>
    </>
  );
};

RefreshRateBody.propTypes = {
  refreshRateData: PropTypes.shape({
    value: PropTypes.string,
    uoMId: PropTypes.number,
    error: PropTypes.bool,
    errorText: PropTypes.string,
  }),
  inputChangeHandler: PropTypes.func,
  uomChangeHandler: PropTypes.func,
  showError: PropTypes.bool,
  actualsUOM: PropTypes.array,
};

export default RefreshRateBody;

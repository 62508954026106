import { useState } from 'react';
import PropTypes from 'prop-types';
import { Box, Grid, styled, Typography } from '@mui/material';
import BlueDotIcon from '../../../assets/img/BlueDot.svg';
import ErrorExclamationIcon from '../../../assets/img/errorExclamation.svg';
import TextField from '../../../components/TextField';
import ListOptions from './listOptions';
import { useRecipeContext } from '../../../context/recipe/reducer';
import Button from '../../../components/Button';
import { recipeActions } from '../../../context/recipe/actions';
import {
  RECIPE_VALIDATIONS_CONSTANTS,
  RECIPE_VALIDATIONS_ERROR_MSG,
} from '../../../constants';
import ListHeader from './listHeader';
import TEST_IDS from '../../../constants/testids';
import { getLeftOutValues } from '../../../utils/helperFunctions';

const flexTop = { display: 'flex', alignItems: 'top' };
const topBottomMargins = { marginTop: '0.5rem', marginBottom: '0.5rem' };

const GridItem = styled((props) => <Grid item {...props} />)(() => flexTop);

const Label = styled(Typography)(() => ({
  fontSize: '0.875rem',
  fontWeight: 400,
  lineHeight: '1.19rem',
}));

const ErrorMessage = ({ condition, message }) => {
  return (
    <>
      {condition && (
        <Typography
          sx={{
            color: (theme) => theme.customColors.white,
            fontSize: '0.75em',
            marginTop: '0.5em',
            paddingTop: '0.4375em',
            lineHeight: '1.333334',
          }}
          varient="caption"
        >
          <span
            style={{
              display: 'inline-block',
              width: '2em',
              verticalAlign: 'top',
            }}
          >
            <ErrorExclamationIcon />
          </span>
          <span style={{ display: 'inline-block', width: 'calc(100% - 2em)' }}>
            {message}
          </span>
        </Typography>
      )}
    </>
  );
};

const ListRow = ({ recipe, position, exportRecipe, afterRecipeDelete }) => {
  const isNewRecipe = !recipe.recipeId;
  const isEditMode = recipe.isEditMode;
  const [showOptions, setShowOptions] = useState(false);

  const { recipeDispatch, recipeState } = useRecipeContext();

  const validateRecipe = (key, value) => {
    if (key == RECIPE_VALIDATIONS_CONSTANTS.RECIPE_IDENTIFER_KEY) {
      if (value.length <= 0) {
        return RECIPE_VALIDATIONS_ERROR_MSG.REQUIRED_FIELDS;
      } else if (
        value.length > RECIPE_VALIDATIONS_CONSTANTS.RECIPE_IDENTIFIER_MAX_LENGTH
      ) {
        return RECIPE_VALIDATIONS_ERROR_MSG.RECIPE_IDENTIFER_MAX_LENGTH;
      } else if (
        !RECIPE_VALIDATIONS_CONSTANTS.RECIPE_IDENTIFER_REGEX.test(value)
      ) {
        return RECIPE_VALIDATIONS_ERROR_MSG.INAVLID_CHARACTERS;
      } else return null;
    } else if (key == RECIPE_VALIDATIONS_CONSTANTS.RECIPE_NAME_KEY) {
      if (value.length <= 0) {
        return RECIPE_VALIDATIONS_ERROR_MSG.REQUIRED_FIELDS;
      } else if (
        value.length > RECIPE_VALIDATIONS_CONSTANTS.RECIPE_NAME_MAX_LENGTH
      ) {
        return RECIPE_VALIDATIONS_ERROR_MSG.RECIPE_NAME_MAX_LENGTH;
      } else if (!RECIPE_VALIDATIONS_CONSTANTS.RECIPE_NAME_REGEX.test(value)) {
        return RECIPE_VALIDATIONS_ERROR_MSG.INAVLID_CHARACTERS;
      } else return null;
    }
  };

  const onChangeHandler = (e, k) => {
    let errors = recipe.errors;
    const errorMessage = validateRecipe(k, e.target.value);
    if (errorMessage) {
      errors = {
        ...errors,
        [k]: {
          error: true,
          helperText: errorMessage,
        },
      };
    } else {
      errors = {
        ...errors,
        [k]: null,
      };
    }

    recipeDispatch(
      recipeActions.editRecipe(position, {
        ...recipe,
        [k]: e.target.value,
        errors: errors,
      })
    );

    // Validate Unique Recipe
    setTimeout(() => {
      validateUniqueRecipe(k);
    }, 100);
  };

  const validateUniqueRecipe = (key) => {
    const allRecipes = recipeState.recipesForList;

    const uniqueValueSet = new Set();
    const duplicateValue = new Set();
    const leftOutValues = getLeftOutValues(
      recipeState.recipesFromAPI,
      allRecipes,
      key
    );

    const editFieldsIndices = [];
    for (let index = 0; index < allRecipes.length; index++) {
      const value = allRecipes[index][key];

      if (allRecipes[index]?.isEditMode) {
        editFieldsIndices.push(index);
      }

      if (uniqueValueSet.has(value) || leftOutValues.has(value)) {
        duplicateValue.add(value);
      } else {
        uniqueValueSet.add(value);
      }
    }

    editFieldsIndices.forEach((index) => {
      const recipe = allRecipes[index];
      if (duplicateValue.has(recipe[key])) {
        recipe.errors = {
          ...recipe.errors,
          [key]: {
            error: true,
            helperText:
              recipe?.errors[key]?.helperText ??
              RECIPE_VALIDATIONS_ERROR_MSG.UNIQUE_VALUE,
          },
        };
      } else {
        if (
          recipe.errors &&
          recipe?.errors[key] &&
          recipe?.errors[key]?.helperText ==
            RECIPE_VALIDATIONS_ERROR_MSG.UNIQUE_VALUE
        ) {
          recipe.errors = {
            ...recipe.errors,
            [key]: null,
          };
        }
      }
      recipeDispatch(recipeActions.editRecipe(index, recipe));
    });
  };

  return (
    <Grid
      sx={{
        border: (theme) => `1px solid ${theme.customColors.wrapperGrey}`,
        borderWidth: '1px 0 0',
        minHeight: '3rem',
        ':last-child': { borderBottomWidth: '1px' },
        backgroundColor: (theme) =>
          isEditMode
            ? theme.palette.background.configLighterDark
            : theme.customColors.blackGrey,
      }}
      container
      data-testid={`${TEST_IDS.RECIPE_LIST_ROW}-${position}`}
      id={`${TEST_IDS.RECIPE_LIST_ROW}-${position}`}
      tabindex={position}
    >
      <Box
        sx={{
          ...flexTop,
          width: '100%',
        }}
        component="div"
        onMouseEnter={() => setShowOptions(true)}
        onMouseLeave={() => setShowOptions(false)}
        data-testid={`${TEST_IDS.RECIPE_LIST_HOVER_ME_BOX}-${position}`}
      >
        <GridItem xs={0.6}>
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              width: '100%',
              height: '3rem',
            }}
          >
            {isNewRecipe && <BlueDotIcon height={8} width={8} />}
          </Box>
        </GridItem>
        <GridItem xs={3}>
          {isEditMode ? (
            <Box sx={{ width: '10rem', ...topBottomMargins }}>
              <TextField
                data-testid={`${TEST_IDS.RECIPE_IDENTIFIER_INPUT}_${position}`}
                value={recipe.recipeIdentifier}
                onChange={(e) => onChangeHandler(e, 'recipeIdentifier')}
                placeholder="-"
                maxLen={255}
                error={
                  recipe.errors?.recipeIdentifier?.error &&
                  recipeState.showInputErrors
                }
              />
              <ErrorMessage
                condition={
                  recipe.errors?.recipeIdentifier?.error &&
                  recipeState.showInputErrors
                }
                message={recipe.errors?.recipeIdentifier?.helperText || ''}
              />
            </Box>
          ) : (
            <Box sx={{ display: 'flex', alignItems: 'center', width: '100%' }}>
              <Label>{recipe.recipeIdentifier}</Label>
            </Box>
          )}
        </GridItem>
        <GridItem xs={8.4}>
          {isEditMode ? (
            <Box sx={{ width: '17.06rem', ...topBottomMargins }}>
              <TextField
                data-testid={`${TEST_IDS.RECIPE_NAME_INPUT}_${position}`}
                value={recipe.recipeName}
                onChange={(e) => onChangeHandler(e, 'recipeName')}
                placeholder="-"
                maxLen={255}
                error={
                  recipe.errors?.recipeName?.error &&
                  recipeState.showInputErrors
                }
              />
              <ErrorMessage
                condition={
                  recipe.errors?.recipeName?.error &&
                  recipeState.showInputErrors
                }
                message={recipe.errors?.recipeName?.helperText || ''}
              />
            </Box>
          ) : (
            <Box sx={{ display: 'flex', alignItems: 'center', width: '100%' }}>
              <Label>{recipe.recipeName}</Label>
            </Box>
          )}
          <ListOptions
            recipe={recipe}
            position={position}
            showOptions={showOptions}
            isEditMode={isEditMode}
            exportRecipe={exportRecipe}
            afterRecipeDelete={afterRecipeDelete}
          />
        </GridItem>
      </Box>
    </Grid>
  );
};

ListRow.displayName = 'ListRow';

const ListSection = ({ exportRecipe, afterRecipeDelete }) => {
  const { recipeState, recipeDispatch } = useRecipeContext();

  const addRecipe = () => {
    recipeDispatch(recipeActions.addNewRecipe());
  };

  return (
    <>
      <ListHeader addRecipe={addRecipe} />
      <Box
        sx={{
          height: '20rem',
          backgroundColor: (theme) => theme.customColors.blackGrey,
          border: (theme) => `1px solid ${theme.customColors.wrapperGrey}`,
          borderWidth: '0 1px',
          overflowY: 'auto',
        }}
      >
        {recipeState.recipesForList.length > 0 ? (
          <>
            {recipeState.recipesForList.map((recipe, idx) => (
              <ListRow
                recipe={recipe}
                position={idx}
                key={idx}
                exportRecipe={exportRecipe}
                afterRecipeDelete={afterRecipeDelete}
              />
            ))}
          </>
        ) : (
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              flexDirection: 'column',
              width: '100%',
              height: '100%',
            }}
          >
            <Typography
              sx={{
                fontSize: '.875rem',
                fontWeight: 400,
                lineHeight: '1.25rem',
                color: (theme) => theme.palette.text.lightWhiteGrey,
                marginBottom: '1rem',
              }}
            >
              Recipes will display here.
            </Typography>
            <Button
              onClick={addRecipe}
              sx={{
                textTransform: 'none',
                width: '8.13rem',
                height: '2.25rem',
              }}
              data-testid={TEST_IDS.RECIPE_INITIAL_ADD_BUTTON}
            >
              Add Recipes
            </Button>
          </Box>
        )}
      </Box>
    </>
  );
};

ErrorMessage.propTypes = {
  condition: PropTypes.bool,
  message: PropTypes.string,
};

ListSection.propTypes = {
  exportRecipe: PropTypes.func,
  afterRecipeDelete: PropTypes.func,
};

ListRow.propTypes = {
  recipe: PropTypes.object,
  position: PropTypes.number,
  exportRecipe: PropTypes.func,
  afterRecipeDelete: PropTypes.func,
};

export default ListSection;

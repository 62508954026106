import { Box, styled, Typography } from '@mui/material';
import Button from '../../../components/Button';
import { useRecipeContext } from '../../../context/recipe/reducer';
import PropTypes from 'prop-types';
import { recipeActions } from '../../../context/recipe/actions';
import TEST_IDS from '../../../constants/testids';

const FooterBox = styled(Box, {
  shouldForwardProp: (prop) => prop != 'h' && prop != 'bg' && prop != 'bWidth',
})(({ theme, h, bg, bWidth }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-end',
  height: h,
  backgroundColor: theme.customColors[bg],
  border: `1px solid ${theme.customColors.wrapperGrey}`,
  borderWidth: bWidth,
}));

const Label = styled(Typography)(() => ({
  fontSize: '.875rem',
  fontWeight: 400,
  lineHeight: '1.25rem',
  marginRight: '1rem',
}));

const FooterSection = ({ saveRecipe }) => {
  const { recipeState, recipeDispatch } = useRecipeContext();

  const handleSave = () => {
    recipeDispatch(recipeActions.showInputErrors(true));
    const updatedRecipes = recipeState.recipesForList.filter(
      (recipe) => recipe?.isEditMode
    );

    let errorRowIndex = validateIsErrors(recipeState.recipesForList);

    if (validateIsErrors(updatedRecipes) == -1) {
      const recipePayload = updatedRecipes.map((recipe) => ({
        recipeId: recipe.recipeId,
        recipeIdentifier: recipe.recipeIdentifier,
        recipeName: recipe.recipeName,
      }));
      /**
       * To hide Save button
       */
      recipeDispatch(recipeActions.saveRecipes());
      saveRecipe(recipePayload);
    } else {
      const errorRow = document.getElementById(
        `${TEST_IDS.RECIPE_LIST_ROW}-${errorRowIndex}`
      );
      errorRow.focus();
    }
  };

  const validateIsErrors = (updatedRecipes) => {
    for (let index = 0; index < updatedRecipes.length; index++) {
      if (
        updatedRecipes[index]?.errors?.recipeName?.error ||
        updatedRecipes[index]?.errors?.recipeIdentifier?.error
      )
        return index;
    }
    return -1;
  };

  return (
    <>
      <FooterBox h="3rem" bg="blackishGrey" bWidth="1px">
        {recipeState.recipesFromAPI.length > 0 && (
          <Label data-testid={TEST_IDS.RECIPES_RESULT_COUNT}>
            {recipeState.recipesResultsCount} results
          </Label>
        )}
      </FooterBox>
      {recipeState.recipesForList.length > 0 && recipeState.isRecipesDirty && (
        <FooterBox h="3.5rem" bg="blackGrey" bWidth="0 1px 1px">
          <Button
            onClick={handleSave}
            data-testid={TEST_IDS.RECIPE_SAVE_BUTTON}
            sx={{ textTransform: 'none' }}
          >
            Save
          </Button>
        </FooterBox>
      )}
    </>
  );
};

FooterSection.propTypes = {
  saveRecipe: PropTypes.func,
};

export default FooterSection;

import { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { OptionLabel, OptionTooltip } from './styledPeers';

const FilterLabel = ({ label, searchText }) => {
  const [isMaskedWithEllipsis, setIsMaskedWithEllipsis] = useState(false);
  const labelRef = useRef();

  useEffect(
    () =>
      setIsMaskedWithEllipsis(
        labelRef.current.offsetWidth < labelRef.current.scrollWidth
      ),
    []
  );

  const getFormattedString = () => {
    if (searchText.length < 2) return <span>{label}</span>;

    const idx = label.toLowerCase().indexOf(searchText.toLowerCase());

    if (idx < 0) return <span>{label}</span>;

    const prefixText = label.substring(0, idx);
    const selectedText = label.substring(idx, idx + searchText.length);
    const suffixText = label.substring(idx + searchText.length);

    return (
      <span>
        {prefixText}
        <b>{selectedText}</b>
        {suffixText}
      </span>
    );
  };

  return (
    <OptionTooltip
      title={label}
      placement="top"
      isDisabled={!isMaskedWithEllipsis}
    >
      <OptionLabel ref={labelRef}>{getFormattedString()}</OptionLabel>
    </OptionTooltip>
  );
};

FilterLabel.propTypes = {
  label: PropTypes.string,
  searchText: PropTypes.string,
};

export default FilterLabel;
